import React, {useEffect, useState} from 'react'
import {useFetchList, useEdit, useFetchOne} from '../../../api/services/backend'
import { Form, Input, Button, InputNumber , Select,Row, Col, DatePicker} from 'antd';
import {useParams} from 'react-router-dom'

const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  export default function EditApartment() {

  const {mutate} = useEdit('campaign')
  const [form] = Form.useForm();
  const {id} = useParams()

  const {data, isLoading, error} = useFetchList('location')
  const {data:res} = useFetchOne('campaign', id)
  console.log(res);
  

  const [zoneChange, setZoneChange] =  useState<any>(false)
  const [pincodeChange, setPincodeChange] =  useState<any>(false)
  const [localityChange, setLocalityChange] =  useState<any>(false)

  const [cityFound, setCityInfo] =  useState<any>(null)
  const [zoneInfo, setZoneInfo] =  useState<any>([])
  const [pincodeInfo, setPincodeInfo] =  useState<any>([])
  const [localityInfo, setLocalityInfo] =  useState<any>([])

    
 
    const zoneOne =  res?.apartment?.city_id?.zone_info.find(item => {
    console.log(item.id);
    
      return item.id === res?.apartment?.zone_id
    } )  

    const pincodeOne = res?.apartment?.city_id?.pincode_info.find(item => {
      return item.id === res?.apartment?.pincode_id
    } )  

    const localityOne = res?.apartment?.city_id?.locality_info.find(item => {
      return item.id ===res?.apartment?.locality_id
    } )


  useEffect(()=>{
    setCityInfo(res?.apartment?.city_id)
    setZoneInfo(res?.apartment?.city_id?.zone_info)

    const pincodeArray = res?.apartment?.city_id?.pincode_info.filter((item, i)=> {
      console.log(item);
      
      return item.zone === res?.apartment?.zone_id} )


    const localityArray = res?.apartment?.city_id?.locality_info.filter((item, i)=> {
        return item.pincode === res?.apartment?.pincode_id} )

        
      setPincodeInfo(pincodeArray)
      setLocalityInfo(localityArray)

    console.log(res?.apartment?.city_id?.locality_info[0].locality);
    
    res && form.setFieldsValue({
    
        name: res?.apartment?.apartment_info?.name,
        address:res?.apartment?.apartment_info?.address,
        phone_no:res?.apartment?.apartment_info?.phone_no,
        status:res?.apartment?.apartment_info?.status,
        city:res?.apartment?.city_id?.city,
        locality:res?.apartment?.city_id?.locality_info[0]?.locality,
        pincode:res?.apartment?.city_id?.pincode_info[0]?.pincode,
        zone:res?.apartment?.city_id?.zone_info[0]?.zone,
    

      })
}, [res])




  const onFinish = (values: any) => {


        const data = {
    
          apartment_info: {        
             name: values.name,            
             address: values.address ,
             phone_no: values.phone_no,
             status: values.status,
          
           },
           id: res?.apartment?._id
              
     }
     
  mutate(data)
 
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


const onchangeCity = (value) =>{
  form.resetFields(['zone'])
  form.resetFields(['pincode'])
  form.resetFields(['locality'])
    const cityFound = data && data.city.find((item, i)=> item._id === value)      
   console.log(cityFound);
   
     setCityInfo(cityFound)
      setZoneInfo(cityFound.zone_info ? cityFound.zone_info : [])
      setPincodeInfo([])
      setLocalityInfo([])

    }

    
    const onChangeZone=(value)=> {
      form.resetFields(['pincode'])
      form.resetFields(['locality'])
      const pins =cityFound.pincode_info.filter((item, i)=> item.zone === value )
      setPincodeInfo(pins)
      setLocalityInfo([])
      setZoneChange(true)
    }

    const onChangePincode=(value)=>{
      form.resetFields(['locality'])
      const localityFound = cityFound.locality_info.filter((item, i)=> item.pincode === value )
      setLocalityInfo(localityFound)
      setPincodeChange(true)
    }
  

    const onChangeLocality=(value)=>{
      console.log(`selected ${value}`);
      setLocalityChange(true)
    }

    function onChange(value) {
        console.log(`selected ${value}`);
      }
return (
    
  <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>

  <Form
  {...layout}
    name="basic"
    // labelCol={{ span: 4 }}
    // wrapperCol={{ span: 10 }}
    form={form}
    initialValues={{ remember: true }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
  >
           
       <div className="grid grid-cols-3  mt-6 mx-4 "> 
       <div  className="col-span-2">
       <Form.Item
        label={<p className="text-left m-0 ">Apartment Name</p>}
        name="name"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>

      <Form.Item
        label={<p className="text-left m-0  ">Address</p>}
        name="address"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input.TextArea rows={2}  style={{width:'100%'}}/>
      </Form.Item> 


<Row gutter={20}>



      <Col span={12}>
      <Form.Item
            label={<p className="text-left m-0   ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
          >
             <Select disabled
              showSearch
              placeholder="Select City"   
              optionFilterProp="children" 
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              onChange={onchangeCity}>
                  {
                  data && data.city.map((item, i)=>{   
                return <option key={i} value={item._id} >{item.city}</option>

              })
            }
    
             </Select >
          </Form.Item>
         </Col>


           <Col span={12}>
           <Form.Item
            label={<p className="text-left m-0   ">Zone</p>}
            name="zone"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Select 
            disabled
              showSearch
              placeholder="Select Zone"
              optionFilterProp="children"
              onChange={onChangeZone}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              // disabled={zoneInfo.length <1 ? true : false}
            >
              {
                  zoneInfo && zoneInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.zone}</option>
              })
              }
      
      </Select>
          </Form.Item>
   
          </Col> 


          <Col span={12}>
          <Form.Item
          label={<p className="text-left m-0  ">Pincode</p>}
          name="pincode"
           rules={[{ required: true, message: 'required!' }]}
         >
         <Select
         disabled
              placeholder="Select Pincode"
              optionFilterProp="children"
              onChange={onChangePincode}
              showSearch
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              // disabled={pincodeInfo.length <1 ? true : false}
            >
              {
                 pincodeInfo && pincodeInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.pincode}</option>
              })
              }
      
      </Select>
         </Form.Item>
          </Col>

      <Col span={12}>     
        <Form.Item
              label={<p className="text-left m-0   ">Locality</p>}
              name="locality"
              rules={[{ required: true, message: 'required!' }]}
            >
            <Select
            disabled
                showSearch
                  placeholder="Select Locality"
                  optionFilterProp="children"
                  onChange={onChangeLocality}
                  filterOption={(input, option:any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } 
                  // disabled={localityInfo.length <1 ? true : false}
                >
                  {
                      localityInfo && localityInfo.map((item, i)=>{                         
                      return <option key={i} value={item.id} >{item.locality}</option>
                  })
                  }

             </Select>
                </Form.Item>
        </Col>
      

      <Col span={12}>
 
      <Form.Item
        label={<p className="text-left m-0 ">Phone Number</p>}
        name="phone_no"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>
          </Col>
      
      <Col span={12}>
      <Form.Item
             label={<p className="text-left m-0 ">Status</p>}
            name='status'
            rules={[{ required: true, message: 'required!' }]}
          >
               <Select
    
    style={{ width: 200 }}
    placeholder="status"
    optionFilterProp="children"
    onChange={onChange}
   
  >
      <Option value="new">New</Option>
      <Option value="progress">In Progress</Option>
      <Option value="registered">Registered</Option>
      <Option value="rejected">Rejected</Option>
  </Select>
          </Form.Item> 
        </Col>
      </Row>

<div className="mt-5">
      <Form.Item {...tailLayout} className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
</div>
</div>
</div>
 </Form>

  </div>
  );

  }