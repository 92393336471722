import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useEdit, useFetchOne,useFetchList} from '../../../../api/services/backend'
import { Form, Input, Button,Checkbox,message, Upload ,Image, Row, Col} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import DataTable from './farmTable'
import { nanoid } from '@reduxjs/toolkit'
import styled from 'styled-components'
import storage from '../../../shared/storage'
import {MdClose, MdDelete} from 'react-icons/md'
import Loader from '../../../shared/loader';
import { farmerSelector } from '../../../../api/services/farmer'

  export default function Createfarm({ actionMethod, info}) {
 

    const dispatch = useDispatch()

    const [form] = Form.useForm();
    const [imgurl, setImgurl] = useState<any>([])
    const [loading1, setLoading1] = useState(false)
    const [fileList, setFileList] = useState([])

    const [value, setValue] = React.useState(1);

  const [button, setButton] = useState('save')
  const [id, setId] = useState(null)
  const [checkState, setCheckState] = useState(false);
  const [farm, setFarm] = useState<any>([])
  const { basic_data } = useSelector(farmerSelector)
  const defaultCheckedList = [];




  // const handleClickEdit = (e, id) => {
  //     e.preventDefault() 

  //     id && form.setFieldsValue({
  //        farm_name: id.farm_name,
  //        ph_no: id.ph_no,
  //        address: id.address,
  //        village: id.village,
  //        district: id.district,
  //        taluk: id.taluk,
  //        state: id.state,
  //        pincode: id.pincode,
  //        // image: id.image,
  //        video: id.video,
  //        about: id.about,
  //      });
  //      setButton('update')
  //      setId(id.id)
  //   }

  console.log()
  
function onChange(e) {

  setCheckState(e.target.checked)
    if(e.target.checked)
    {    
      basic_data && form.setFieldsValue({
  
        farm_name: basic_data.farmer_info && basic_data.farmer_info.farmer_name,
        ph_no:basic_data.ph_no,
        address:basic_data.farmer_info && basic_data.farmer_info.address.address,
        village:basic_data.farmer_info && basic_data.farmer_info.address.village,
        district:basic_data.farmer_info && basic_data.farmer_info.address.district,
        taluk:basic_data.farmer_info && basic_data.farmer_info && basic_data.farmer_info.address.taluk,
        state:basic_data.farmer_info && basic_data.farmer_info.address.state,
        pincode:basic_data.farmer_info && basic_data.farmer_info.address.pincode,
      });
    }
    else{
      form.resetFields()
    }
}



    // const handleClickDelete = (id) => {
    //   console.log(id);
    //   const newFarm = farm.filter(item=> {
    //       return item.id !== id.id
    //   })      
    //   setFarm(newFarm)
    //   message.info('Farm Deleted')
    // }

       
  const onFinish = (values: any) => {
    console.log(values);
    var numbers = /^[0-9]+$/;
    if(!values.ph_no.match(numbers) )
    {
    return message.error("Phone number must be only numbers") 
    }
    if(values.ph_no.length !== 10 ){
     return message.error("Phone number must be 10 numbers")
    }
    if(!values.pincode.match(numbers) )
    {
      return message.error("Pincode must be only numbers") 
    }
    if(values.pincode.length !== 6 ){
      return message.error("pincode must be 6 digit")
     }
   
else{
     const data = {
          farm_name: values.farm_name,
          ph_no:values.ph_no,
          address:values.address,        
          village :values.village,
          district :values.district ,
          taluk:values.taluk ,
          state: values.state, 
          pincode: values.pincode,  
          image:imgurl,
          video: values.video,
          about: values.about,
          // id: button === 'update' ? id : nanoid()
          id: nanoid()
       }

       dispatch(actionMethod())
       dispatch(info([data]))

    // if(button === 'update'){
    //   const newFarm = farm.map(item=> {
    //     if(item.id === id){
    //       return data
    //     } 
    //     return item
    //   })
    //   setFarm(newFarm)
    //   setButton('save')
    //   message.info('Farm Updated')

    // }

  //   else {
  //     setFarm([...farm, data ])
  //     setButton('save')
  //     message.info('Farm Added')
  // }

  // setId(null)
  // form.resetFields()
  };
  }
  
  // const changeTab = () =>{
  //     dispatch(actionMethod())
  //     dispatch(info(farm))
  //   }

    
    const onFinishFailed = (errorInfo: any) => {
      console.log('Failed:', errorInfo);
    };


    const handleChange = info => {
      setLoading1(true)
         
          storage
          .ref("images/" + info.file.name)
          .put(info.file.originFileObj)
          .then(snapshot => {
            return snapshot.ref.getDownloadURL();
          })
          .then(url => {
            console.log(url);
            setImgurl([...imgurl, url])
            setLoading1(false)
      
          })
          .catch(error => {
            console.log(error);
          });
      
        };
      
      
      const remove = (e, url) =>{
      
      setImgurl(prev => prev.filter(item => item !== url))
      
      }
      
      
        const uploadButton = (
          <div>
            { loading1 ? <LoadingOutlined  /> : <PlusOutlined />}
            <div style={{ marginTop: 8, fontSize:"14px" }}>{loading1 ? "uploading" :""}</div>
          </div>
        );


        
  return (
    <FormWrap> 
    <div className="mt-5 mr-10 ml-5">

{/* {
  farm && farm.length>0 ? <DataTable  isLoading={false} edit={handleClickEdit} deleteFarm={handleClickDelete} data={farm} /> : '' 
} */}


    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<div className="grid grid-cols-2 gap-10 ">

<div>

<Form.Item name="basics" valuePropName="checked" >
     <Checkbox onChange={onChange} defaultChecked={checkState}>

      Same as Basics
     </Checkbox>
     </Form.Item>

<Form.Item
        label={<p className="text-left m-0 ">Farm Name</p>}
        name="farm_name"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>

      <Form.Item
        label={<p className="text-left m-0 ">Phone No.</p>}
        name="ph_no"
        rules={[{ required: true, message: 'required!' }
        ]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>


                
    <Form.Item
            label={<p className="text-left m-0 ml-2 ">Images</p>}
            name="image"
            rules={[{ required: true, message: 'required!' }]}
          >
    <div 
            className=" grid grid-cols-4 gap-5">
        {
          imgurl.map((img, i)=>{

            return <div className=" imglist  bg-gray-50 text-center" style={{height:"100px"}}>
            {/* <img key={i} className="  rounded col-span-1  block  object-cover" style={{height:"100%", width:"100%"}} src={img} alt="hj"   /> */}
            
            <Image    
            preview={false}               
            key={i}
            className="  rounded col-span-1  block  object-cover"
            style={{height:"100%", width:"100%"}}
            src={img}
            placeholder={<Loader/> }              
          />   
            <h2 onClick={(e)=>remove(e, img)} className="p-1 text-white  text-xl "> <MdDelete/></h2> 

              </div>
  })
        }

        <Upload
                
                  listType="picture-card"
                  fileList={fileList}
                  onChange={handleChange}
                  multiple={true}
                  className="ml-2"
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
          </div> 
        </Form.Item>


        <Form.Item
        label={<p className="text-left m-0 ">Video Link</p>}
        name="video"
        // rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>    
      
      <Form.Item
            label={<p className="text-left m-0 ">About Farm</p>}
            name="about"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input.TextArea  style={{width:'100%'}} rows={2}/>
          </Form.Item>
           
 </div>
  <div>
           
          <Form.Item
        label={<p className="text-left m-0 ">Address</p>}
        name="address"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input.TextArea  style={{width:'100%'}} rows={2}/>
      </Form.Item>


      <Row gutter={20}>
        <Col span={8}> 
            <Form.Item
              label={<p className="text-left m-0 ">Village</p>}
              name="village"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item>
            </Col>

            <Col span={8}> 
            <Form.Item
              label={<p className="text-left m-0">Taluk</p>}
              name="taluk"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

                 <Col span={8}>    
            <Form.Item
              label={<p className="text-left m-0 ">District</p>}
              name="district"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item>
            </Col>

            <Col span={8}> 
            <Form.Item
              label={<p className="text-left m-0">State</p>}
              name="state"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item>
            </Col>
      

            <Col span={8}> 
            <Form.Item
              label={<p className="text-left m-0 ">Pincode</p>}
              name="pincode"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item> 
            </Col>
      </Row>

     

    </div>

 
</div>


<div className="mt-14">

      <Form.Item className="text-right">

             {/* <Button className="mx-5" type="primary" htmlType="submit"> {button === 'save' ? 'Save' : 'Update' } </Button> */}

        <Button type="primary"  htmlType="submit">
          Next
        </Button>
      </Form.Item>
</div>
   

    </Form>

    </div>
         </FormWrap>
  );

  }

  const FormWrap = styled.div`

.ant-checkbox + span {
    font-size: 16px !important;
}

position: relative;

.imglist {
overflow: hidden;
  h2 { opacity : 0;
     display:none;
    position: absolute;
    margin: 0;
    bottom: 11%;
    background-color: rgba(0,0,0,0.5);
 cursor: pointer;
 transition: 0.1s;

 &:hover {

  font-size: 1.5rem;
 }
    
    }

  &:hover h2 {

    opacity : 1;
    display:block
  }
}

`