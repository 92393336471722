import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import DeleteConfirm from '../../shared/deleteConfirm'
import styled from 'styled-components'
import { useEdit} from '../../../api/services/backend'
import {Link} from 'react-router-dom'

export default function Datatable({data, isLoading}) {

  const [page, setPage] = useState(1);

  const {mutate} = useEdit('city-delete', 'location', 'location')

console.log(data);


    const confirm = (e, id) => {
      const city = data && data.city.find((item, i)=> item._id === id.city )

        const localityData = {
        city:city.city,
        info_type:"locality_info",
        element_id:id.id,
        id:id.city
    }  
    mutate(localityData)
    }

    const cancel = (e) =>{
      return null
    }

  

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },
    {
        title: 'Locality',
        dataIndex: 'locality',
        key: 'locality',
        render: text => <p className="m-0 capitalize font-medium" style={{color:"#1A3353"}}>{text}</p>,
    },

    {
      title: 'Pincode',
      dataIndex: 'pincode',
      key: 'pincode',
      render: text => {
        const name = data && data.pins.find((item, i)=>{   
                return item.id === text 
                })
                return <p className="m-0 capitalize ">{name.pincode}</p>
            }
    },

    {
      title: 'Zone',
      dataIndex: 'zone',
      key: 'zone',
      render: text => {
        const name = data && data.zones.find((item, i)=>{   
                return item.id === text 
                })
                return <p className="m-0 capitalize ">{name.zone}</p>
            }
      },

    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      render: text => {
        // console.log(text);
                
        const name = data && data.city.find((item, i)=>{   
                return item._id === text 
                })
                return <p className="m-0 capitalize ">{name.city}</p>
            }
      },

      
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
                <Link to={`/dashboard/edit-locality/${id.id}`}>
                <h5 style={{color: 'var(--brandColor' }} className="text-secondary  m-0 ">

                <FaRegEdit />                           
                </h5>
                </Link>

              <h5 className="m-0">
                  <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                      <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
                  </DeleteConfirm>
              </h5>

          </Space>
        ),
      },

     ]

 

      return (
        <DataTableWrap>
      

    { 
      !isLoading &&  <Table 
     
    loading={isLoading}
    columns={columns} 
    dataSource={data.locality} 
    className="px-5"

    />

    }       
            
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;


`