import React, {useEffect, useState} from 'react'
import {useCreate, useFetchList} from '../../../../api/services/backend'
import { Form, Input, Button,message, InputNumber ,Checkbox, Select, Row, Col} from 'antd';
import {useParams} from 'react-router-dom'
import { useEdit, useFetchOne} from '../../../../api/services/backend'


const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}


  export default function CreatePincode() {
    const {id} = useParams()

    const {mutate} = useEdit('location/city', 'location')

  const [form] = Form.useForm();
  const {data, isLoading, error} = useFetchList('location')
  const [zoneInfo, setZoneInfo] =  useState<any>([])


  const pincodeOne = data && data.pins.find((item, i)=>{   
    return item.id === id 
    }) 

    console.log(pincodeOne);
    

useEffect(()=>{
    const city = data && data.city.find((item, i)=>{   
        return item._id === pincodeOne.city 
        })  
    const zone = data && data.zones.find((item, i)=>{   
        return item.id === pincodeOne.zone 
        }) 

        setZoneInfo(city.zone_info ? city.zone_info : [])

      pincodeOne && form.setFieldsValue({  
      city:city && city.city,
      zone:zone && zone.id,
      pincode:pincodeOne && pincodeOne.pincode
      })
}, [pincodeOne])



  const onFinish = (values: any) => {
    console.log(values);
    var numbers = /^[0-9]+$/;
    
    if(!values.pincode.match(numbers) )
    {
      return message.error("Pincode must be only numbers") 
    }
    if(values.pincode.length !== 6 ){
      return message.error("pincode must be 6 digit")
     }
else{
    
    const pincodeData = {
      city:values.city,
      element_id:id,
      zone:values.zone,
      info_type:"pincode_info",
      info:"pincode",
      value:values.pincode,
      id:id
  }
  mutate(pincodeData)
  };
}


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };



  const onChangeCity=(value)=> {
        form.resetFields(['zone'])

        const cityFound = data && data.city.find((item, i)=>{   
      return item.city === value 
      
      })      
      setZoneInfo(cityFound.zone_info ? cityFound.zone_info : [])
  }


  const onChangeZone=(value)=> {
    console.log(`selected ${value}`);
  }



  return (
   
    <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
    <h1 className="mx-3 text-xl">Edit Pincode</h1>
  <div className=" mx-5 grid grid-cols-2 mt-12">

    <Form
    {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >   

<Form.Item
            label={<p className="text-left m-0 ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
            >
            <Select
                   showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    onChange={onChangeCity}
                    disabled={true}
                    filterOption={(input, option:any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   } 
                  >
             {
                  data && data.city.map((item, i)=>{     
                                        
                  return <option key={i} value={item.city} >{item.city}</option>

              })
              }
            </Select>
    </Form.Item>

    <Form.Item
            label={<p className="text-left m-0 ">Zone</p>}
            name="zone"
            rules={[{ required: true, message: 'required!' }]}
            >
            <Select
              showSearch
              placeholder="Select Zone"
              optionFilterProp="children"
              onChange={onChangeZone}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              // disabled={true}
            >
              {
                  zoneInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id}>{item.zone}</option>
              })
              }
      
      </Select>
    </Form.Item>
         
      <Form.Item
            label={<p className="text-left m-0   ">Pincode</p>}
            name="pincode"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{width:'100%'}} />
          </Form.Item>
      

          <div className="mt-10">

            <Form.Item  className="text-right">
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
        </div>
   

    </Form>

    </div>
    </div>

  );

  }