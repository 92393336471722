import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../api/store';
import axios from 'axios'
import key from '../key'
const token = localStorage.getItem('sftoken')

const config = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    // type:'admins'
  }
};


export interface CounterState {
  loading: boolean;
  name: any,
  user: any,
  role: any,
  target_ads: any,
  current_ads: any,
  current_target: any
  current: any,
  filter: any,
  latlng: any,
  hasErrors: any,
  isAuthenticate: any,
  userType: undefined | string
}

const initialState: CounterState = {
  loading: false,
  name: null,
  user: null,
  role: null,
  target_ads: [],
  current_ads: [],
  current_target: [],
  hasErrors: false,
  isAuthenticate: token ? true : false,
  current: [],
  filter: [],
  latlng: {},
  userType: undefined
};


export const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    getProfile: (state, { payload }) => {
      console.log(payload);
      state.user = payload.data
      state.name = payload.name
      state.role = payload.role
      state.loading = false
      state.userType = payload.userType
    },
    getLoading: state => {

      state.loading = true

    },
    isAuthenticateError: state => {

      state.hasErrors = true;
      state.loading = false;
      state.isAuthenticate = false


    },

    getTargetAdsList: (state, { payload }) => {
      // console.log(payload);

      state.loading = false
      state.target_ads = payload?.target
      state.current_ads = payload?.ads_id

    },

    getCurrentTargets: (state, { payload }) => {
      state.loading = false
      state.current_target = payload

    },
    getFilter: (state, { payload }) => {
      console.log(payload);

      state.loading = false;
      state.filter = payload
      state.current = payload
    },

    getLatlang: (state, { payload }) => {

      state.loading = false;
      state.latlng = payload

    },

  },

});

export const { getProfile, getFilter, getLoading, getLatlang, getTargetAdsList, getCurrentTargets, isAuthenticateError } = authSlice.actions;


export const selectAuth = (state: RootState) => state.profile

export const fethFilter = (value, filter) => async dispatch => {

  try {
    let a = []
    console.log({ test: value });
    console.log({ filter: filter });

    if (!value) {
      return dispatch(getFilter(a))

    }
    const { data } = await axios.get(key.BACK_ENDURL + `/${value}?search=${filter}`, config)
    console.log(data);

    dispatch(getFilter(data))

  } catch (error) {

    dispatch(isAuthenticateError())
  }

}

export default authSlice.reducer;