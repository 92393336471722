import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.less';
import Blog from './components/blog';
import Dashboard from './components'
import Login from './components/auth/login'
import { QueryClientProvider, QueryClient, } from 'react-query'


const queryClient = new QueryClient()


function App() {
  let token = localStorage.getItem('sftoken');
  console.log(token)
  return (
    <QueryClientProvider client={queryClient}>

  <Router>
    <main className="App">
     <Route path="/" component={Login} exact/>
     <Route path="/dashboard" component={Dashboard} />

     </main>
    </Router>
</QueryClientProvider>
  );
}

export default App;
