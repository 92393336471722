import React, {useEffect, useState} from 'react'
import {useFetchList, useEdit, useFetchOne} from '../../../../api/services/backend'
import {useParams} from 'react-router-dom'
import { Form, Input, Button, Upload ,Image, Select, InputNumber} from 'antd';
import storage from '../../../shared/storage'
import {MdClose, MdDelete} from 'react-icons/md'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Loader from '../../../shared/loader';
import styled from 'styled-components'

const { Option } = Select;

const layout = {
    labelCol: { span: 24},
    wrapperCol: { span: 24 },
  }
  
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

  export default function EditProduct() {


  const {mutate} = useEdit('category')


  const [loading1, setLoading1] = useState(false)
  const [imgurl, setImgurl] = useState<any>([])
  const [fileList, setFileList] = useState([])
  const {id} = useParams()

  const [form] = Form.useForm();


  const {data} = useFetchOne('category', id)

console.log(data);

  useEffect(()=>{
    
    data && setImgurl([data?.category.category_info?.image])

    data && form.setFieldsValue({
       category_name:data?.category?.category_info.category_name,
       category_order:data?.category?.category_info?.category_order
      })
}, [data])




  const onFinish = (values: any) => {
        console.log(values);

        const categorydata = {
    
            category_info: {
                category_name:values.category_name,
                category_order:values.category_order,
                image: imgurl.length>=1 ? imgurl[0] : null,

             },
             id:data.category._id
       }
  mutate(categorydata)
 
  };

  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  const handleChange = info => {
    setLoading1(true)
       
        storage
        .ref("images/" + info.file.name)
        .put(info.file.originFileObj)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL();
        })
        .then(url => {
          console.log(url);
          setImgurl([...imgurl, url])
          setLoading1(false)
    
        })
        .catch(error => {
          console.log(error);
        });
    
      };
    

      const remove = (e, url) =>{

        setImgurl(prev => prev.filter(item => item !== url))
        
        }

        const uploadButton = (
            <div>
              { loading1 ? <LoadingOutlined  /> : <PlusOutlined />}
              <div style={{ marginTop: 8, fontSize:"14px" }}>{loading1 ? "uploading" :""}</div>
            </div>
          );

  
return (
      <FormWrap>
        <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'82vh', border:'1px solid #e5e5e5'}}>

    <Form
    {...layout}
      name="basic"
      // labelCol={{ span: 4 }}
      // wrapperCol={{ span: 10 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
   <div className="grid grid-cols-2 gap-10 mt-6 mx-4 ">   
      <div >
      <Form.Item
        label={<p className="text-left m-0 ">Category Name</p>}
        name="category_name"
        rules={[{ required: true, message: 'required' }]}
      >
        <Input  style={{width:'100%'}} />
      </Form.Item>
  
      <Form.Item
        label={<p className="text-left m-0 ">Category Order</p>}
        name="category_order"
        rules={[{ required: true, message: 'required' }]}
      >
        <InputNumber  style={{width:'100%'}} />
      </Form.Item>

      <Form.Item
            label={<p className="text-left m-0 ml-2 ">Image</p>}
            name="image"
            // rules={[{ required: true, message: 'required!' }]}
          >
                <div 
                    className=" grid grid-cols-4 gap-5">
                {

                imgurl.map((img, i)=>{

                  return <div className=" imglist  bg-gray-50 text-center" style={{height:"100px"}}>
                  {/* <img key={i} className="  rounded col-span-1  block  object-cover" style={{height:"100%", width:"100%"}} src={img} alt="hj"   /> */}
                   
                  <Image    
                   preview={false}               
                   key={i}
                   className="  rounded col-span-1  block  object-cover"
                   style={{height:"100%", width:"100%"}}
                   src={img}
                   placeholder={<Loader/> }              
                 />   
                  <h2 onClick={(e)=>remove(e, img)} className="p-1 text-white  text-xl "> <MdDelete/></h2> 

                    </div>
                })
                }

                <Upload       
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleChange}
                        multiple={true}
                        className="ml-2"
                        >
                        {imgurl.length >= 1 ? null : uploadButton}
                        </Upload>
                </div> 


        </Form.Item>

      <div className="mt-14">
      <Form.Item className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      </div> 
      </div>
   </div>

    </Form>

    </div>
      </FormWrap>
  );

  }

  const FormWrap = styled.div`

position: relative;


.imglist {
overflow: hidden;

h2 { opacity : 0;
display:none;
position: absolute;
margin: 0;
bottom: 0%;
background-color: rgba(0,0,0,0.5);
cursor: pointer;
transition: 0.1s;

&:hover {

font-size: 1.5rem;
}

}

&:hover h2 {

opacity : 1;
display:block
}
}

`