import { Button, Col, Form, Input, Row, Select } from 'antd'
import { useCreate, useEdit } from '../../../api/services/backend'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
}

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};


export default function AgencyForm({ fdata, apartment_info, isEdit }) {

    const [form] = Form.useForm();
    const { mutate, isLoading } = useCreate('apartment');
    const { mutate: updateData, isLoading: updateLoading } = useEdit('apartment')

    const { id } = useParams() as any;

    useEffect(() => {
        console.log({fdata});
        
        form.setFieldsValue(fdata)
    }, [fdata])

    const onFinish = (agency_info: any) => {
        console.log(agency_info);
        
        delete agency_info.confirm_password;
        if(!agency_info?.password) delete agency_info.password;

        isEdit
        ? updateData({
            apartment_info: {
                apartment_info: {
                    name: apartment_info.name,
                    address: apartment_info.address,
                    latitude: apartment_info.latitude,
                    longitude: apartment_info.longitude,
                    block: apartment_info.block,
                    sample: apartment_info.sample,
                },
                total_houses: apartment_info.total_houses,
            },
            agencyData: {
                ...agency_info,
                id: fdata?._id
            },
            id
        })
        : mutate({ agency_info, ...apartment_info })
    }
     

    const onFinishFailed = () => { }

    return (
        <Form
            {...layout}
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >

            <div className="grid grid-cols-3  mt-6 mx-4 ">
                <div className="col-span-2">
                    <Form.Item
                        label={<p className="text-left m-0 ">Name</p>}
                        name="name"
                        rules={[{ required: true, message: 'required!' }]}
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>


                    <Form.Item
                        label={<p className="text-left m-0  ">Email</p>}
                        name="email"
                        rules={[{ required: true, message: 'required!', type: "email" }]}
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label={<p className="text-left m-0  ">Password</p>}
                        name="password"
                        rules={[{ required: false, message: 'required!' }]}
                    >
                        <Input.Password style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label={<p className="text-left m-0  ">Confirm Password</p>}
                        name="confirm_password"
                        dependencies={['password']}
                        rules={[{ required: false, message: 'required!' }, ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Passwords did not match!'));
                            },
                        })]}
                    >
                        <Input.Password style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        label={<p className="text-left m-0  ">Bank Name</p>}
                        name="bank_name"
                        rules={[{ required: true, message: 'required!' }]}
                    >
                        <Input style={{ width: '100%', textTransform: "capitalize" }} />
                    </Form.Item>
                    <Form.Item
                        label={<p className="text-left m-0  ">IFSC Code</p>}
                        name="ifsc_code"
                        rules={[{ required: true, message: 'required!' }]}
                    >
                        <Input style={{ width: '100%', textTransform: "uppercase" }} />
                    </Form.Item>
                    <Form.Item
                        label={<p className="text-left m-0  ">Account Number</p>}
                        name="acc_number"
                        rules={[{ required: true, message: 'required!' }]}
                    >
                        <Input style={{ width: '100%', textTransform: "uppercase" }} />
                    </Form.Item>
                    <div className="mt-5">
                        <Form.Item {...tailLayout} className="text-right">
                            <Button type="primary" htmlType="submit">
                                {
                                    (isLoading || updateLoading)
                                        ? "Loading..."
                                        : isEdit ? "Update" : "Submit"
                                }
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </div>
        </Form>
    )
}
