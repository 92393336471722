import React, {useState, useEffect} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import DeleteConfirm from '../../shared/deleteConfirm'
import styled from 'styled-components'
import { useEdit } from '../../../api/services/backend'
import {Link} from 'react-router-dom'

export default function Datatable({data, isdataLoading }) {

  const [page, setPage] = useState(1);

  const {mutate, isLoading } = useEdit('city-delete', 'location', 'location')


    const confirm = (e, id) => {
      const city = data && data.city.find((item, i)=> item._id === id.city )

        const zoneData = {
        city:city.city,
        info_type:"zone_info",
        // info:"zone",
        element_id:id.id,
        id:id.city
    }  
    mutate(zoneData)
    }

    const cancel = (e) =>{
      return null
    }
  

    
   useEffect(() => {
    console.log('testing...');
    console.log(isLoading);
    
  })


  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },

    {
      title: 'Zone',
      dataIndex: 'zone',
      key: 'zone',
      render: text => {
                return <p className="m-0 capitalize font-medium">{text}</p>
            }
      },

      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        render: text => {
          // console.log(text);
                  
          const name = data && data.city.find((item, i)=>{   
                  return item._id === text 
                  })
                  return <p className="m-0 capitalize ">{name.city}</p>
              }
        },
      
      {
        title: 'No. of Pincodes',
        dataIndex: 'id',
        key: 'id',
        render: text => {
            const name = data && data.pins.filter((item, i)=>{   
                    return item.zone === text 
                    })
                    
                    return <p className="m-0 capitalize ">{name.length}</p>
                }
        },
        {
          title: 'No. of Locality',
          dataIndex: 'id',
          key: 'id',
          render: text => {
              const name = data && data.locality.filter((item, i)=>{   
                      return item.zone === text 
                      })
                      
                      return <p className="m-0 capitalize ">{name.length}</p>
                  }
          },
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
                <Space size="middle">
                <Link to={`/dashboard/edit-zone/${id.id}`}>
                <h5 style={{color: 'var(--brandColor' }} className="text-secondary  m-0 ">

                <FaRegEdit />                           
                </h5>
                </Link>

              <h5 className="m-0">
                  <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                      <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
                  </DeleteConfirm>
              </h5>

          </Space>
        ),
      },

     ]

 

      return (
        <DataTableWrap>
      

    { 
      !isdataLoading &&  <Table 
     
    loading={isdataLoading}
    columns={columns} 
    dataSource={data.zones} 
    className="px-5"
    rowKey={(record)=>record.id}
    expandable={{
        expandedRowRender: record => {
         return <div className="px-5 py-2 text-sm " style={{width:'100%', color:'grey'}}>       
            <div className="text-left" style={{ letterSpacing:"0.03rem"}}>
               <p><b>Pincodes : </b></p>

               { 
                data && data.pins.map((item, i)=>{   
                    return item.zone === record.id ? <p className="mx-4">{item.pincode} </p> : ''
                    })                  
                }

            </div>
       </div>
      }, 
    } }
    />

    }       
          
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;


`