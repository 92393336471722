import  React,{ useEffect } from 'react'
import {  Tabs  } from 'antd';
import Basic from './basic'
import Farm from './farm'
import Crop from './crop'
import Bank from './bank'
import RManager from './relationshipManager'
import {useDispatch, useSelector} from 'react-redux'

import { Pbasic, Pfarm, Pcrop, Pbank, Pmanager, pFinish, getBasicInfo, getFarmInfo, getCropInfo, getBankInfo, farmerSelector } from '../../../../api/services/farmer'

const { TabPane } = Tabs;

  export default function CreateFarmerIndex() {

    const dispatch = useDispatch()
    const {   activetab, basic, farm, crop, bank, manager } = useSelector(farmerSelector)
  

    const ontab = (k, e) =>{
      e.preventDefault();
        if(k === "2"){
            dispatch(Pfarm());
      
        } else if(k === "3"){
      
          dispatch(Pcrop());

        }else if(k === "4"){
      
          dispatch(Pbank());
        }
        else if(k === "5"){
      
          dispatch(Pmanager());

        } else {
      
          dispatch(Pbasic());
        }   
      }
  

  return (

   <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>

            <Tabs           
              activeKey={activetab}             
               onTabClick={(k, e)=>ontab(k, e)}
             >    
              
              <TabPane tab="Basic" key="1">
              <Basic info={getBasicInfo} actionMethod={ Pfarm}/>
              </TabPane>

            <TabPane tab="Farm"  disabled = {farm ? false : true} key="2">
                  <Farm info={getFarmInfo} actionMethod={ Pcrop}/>
            </TabPane>
            <TabPane tab="Crop"  disabled = {crop? false : true} key="3">
                  <Crop info={getCropInfo} actionMethod={ Pbank}/>
            </TabPane>
            <TabPane tab="Bank"  disabled = {bank? false : true}  key="4">
                  <Bank info={getBankInfo}  actionMethod={ Pmanager}/>
            </TabPane>
            <TabPane tab="Relationship Manager" disabled = {manager? false : true} key="5">
                  <RManager actionMethod={ pFinish}/>
            </TabPane>
    </Tabs>
   </div>  
  );

  }