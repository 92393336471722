import { Button, Checkbox, DatePicker, Form, Input, Select, Space, Table, Tag } from 'antd';
import { useCreate, useDelete, useFetchList } from '../../../api/services/backend';
import { useEffect, useState } from 'react';

export default function Coupon() {

    const { data } = useFetchList('location')

  const {data:coupons, refetch, isLoading} =   useFetchList('coupon')


    const onSuccess = ()=>{

          refetch()

    }

  const {mutate} =   useCreate('coupon', onSuccess)
  const {mutate:deleteCoupon} =   useDelete('coupon')


const [pincode, setPinCode] = useState(null) as any
const [appartment, setAppartment] = useState(null) as any
const { data: appartments } = useFetchList(`apartmentbypin?pin=${pincode}`, {enabled:Boolean(pincode)})


    useEffect(()=>{

        console.log({coupons});
        

    }, [coupons])

    const onFinish = (values) => {
        mutate(values)
        console.log('Success:', values);
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

      const handleDelete = (record)=>{
        deleteCoupon(record?._id)

      }

    const columns = [
        {
          title: 'Coupon Code',
          dataIndex: 'coupon_code',
          key: 'coupon_code',
          render: (text) => <p className='font-semibold'>{text}</p>,
        },
        {
          title: 'Coupon Discount',
          dataIndex: 'coupon_price',
          key: 'coupon_price',
          render: (text) => <p className='font-semibold'>{text}%</p>,
        },
        {
            title: 'Target Appartment',
            dataIndex: 'apartment',
            key: 'apartment',
            render: (appartment) =>{
              return <p>{appartment?.apartment_info?.name}</p>
            } ,
          },
        {
          title: 'Expairy Date',
          dataIndex: 'expairy_date',
          key: 'expairy_date          ',
          render:(date, data)=>{

            const dateformate = new Date(date).toLocaleDateString();
            console.log({dateformate});
            


            return <Tag  className=' rounded-full' color={data?.isExpired ?  "red": "green"} key={"tag"}>
          {dateformate}
        </Tag>

          } 
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <button onClick={()=>handleDelete(record)}>Delete</button>
            </Space>
          ),
        },
      ];

      const onChangePincode = (value:any)=>{
        console.log({value});

        setPinCode(value)
        
      }

      const onChangeAppartment = (value:any)=>{
        console.log({value});

        // setPinCode(value)
        
      }

  return (
    <div className=' grid grid-cols-9 gap-12'>
<div className=' col-span-3 rounded  p-4'>
<br/>

<Form
   layout='vertical'
    name="basic"
   

    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
    
  >
    <Form.Item
      label="Coupon code"
      name="coupon_code"
      rules={[
        {
          required: true,
          
        },
      ]}

    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Coupon Price"
      name="coupon_price"
      rules={[
        {
          required: true,
          
        },
      ]}

    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Expairy Date"
      name="expairy_date"
      rules={[
        {
          required: true,
        },
      ]}
 
    >
      <DatePicker />
    </Form.Item>

    <Form.Item
                label={<p className="text-left m-0  ">Pincode</p>}
                name="pincode"
                rules={[{ required: true, message: 'required!' }]}
              >
                <Select
                  showSearch
                  placeholder="Select Pincode"
                  optionFilterProp="children"
                  onChange={onChangePincode}
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={data?.pins?.length < 1 ? true : false}
                  
                >
                  {
                    data?.pins?.map((item, i) => {
                      return <Select.Option key={i} value={item.id} >{item.pincode}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>


             { appartments &&  <Form.Item
  label={<p className="text-left m-0  ">Apartment</p>}
  name="apartment"
>
  <Select
    showSearch
    placeholder="Select Apartment"
    optionFilterProp="children"
    onChange={onChangeAppartment}
    filterOption={(input, option: any) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {appartments?.map((item, i) => (
      <Select.Option key={i} value={item._id}>
        {item.apartment_info?.name}
      </Select.Option>
    ))}
  </Select>
</Form.Item>}

    <Form.Item
 
    >
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
  </div>
 <div className=' col-span-6'>

  <Table loading={isLoading} columns={columns} dataSource={coupons?.coupons} />

 </div>

    </div>
  )
}
