/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react'
import {useCreate, useTarget, useFetchList} from '../../../api/services/backend'
import { Form, Input,notification, Button,Select, Upload ,Checkbox, Row, Col} from 'antd';
import moment from 'moment' 
import {MdClose} from 'react-icons/md'
const { Option } = Select;


const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };



  export default function Createtarget() {

  const {mutate} = useTarget('target')

  const {data, isLoading, error} = useFetchList('location')
  const {data:advertisement} = useFetchList('advertisement')

  const [imgurl, setImgurl] = useState('')
  const [cityFound, setCityInfo] =  useState<any>(null)
  const [zoneInfo, setZoneInfo] =  useState<any>([])
  const [pincodeInfo, setPincodeInfo] =  useState<any>([])
  const [locality_info, setLocalityInfo] =  useState<any>([])

  const [form] = Form.useForm();


  const onFinish = (values: any) => {
        // console.log(values);

        const data = {
        target_info:
        {
          ads_id :values.advertisement,
          zone_id:values.zone,
          pincode_id:values.pincode,
          city_id:values.city,
          locality_id:values.locality
        }    
      }
      
  mutate(data)
  // form.resetFields()
  };

  
  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const handleChangeAd = (value) =>{
    // console.log(value);
}

const onchangeCity = (value) =>{
  form.resetFields(['zone'])
  form.resetFields(['pincode'])
   const cityFound = data && data.city.find((item, i)=>{   
     return item._id === value 
     
     })

     setZoneInfo(cityFound.zone_info ? cityFound.zone_info : [])
     setCityInfo(cityFound)
    cityFound && form.setFieldsValue({
      state:cityFound && cityFound.state
    })
    setPincodeInfo([])
  }



  const onChangeZone=(value)=> {
    form.resetFields(['pincode'])
    const zoneFound = cityFound.pincode_info.filter((item, i)=>{   
                 return item.zone === value 
    })
    setPincodeInfo(zoneFound)
  }
 
  const onChangePincode=(value)=>{
    
    const locality = cityFound.locality_info.filter(item=>{   
                 return item.pincode === value 
    })
    setLocalityInfo(locality)  
  }


  const onChangeLocality = (value) =>{

    // console.log(value);
    
  }  
  
  return (
   
    <div  className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
{/* <h3 className="text-left pl-5" style={{color:' #3e79f7 '}}>Select Target Location</h3> */}
   
    <Form
    {...layout}
      name="basic"
      // labelCol={{ span: 4 }}
      // wrapperCol={{ span: 10 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="grid grid-cols-1 gap-5 mt-6 mx-4 ">   
  <div >

  <Form.Item
            label={<p className="text-left m-0   ">Advertisement</p>}
            name="advertisement"
            rules={[{ required: true, message: 'required!' }]}
          >
             <Select 
             placeholder="Select Advertisement"    
             showSearch
             optionFilterProp="children"
             filterOption={(input, option:any) =>
               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
             onChange={handleChangeAd}>

                  {
                  advertisement && advertisement.advertisement.map((item, i)=>{ 
                    // console.log(item?.basic_info?.sale_date);
                    
                  let isexpire =  moment().isBetween(item?.basic_info?.sale_date[0], item?.basic_info?.sale_date[1], undefined, '()');  
                  // console.log(isexpire);
                  
                    const crop = item.farmer?.crop_info.find(c=> c.id === item.basic_info?.crop)                     
                         if(isexpire){
                           return <option key={i} value={item._id} >{item.ad_no} ({item.farmer?.farmer_info?.farmer_name}) ({crop.crop_name})</option>
                         }         
                  return 

              })
            }
   
   
             </Select>
          </Form.Item>

  <Form.Item
            label={<p className="text-left m-0   ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
          >
             <Select 
             showSearch
             placeholder="Select City"    
             optionFilterProp="children"
             filterOption={(input, option:any) =>
               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
             onChange={onchangeCity}>

                  {
                  data && data.city.map((item, i)=>{     
                                        
                  return <option key={i} value={item._id} >{item.city}</option>

              })
            }
   
   
             </Select>
          </Form.Item>

          <Form.Item
            label={<p className="text-left m-0   ">Zone</p>}
            name="zone"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Select
              showSearch
              placeholder="Select Zone"
              optionFilterProp="children"
              onChange={onChangeZone}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              disabled={zoneInfo.length < 1 ? true : false}
            >
              {
                  zoneInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.zone}</option>
              })
              }
      
      </Select>
          </Form.Item>

   <Form.Item
            label={<p className="text-left m-0 ">Pincode</p>}
            name="pincode"
            rules={[{ required: true, message: 'required!' }]}
            >
              <Select
             showSearch
              placeholder="Select Pincode"
              optionFilterProp="children"
              onChange={onChangePincode}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              disabled={pincodeInfo.length <1 ? true : false}
            >
              {
                  pincodeInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.pincode}</option>
              })
              }
      
      </Select>
    </Form.Item>

    <Form.Item
            label={<p className="text-left m-0">Locality</p>}
            name="locality"
            // rules={[{ required: true, message: 'required!' }]}
            >
              <Select
             showSearch
              placeholder="Select locality"
              optionFilterProp="children"
              onChange={onChangeLocality}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              disabled={pincodeInfo.length <1 ? true : false}
            
            >
              {
                  locality_info.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.locality}</option>
              })
              }
      
      </Select>
    </Form.Item>

<div className="mt-6">
      <Form.Item {...tailLayout} className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
</div>
</div>
</div>
    </Form>

    </div>
  );

  }