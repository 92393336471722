import  React,{ useEffect,useState } from 'react'
import {motion} from 'framer-motion'
import {   useQuery } from 'react-query'
import key from '../../../key'
import { Table,} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {useFetchList} from '../../../api/services/backend'
import {Button,Input} from 'antd'
import {Link} from 'react-router-dom'
import ModalForm from '../../shared/modal'
import {PlusOutlined,SearchOutlined} from '@ant-design/icons'
import DataTable from './datatable'


interface Props {

    title? :string
}

 const Order: React.FC<Props> = ({title}) => {

   const {data, isLoading, error} = useFetchList('logbook')
   
   const [visible, setVisible] = useState(false)
  console.log(data);
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  console.log(search);
  

    return (
      
      <div className="p-5 rounded-lg bg-white" style={{border:'1px solid #e5e5e5'}}> 
          <div className="flex mb-5 items-center justify-between">    
           <div>
        
           </div>
        
   
        </div>
       
        <DataTable  isloading={isLoading || isLoading} 
         data={data} />
      
     </div>



    )
}

export default Order


