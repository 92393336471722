import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'
import moment from 'moment'

export default function Datatable({data, isloading}) {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const {mutate} = useDelete('product')
  const [visibleEdit, setEditModal] = useState(false);
  const [curr_product, setProduct] = useState(null);

    const confirm = (e, id) => {
      console.log(id._id);
      mutate(id._id)
    }

    const cancel = (e) =>{
      return null
    }

      console.log(data);
      
  const columns = [

    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },

      {
        title: 'Event',
        dataIndex: 'event',
        key: 'event',
        render: text => <p className="m-0">{text}</p>
      },

      {
        title: 'Updates',
        dataIndex: 'updated_by',
        key: 'updated_by',
        render: text => <p className="m-0">{text}</p>
      },

      {
        title: 'Date',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: text => <p className="m-0">{moment(text).format('DD/MM/YYYY')}</p>,
      },
  
      {
        title: 'Time',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: text => <p className="m-0">{ moment(text).format('LT')}</p>,
      },
        
     ]

 
      return (
        <DataTableWrap>
        { 
        !isloading && <Table 
        pagination={{
          onChange(current) {
            setPage(current)
          }
        }}
        loading={isloading}
        columns={columns} 
        dataSource={data} 
        rowKey={(record)=>record._id}
        
        />

        }       
          </DataTableWrap>  
     )
 }
   
const DataTableWrap = styled.div`

min-height: 70vh;

`