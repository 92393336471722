import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useCreate, useFetchList} from '../../../../api/services/backend'
import { Form, Input, Button, Upload , Select, Image, DatePicker, Row, Col, InputNumber} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { farmerSelector } from '../../../../api/services/farmer'
import DataTable from './cropTable'
import { nanoid } from '@reduxjs/toolkit'
import moment from 'moment'
import styled from 'styled-components'
import storage from '../../../shared/storage'
import {MdClose, MdDelete} from 'react-icons/md'
import Loader from '../../../shared/loader';

const { Option } = Select;
const { RangePicker } = DatePicker;

  export default function CreateCrop({ actionMethod, info }) {
    const dispatch = useDispatch()

  const { farm_data } = useSelector(farmerSelector)

  const [form] = Form.useForm();
  const [imgurl, setImgurl] = useState<any>([])
  const [loading1, setLoading1] = useState(false)
  const [fileList, setFileList] = useState([])

  const [button, setButton] = useState('save')
  const [id, setId] = useState(null)

  const [crop, setCrop] = useState<any>([])





  // const handleClickEdit = (e, id) => {
  //   e.preventDefault() 

  //   id && form.setFieldsValue({
  //      farm: id.farm,
  //      crop_name: id.crop_name,
  //      units: id.units,
  //      about_crop: id.about_crop,
  //      harvesting_time:id.harvesting_time.map((item)=>{
  //       return moment(item)
  //     })
  //    });
  //    setButton('update')
  //    setId(id.id)
  // }


  // const handleClickDelete = (id) => {
  //   console.log(id);
  //   const newCrop = crop.filter(item=> {
  //       return item.id !== id.id
  //   })      
  //   setCrop(newCrop)
  //   message.info('Crop Deleted')
  // }


  const onFinish = (values: any) => {
    const data = {
         crop_name: values.crop_name,
         quantity:values.quantity,
         units:values.units,
         image:imgurl,
         about_crop: values.about_crop,
         harvesting_time: values.harvesting_time,
        //  farm: values.farm,
        //  id: button === 'update' ? id : nanoid()
         farm: farm_data[0].id,
         id: nanoid()
      }

        dispatch(actionMethod())
        dispatch(info([data]))

      // if(button === 'update'){
      //   const newCrop = crop.map(item=> {
      //     if(item.id === id){
      //       return data
      //     } 
      //     return item
      //   })
      //   setCrop(newCrop)
      //   setButton('save')
      //   message.info('Crop Updated')
      // }

    //   else {
    //     setCrop([...crop, data ])
    //     setButton('save')
    //     message.info('Crop Added')
    // }
    // setId(null)
    // form.resetFields()
  };


  // const changeTab = () =>{
  //   dispatch(actionMethod())
  //   dispatch(info(crop))

  // }
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


const handleUnitSelect = (value) =>{
  console.log(value);
}

  const handleFarmSelect = (value) =>{
    console.log(value);
}

const handleChange = info => {
  setLoading1(true)
     
      storage
      .ref("images/" + info.file.name)
      .put(info.file.originFileObj)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      })
      .then(url => {
        console.log(url);
        setImgurl([...imgurl, url])
        setLoading1(false)
  
      })
      .catch(error => {
        console.log(error);
      });
  
    };
  
  
  const remove = (e, url) =>{
  
  setImgurl(prev => prev.filter(item => item !== url))
  
  }
  
  
    const uploadButton = (
      <div>
        { loading1 ? <LoadingOutlined  /> : <PlusOutlined />}
        <div style={{ marginTop: 8, fontSize:"14px" }}>{loading1 ? "uploading" :""}</div>
      </div>
    );

  return (
    <FormWrap>

   <div className="mt-5 mr-10 ml-5">

{/* {
  crop && crop.length>0 ? <DataTable  isLoading={false} edit={handleClickEdit} deleteFarm={handleClickDelete} farm={farm_data} data={crop} /> : '' 
} */}

    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<div className="grid grid-cols-3 gap-1 ">

<div className="col-span-2">

          {/* <Form.Item
                label={<p className=" text-left m-0 w-32">Select Farm</p>}
                  name="farm"
                  rules={[{ required: true, message: 'required!' }]}
                >
                      <Select placeholder="Farm Name"  onChange={handleFarmSelect}>
              {
                  farm_data && farm_data.map((item, i)=>{     
                                        
                  return <option key={i} value={item.id} >{item.farm_name}</option>

              })
              }
              </Select>
              </Form.Item> */}

              
              <Form.Item
        label={<p className="text-left m-0">Crop Name</p>}
        name="crop_name"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>


<Row gutter={20}>
<Col span={8}>
      <Form.Item
        label={<p className="text-left m-0  ">Quantity </p>}
        name="quantity"
        rules={[{ required: true, message: 'required' }]}
      >
        <InputNumber  style={{width:'100%'}}/>
      </Form.Item>
      </Col>

<Col span={8}>
      <Form.Item
                label={<p className=" text-left m-0">Units</p>}
                  name="units"
                  rules={[{ required: true, message: 'required!' }]}
                >
                      <Select  onChange={handleUnitSelect}>

                      <Option value="kilogram">kilogram</Option>
                      <Option value="tonne">tonne</Option>

                </Select>
              </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
            label={<p className="text-left m-0">Harvesting Time</p>}
            name="harvesting_time"
            rules={[{ required: true, message: 'required!' }]}
          >
            <RangePicker />
          </Form.Item>
      </Col>

  </Row>
            
<Form.Item
        label={<p className="text-left m-0 ml-2">Images</p>}
        name="image"
        rules={[{ required: true, message: 'required!' }]}
      >
    <div 
            className=" grid grid-cols-4 gap-5">
        {

          imgurl && imgurl.map((img, i)=>{

          return <div className=" imglist  bg-gray-50 text-center" style={{height:"100px"}}>
          {/* <img key={i} className="  rounded col-span-1  block  object-cover" style={{height:"100%", width:"100%"}} src={img} alt="hj"   /> */}
          
          <Image    
          preview={false}               
          key={i}
          className="  rounded col-span-1  block  object-cover"
          style={{height:"100%", width:"100%"}}
          src={img}
          placeholder={<Loader/> }              
        />   
          <h2 onClick={(e)=>remove(e, img)} className="p-1 text-white  text-xl "> <MdDelete/></h2> 

            </div>
        })
        }
        <Upload
                
                  listType="picture-card"
                  fileList={fileList}
                  onChange={handleChange}
                  multiple={true}
                  className="ml-2"
                >
                  {imgurl.length >= 1 ? null : uploadButton}
                </Upload>
          </div> 

        </Form.Item>

      
      
      <Form.Item
            label={<p className="text-left m-0">About Crop</p>}
            name="about_crop"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input.TextArea  style={{width:'100%'}} rows={2}/>
          </Form.Item>
            

      
      <div className="mt-10">
      <Form.Item className="text-right">

      {/* <Button className="mx-5" type="primary" htmlType="submit"> {button === 'save' ? 'Save' : 'Update' } </Button> */}

      <Button type="primary"  htmlType="submit">
      Next
      </Button>
      </Form.Item>
      </div>

 </div>
  <div>
            
   </div>    
 
</div>


    </Form>

    </div>
</FormWrap>
  );

  }

  
  const FormWrap = styled.div`
  position: relative;

.imglist {
overflow: hidden;
h2 { opacity : 0;
 display:none;
position: absolute;
margin: 0;
bottom: 11%;
background-color: rgba(0,0,0,0.5);
cursor: pointer;
transition: 0.1s;

&:hover {

font-size: 1.5rem;
}

}

&:hover h2 {

opacity : 1;
display:block
}
}

`