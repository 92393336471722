import React, {useEffect, useState} from 'react'
import {useCreate, useFetchList} from '../../../../api/services/backend'
import { Form, Input, Button, message, Select, } from 'antd';
const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };



  export default function CreateLocality({cancel}) {

  const {mutate} = useCreate('location')

  const [form] = Form.useForm();
  const {data, isLoading, error} = useFetchList('location')

 const [cityInfo, setCityInfo] =  useState<any>(null)
 const [zoneInfo, setZoneInfo] =  useState<any>([])
 const [pincodeInfo, setPincodeInfo] =  useState<any>([])

 

  const onFinish = (values: any) => {
    console.log(values);

    const localityData = {
      city:values.city,
      zone:values.zone,
      pincode:values.pincode,
      locality:values.locality
  }
  
  mutate(localityData)
  form.resetFields()
  };
  
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  const onChangeCity=(value)=> {
    form.resetFields(['zone'])
    form.resetFields(['pincode'])
    const cityFound = data && data.city.find((item, i)=>{   
      return item.city === value     
      })
      setCityInfo(cityFound)
      setZoneInfo(cityFound.zone_info ? cityFound.zone_info : [])
      setPincodeInfo([])
  }



  const onChangeZone=(value)=> {
    form.resetFields(['pincode'])
    const pinsFound = cityInfo.pincode_info.filter((item, i)=>{   
        return item.zone === value 
    })
    setPincodeInfo(pinsFound)
  }

  const onChangePincode=(value)=>{
    console.log(`selected ${value}`);
  
  }

  return (
   
    <div className=" mx-5 grid grid-cols-2 mt-6">

    <Form
    {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >   

<Form.Item
            label={<p className="text-left m-0 ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
            >
            <Select
                    showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    filterOption={(input, option:any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   } 
                    onChange={onChangeCity}
                  >
             {
                  data && data.city.map((item, i)=>{     
                                        
                  return <option key={i} value={item.city} >{item.city}</option>

              })
              }
            </Select>
    </Form.Item>

    <Form.Item
            label={<p className="text-left m-0 ">Zone</p>}
            name="zone"
            rules={[{ required: true, message: 'required!' }]}
            >
            <Select
              showSearch
              placeholder="Select Zone"
              optionFilterProp="children"
              onChange={onChangeZone}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              disabled={zoneInfo.length <1 ? true : false}
            >
              {
                  zoneInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.zone}</option>
              })
              }
      
      </Select>
    </Form.Item>
         
    <Form.Item
          label={<p className="text-left m-0  ">Pincode</p>}
          name="pincode"
           rules={[{ required: true, message: 'required!' }]}
         >
         <Select
             showSearch
              placeholder="Select Pincode"
              optionFilterProp="children"
              onChange={onChangePincode}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              disabled={pincodeInfo.length <1 ? true : false}
            >
              {
                  pincodeInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.pincode}</option>
              })
              }
      
      </Select>
         </Form.Item>
      
          <Form.Item
            label={<p className="text-left m-0   ">Locality</p>}
            name="locality"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{width:'100%'}} />
          </Form.Item>

          <div className="mt-10">

            <Form.Item  className="text-right">
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
        </div>
   

    </Form>

    </div>
  );

  }