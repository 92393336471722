import React, {useEffect, useState} from 'react'
import {useCreate, useFetchList} from '../../../../api/services/backend'
import { Form, Input, Button, InputNumber ,Checkbox, Select, Row, Col} from 'antd';
import { useEdit, useFetchOne} from '../../../../api/services/backend'
import {useParams} from 'react-router-dom'

const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}


  export default function CreateZone() {
    const {id} = useParams()

  const {mutate} = useEdit('location/city', 'location')

  const [form] = Form.useForm();
  const {data, isLoading, error} = useFetchList('location')
  const [city, setCity] = useState(null)


const zoneOne = data && data.zones.find((item, i)=>{   
    return item.id === id 
    }) 


useEffect(()=>{
    const city = data && data.city.find((item, i)=>item._id === zoneOne.city )      

      zoneOne && form.setFieldsValue({  
      city:city && city.city,
      zone:zoneOne && zoneOne.zone,
      })
}, [zoneOne])


  const onFinish = (values: any) => {
    const zoneData = {
      city:values.city,
      element_id:id,
      info_type:"zone_info",
      info:"zone",
      value:values.zone,
      id:id
  }  
  mutate(zoneData)
  };

  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
    setCity(value)
  }

  return (
   
      <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
         <h1 className="mx-3 text-xl">Edit Zone</h1>
       <div className=" mx-5 grid grid-cols-2 mt-12">

    <Form
    {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >   

        <Form.Item
            label={<p className="text-left m-0 ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
            >

            <Select
               showSearch
                disabled={true}
                    placeholder="Select City"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option:any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   } 
                  >
              {
                  data && data.city.map((item, i)=>{                                             
                  return <option key={i} value={item.city} >{item.city}</option>

              })
              }
            </Select>
           
    </Form.Item>
         
      <Form.Item
            label={<p className="text-left m-0   ">Zone</p>}
            name="zone"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{width:'100%'}} />
          </Form.Item>
      

          <div className="mt-10">
            <Form.Item  className="text-right">
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
        </div>
   

    </Form>

    </div>
    </div>

  );

  }