import React, { useEffect } from 'react'
import styled from 'styled-components'
import loginbanner from '../../images/loginbanner.jpg'
import { Form, Input, Button, Checkbox } from 'antd';
import { useLogin } from '../../api/services/auth'


export default function Login() {

  const { mutate, isLoading } = useLogin()
  const onFinish = (values: any) => mutate({ admin: values })

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <LoginWrap className="w-screen h-screen bg-white" >
      <div className="loginsec " >
        <div className="grid grid-cols-8  " style={{ backgroundImage: `url('${loginbanner}')` }}>

          <div className="login backdrop-filter backdrop-blur-sm col-span-3 flex items-center justify-center">
            <div className=" " style={{ width: "80%" }}>
              <h2 className="text-2xl">Admin Login</h2>


              <Form
                name="loginform"
                style={{ width: "100%" }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item

                  name="email"
                  className="inputf"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input placeholder="Email ID" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>


                <Form.Item >
                  <Button type="primary" htmlType="submit">
                    {isLoading ? "Loading..." : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="col-span-5">

          </div>

        </div>
      </div>
    </LoginWrap>
  )
}

const LoginWrap = styled.div`
background-size:cover;
background-position:bottom;
.loginsec{
  width:50%;
  height: 500px;
position: absolute;
left:50%;
top:45%;
transform: translate(-50%, -50%);
border-radius: 8px ;
overflow: hidden;
border:1px solid #e5e5e5;
}
.inputf{
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border:none !important;
}
input {
    font-size:1.3rem;
  
    border-radius:0.3rem;
}
.ant-input-affix-wrapper{
  border:none !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.login{
    background-color:#b1b1b178;
  
    height:500px;
}
`