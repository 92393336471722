import { Button, Form, Input } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectAuth } from '../../../api/authSlice';
import { useAppSelector } from '../../../api/hooks';
import { useEdit, useFetchOne } from '../../../api/services/backend';

export default function AgenyProfile() {
    const [form] = Form.useForm();
    const { id } = useParams() as any;
    const { user } = useAppSelector(selectAuth)
    const { mutate } = useEdit('agency')

    useEffect(() => {
        form.setFieldsValue({
            name: user?.name,
            email: user?.email,
            bank_name: user?.bank_name,
            ifsc_code: user?.ifsc_code,
            acc_number: user?.acc_number
        })
    }, [user])


    const onFinish = (values: any) => mutate({
        ...values,
        id
    })
    return (
        <div className=" rounded-lg bg-white p-5 shadow-sm" style={{ height: '100%', border: '1px solid #e5e5e5' }}>
            <Form
                // {...layout}
                form={form}
                className="form"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            // onFinishFailed={}
            >
                <div >
                    <Button type="primary" style={{ borderRadius: '25px 25px 25px 25px', cursor: 'default' }} className="text-success text-capitalize mt-5 mb-7" > CONTACT DETAILS </Button>
                </div>

                <div className="mx-10 grid grid-cols-3 mb-4">
                    <div className="col-span-2">
                        <Form.Item
                            label={<p className='w-36 text-left m-0'>Name </p>}
                            name='name'
                            rules={[{ required: true, },
                                //   {min: 10},
                                //   {max:10},
                                //   {pattern:"[0-9]", message:"Only Numbers"} 
                            ]}
                        >

                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={<p className='w-36 text-left m-0'>Email</p>}
                            name='email'
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input E-mail!',
                                },
                            ]}
                        >

                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<p className='w-36 text-left m-0'>Bank Name</p>}
                            name="bank_name"
                            rules={[{ required: true, message: 'required!' }]}
                        >
                            <Input style={{ width: '100%', textTransform: "capitalize" }} />
                        </Form.Item>
                        <Form.Item
                            label={<p className='w-36 text-left m-0'>IFSC Code</p>}
                            name="ifsc_code"
                            rules={[{ required: true, message: 'required!' }]}
                        >
                            <Input style={{ width: '100%', textTransform: "uppercase" }} />
                        </Form.Item>
                        <Form.Item
                            label={<p className='w-36 text-left m-0'>Account Number</p>}
                            name="acc_number"
                            rules={[{ required: true, message: 'required!' }]}
                        >
                            <Input style={{ width: '100%', textTransform: "uppercase" }} />
                        </Form.Item>
                    </div>
                </div>
                <div >
                    <Button type="primary" style={{ borderRadius: '25px 25px 25px 25px', cursor: 'default' }} className="text-success text-capitalize mb-7 shadow" > CHANGE PASSWORD </Button>
                </div>
                <div className="mx-10 grid grid-cols-3 mb-5">
                    <div className="col-span-2">
                        <Form.Item
                            label={<p className=' w-40 text-left m-0'>Old Password </p>}
                            name="password"
                            rules={[
                                {
                                    min: 8,

                                    message: 'password must be at list 8 character!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password style={{ width: '100%' }} placeholder="Old Password" />
                        </Form.Item>

                        <Form.Item
                            label={<p className='w-40 text-left m-0'>New Password  </p>}
                            name='new_password'
                            className='  border-none'
                            rules={[
                                {
                                    min: 8,

                                    message: 'password must be at list 8 character!',
                                },
                            ]}
                            hasFeedback
                        >

                            <Input.Password placeholder="New Password" />
                        </Form.Item>

                        <Form.Item
                            label={<p className=' w-40 text-left m-0'>Confirm Password </p>}
                            name='confirm_password'

                            className='  border-none'
                            dependencies={['new_password']}
                            hasFeedback
                            rules={[
                                {
                                    min: 8,


                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject('The two Passwords that you Entered Do Not Match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Confirm Password" />
                        </Form.Item>

                    </div>

                </div>

                <Form.Item wrapperCol={{ span: 20, offset: 20 }}>
                    <Button type="primary" style={{ width: '110px' }} htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
