import React, {useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { Table, Tag, Input,Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import DeleteConfirm from '../../shared/deleteConfirm'
import styled from 'styled-components'
import key from '../../../key'
import { useDelete,useFetchList} from '../../../api/services/backend'
import {Link} from 'react-router-dom'
import { AiFillEye } from 'react-icons/ai';
import {  GoPrimitiveDot } from 'react-icons/go';
import moment from 'moment'
import {PlusOutlined,SearchOutlined} from '@ant-design/icons'
import { selectAuth,fethFilter} from '../../../api/authSlice'
import { useDebounce } from "use-debounce";
import {useParams} from 'react-router-dom'

export default function Datatable({data, isloading}) {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const [visibleOrder, setVisibleOrderModal] = useState(false);
  const [visibleEdit, setEditModal] = useState(false);

  const {mutate} = useDelete('advertisement')
  const [adid, setAdid] = useState(null);


  
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const {filter} = useSelector(selectAuth)
  console.log(filter);
  const {id} = useParams()
  const [debouncedText] = useDebounce(search, 2000);


    const confirm = (e, id) => {
      // console.log(id._id);
      mutate(id._id)
    }

    const cancel = (e) =>{
      return null
    }


  
      
      const closeModal = () => {
        setVisibleOrderModal(false)
      }

    console.log(data);
    
  //   useEffect(()=>{
  //     dispatch(fethFilter(`seasonalorder/${adid}`, debouncedText ))
  //   setLoading(false)
  //    }, [dispatch, debouncedText])
  
  
  
  //  useEffect(()=>{     
  //   if(filter.length < 1) {
  //     setSearch('')
  //   }
  //    }, [filter])
  
  
  // const onSearch = (e) => {
  //   setLoading(true)
  //   setSearch(e.target.value)
  
  // }
  

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },
    
    {
      title: 'Ad ID',
      key: "ad_no",
      dataIndex: 'ad_no',
      render:(text)=>{
        return <p className="m-0 font-medium">{text}</p>
    }
    },

    {
      title: 'Farmer Name',
      key: "farmer",
      dataIndex: 'farmer',
      render:(text)=>{
        return <p className="m-0 capitalize ">{text?.farmer_info && text?.farmer_info?.farmer_name}</p>
    }
    },

    {
      title: 'Crop',
      key: "crop",
      render:(text)=>{
         const crop =  text?.farmer?.crop_info.find((item)=> item?.id === text?.basic_info?.crop )
        return <p className="m-0  capitalize">{crop && crop?.crop_name}</p>
    }
    },

    {
      title: 'Submitted Date',
      dataIndex:'createdAt',
      key:'createdAt',
      render: text => <p className="m-0">{moment(text).format('DD/MM/YYYY')}</p>,
    },
    
    {
      title: 'Sale Date',
      key: "sale_date",
      dataIndex: 'basic_info',
      render:(text)=>{
        return <p className="m-0 ">{moment(text?.sale_date[0]).format('DD/MM/YYYY')}  -  {moment(text?.sale_date[1]).format('DD/MM/YYYY')}</p>
    }
    },

    {
      title: 'Target Customer',
      key: "target_customers",
      dataIndex: 'target_customers',
      render:(text)=>{
        return <p className="ml-6 mb-0 ">{text}</p>
        
    },
    // sorter: (a, b) => a.target_customers - b.target_customers,
    
    },

    {
      title: 'No. Of Orders',
      key: "no_of_orders",
      render:(text)=>{
        return <p className="ml-6">{text?.no_of_orders}</p>
    }
    },

    {
      title: 'Status',
      key: "basic_info",
      dataIndex: 'basic_info',
      render:(item) => {
    
        let isexpire =  moment().isBetween(item?.sale_date[0], item?.sale_date[1], undefined, '()'); 
        console.log(isexpire); 
        return <Space size="middle"> 
    
        
    {  isexpire ? <span style={{ color: 'green' }}><GoPrimitiveDot className="m-auto" style={{ color: 'green' }} /> </span> : <span style={{ color: 'red' }}><GoPrimitiveDot /></span> }
          </Space>
       } ,
      
    
    },

    // {
    //   title: 'No. of Orders',
    //   key: "sale_date",
    //   dataIndex: 'basic_info',
    //   render:(text)=>{
    //     return <p className="m-0 capitalize ">{text.farmer_info && text.farmer_info.farmer_name}</p>
    // }
    // },

    {
      title:'Order View',
      render: (id)=> (
         <Space size="middle">
        <Link to={`/dashboard/seasonalorder/${id._id}`}>         
        <h5  style={{color: 'var(--brandColor' }}  className="text-secondary  m-0 ">
        <AiFillEye/>
         </h5> 
   </Link>
  
       </Space>               
   
       )
  },


      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
  
              <Link to={`/dashboard/edit-advertisement/${id._id}`}>         
                     <h5  style={{color: 'var(--brandColor' }}  className="text-secondary  m-0 ">
                      <FaRegEdit />   
                      </h5> 
                </Link>

        <h5 className="text-danger mt-2">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
            <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },
     ]

     
     const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {


        setSelectionKey(selectedRowKeys)
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };


      return (
        <DataTableWrap>
     
    { 
     <Table 
      // rowSelection={{
      //   // type: selectionType,
      //   ...rowSelection,
      // }}  
    loading={isloading}
    columns={columns} 
    dataSource={data} 
    rowKey={(record)=>record._id}  

    />

    }       

     {/* <ModalForm 
        isVisible={visibleOrder} 
        title="ORDER DETAILS"
        footer={false}
        className=""
        width="80%"
        cancel={closeModal}
         > */}
{/* 

         <div className="flex  items-center justify-end">
            <SearchWrap className="mx-4 shadow" style={{borderRadius:"4px"}}>

            <Input value={search}  className="px-4 py-2 focus:outline-none"
            prefix ={  <SearchOutlined  style={{color:'#3e79f7', fontWeight:'bold'}} />
            }
            placeholder="Search" onChange={onSearch}  />
            </SearchWrap>
            <ExcelBtn data={currentorder} />
           </div> */}


   
        {/* </ModalForm> */}

        {/* <OrderTable  loading={loading} 
    data={order?.order}/> */}
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;


`

const SearchWrap = styled.div`
width:25%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}

`