import React, {useEffect, useState} from 'react'
import {useCreate, useFetchList} from '../../../../api/services/backend'
import { Form, Input, Button, InputNumber ,Checkbox, Select, Row, Col} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {MdClose} from 'react-icons/md'
const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };



  export default function CreateZone({cancel}) {

  const {mutate} = useCreate('location')

  const [form] = Form.useForm();
  const {data, isLoading, error} = useFetchList('location')
  const [city, setCity] = useState(null)


console.log(data);


  const onFinish = (values: any) => {
    const zoneData = {
      city:values.city,
      zone:values.zone
  }
  mutate(zoneData)
  form.resetFields()
  };

  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
    setCity(value)
  }

  return (
   
    <div className=" mx-5 grid grid-cols-2 mt-6">

    <Form
    {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >   

        <Form.Item
            label={<p className="text-left m-0 ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
            >

            <Select
                  showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    filterOption={(input, option:any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   } 
                    onChange={onChange}
                  >
              {
                  data && data.city.map((item, i)=>{                                             
                  return <option key={i} value={item.city} >{item.city}</option>

              })
              }
            </Select>
           
    </Form.Item>
         
      <Form.Item
            label={<p className="text-left m-0   ">Zone</p>}
            name="zone"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{width:'100%'}} />
          </Form.Item>
      

          <div className="mt-10">
            <Form.Item  className="text-right">
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
        </div>
   

    </Form>

    </div>
  );

  }