import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {useDispatch,useSelector} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import {Link} from 'react-router-dom'
import { motion } from "framer-motion";
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'
import moment from 'moment' 

export default function Datatable({data, loading}) {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const {mutate} = useDelete('broadcast')

  const handleClickEdit = (e, isvisible, id) =>{
    e.preventDefault()
   
    }
  
    const confirm = (e, id) => {
      console.log(id._id);
      mutate(id._id)
    }

    const cancel = (e) =>{
      return null
    }

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },
   
   
    {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => <p className="m-0">{ moment(createdAt).format("DD-MM-yyyy")}</p>,

      },
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: text => <p className="m-0">{ moment(text).format('LT')}</p>
      },
      {
        title: 'To',
        dataIndex: 'audiance',
        key: 'audiance',
        render: text => <p className="m-0">{text}</p>
      },

      // {
      //   title: 'Message',
      //   dataIndex: 'broadcast_info',
      //   key: 'broadcast_info',
      //   render: text => <p className="m-0">{text.message}</p>
      // },
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
             <Link to={`/dashboard/edit-broadcast/${id._id}`}>
             <h5  style={{color: 'var(--brandColor' }}  className="text-secondary  m-0 ">
           <FaRegEdit />  
            </h5>
            </Link>

        <h5 className="m-0">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },

     ]

     const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {


        setSelectionKey(selectedRowKeys)
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };


      return (
        <DataTableWrap>
    
    { 
        <Table 
      
      // rowSelection={{
      //   // type: selectionType,
      //   ...rowSelection,
      // }}   
  
      pagination={{
        onChange(current) {
          setPage(current)
        }
      }}

    loading={loading}
    columns={columns} 
    dataSource={data} 
    rowKey={(record)=>record._id}
    expandable={{
      expandedRowRender: record => {
       
        console.log(record);
        
       return <div className="px-5 py-2 text-sm " style={{width:'100%', color:'grey'}}>
       
       <div className="text-left" style={{lineHeight:"1.7", letterSpacing:"0.03rem"}}>
       <div className="grid grid-cols-5 gap-2">  

       <div> <p><b>Message : </b> {record && record?.message || "null"}</p></div>
       <div> <p><b>Advertisement Id : </b> {record && record?.ads_id?.ad_no || "null"}</p></div>

          </div>                
      </div>
     </div>
    }, 
    // rowExpandable: record => record.name  !== null,
  } }
    />

    }       
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;



`