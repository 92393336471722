/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-template-curly-in-string */
import  React,{ useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input,message, Button,Upload, Row,Image, Col, Checkbox } from 'antd';
import Loader from '../../../shared/loader';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import storage from '../../../shared/storage'
import {MdClose, MdDelete} from 'react-icons/md'
import styled from 'styled-components'

  export default function Createfarmer({ actionMethod, info }) {
    const dispatch = useDispatch()
    const [imgurl, setImgurl] = useState<any>([])
    const [loading1, setLoading1] = useState(false)
    const [fileList, setFileList] = useState([])
    const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log(values);
    var numbers = /^[0-9]+$/;
    if(!values.ph_no.match(numbers) )
    {
    return message.error("Phone number must be only numbers") 
    }
    if(values.ph_no.length !== 10 ){
     return message.error("Phone number must be 10 numbers")
    }
    if(!values.pincode.match(numbers) )
    {
      return message.error("Pincode must be only numbers") 
    }
    if(values.pincode.length !== 6 ){
      return message.error("pincode must be 6 digit")
     }
else{

    const data = {
      farmer_info : {
          farmer_name: values.farmer_name,
          email:values.email,
          about_farmer:values.about_farmer,
          image: imgurl[0],
          experience:values.experience,
          address:{
             address:values.address,
              village :values.village , district :values.district ,taluk:values.taluk ,state: values.state, pincode: values.pincode
          }
        },                
      ph_no:values.ph_no,
  }
    dispatch(actionMethod())
    dispatch(info(data))

  };
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  
  // const validateMessages = {
  //   required: 'required!',
  //   types:{
  //     regexp: 'is not a valid number!',
  //   }
  
  // };

  console.log(imgurl);
  
  
const handleChange = info => {
  setLoading1(true)
     
      storage
      .ref("images/" + info.file.name)
      .put(info.file.originFileObj)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      })
      .then(url => {
        console.log(url);
        setImgurl([...imgurl, url])
        setLoading1(false)
  
      })
      .catch(error => {
        console.log(error);
      });
  
    };
  

    const remove = (e, url) =>{

      setImgurl(prev => prev.filter(item => item !== url))
      
      }

      const uploadButton = (
          <div>
            { loading1 ? <LoadingOutlined  /> : <PlusOutlined />}
            <div style={{ marginTop: 8, fontSize:"14px" }}>{loading1 ? "uploading" :""}</div>
          </div>
        );


  return (
    <FormWrap>

         <div className="mt-5 mx-5">

<Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      // validateMessages={validateMessages}
    >

<div className="grid grid-cols-3">
<div className="col-span-2">


      <Form.Item
        label={<p className="text-left m-0">Farmer Name</p>}
        name="farmer_name"
        rules={[{ required: true, message:"required"}]}
      >
        <Input />
      </Form.Item>

      <Row gutter={20}>

<Col span={12}>

      <Form.Item
        label={<p className="text-left m-0">Phone No.</p>}
        name="ph_no"
        rules={[{ required: true, message:"required"} 
      // {min:10,max:10,message:"must be 10 numbers"},

      // {
      //   type: "regexp",
      //   pattern:new RegExp(`^\\+[0-9]*$`),
      //   // message: `numbers only`
      // }
  ]}
      >
        <Input />
      </Form.Item>

      </Col>
  
      <Col span={12}>
      <Form.Item
        label={<p className="text-left m-0">Email</p>}
        name="email"
        rules={[{ required: true, message:"required" },
         {type:"email", message: ' email is not valid!'}]}
      >
        <Input />
      </Form.Item>

</Col>
    </Row>

      <Form.Item
        label={<p className="text-left m-0">Address</p>}
        name="address"
        rules={[{ required: true,  message:"required" }]}
      >
               <Input.TextArea rows={2} />

      </Form.Item>


<Row gutter={20}>
  
<Col span={8}> 
      <Form.Item
        label={<p className="text-left m-0">Village</p>}
        name="village"
        rules={[{ required: true,  message:"required" }]}
      >
        <Input />
      </Form.Item>
</Col>

<Col span={8}> 

      <Form.Item
        label={<p className="text-left m-0 ">Taluk</p>}
        name="taluk"
        rules={[{ required: true, message:"required" }]}
      >
        <Input />
      </Form.Item>

      </Col>

<Col span={8}> 
      <Form.Item
        label={<p className="text-left m-0 ">Pincode</p>}
        name="pincode"
        rules={[{ required: true,  message:"required" }]}
      >
        <Input />
      </Form.Item>   
      </Col>

<Col span={8}> 

      <Form.Item
        label={<p className="text-left m-0">District</p>}
        name="district"
        rules={[{ required: true,  message:"required" }]}
      >
        <Input />
      </Form.Item>

      </Col>

<Col span={8}> 


      <Form.Item
        label={<p className="text-left m-0 ml ">State</p>}
        name="state"
        rules={[{ required: true, message:"required" }]}
      >
        <Input />
      </Form.Item>
      </Col>

      <Col span={8}> 


        <Form.Item
          label={<p className="text-left m-0 ml ">Experience</p>}
          name="experience"
          // rules={[{ required: true, message:"required" }]}
        >
          <Input />
        </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
  
  <Col span={12}> 
      <Form.Item
            label={<p className="text-left m-0 ml-2 ">Image</p>}
            name="image"
            rules={[{ required: true, message: 'required' }]}
          >
                <div 
                    className=" grid grid-cols-4 gap-5">
                {

                imgurl.map((img, i)=>{

                  return <div className=" imglist  bg-gray-50 text-center" style={{height:"100px"}}>
                   
                  <Image    
                   preview={false}               
                   key={i}
                   className="  rounded col-span-1  block  object-cover"
                   style={{height:"100%", width:"100%"}}
                   src={img}
                   placeholder={<Loader/> }              
                 />   
                  <h2 onClick={(e)=>remove(e, img)} className="p-1 text-white  text-xl "> <MdDelete/></h2> 

                    </div>
                })
                }

                <Upload       
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleChange}
                        multiple={true}
                        className="ml-2"
                        >
                        {imgurl.length >= 1 ? null : uploadButton}
                        
                        </Upload>
                </div> 


              </Form.Item>
      </Col>
      </Row>
      {/* <Col span={14}> */}
      
      <Form.Item
        label={<p className="text-left m-0">About Farmer</p>}
        name="about_farmer"
        rules={[{ required: true,  message:"required" }]}
      >
               <Input.TextArea rows={2} />

      </Form.Item>

      {/* </Col> */}
    


      <div className="mt-10">
        <Form.Item className="text-right">
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </div>

    
      </div>

</div>



    </Form>
    </div>  
    </FormWrap>

  );

  }

  const FormWrap = styled.div`
position: relative;

.imglist {
overflow: hidden;

h2 { opacity : 0;
display:none;
position: absolute;
margin: 0;
bottom: 0%;
background-color: rgba(0,0,0,0.5);
cursor: pointer;
transition: 0.1s;

&:hover {

font-size: 1.5rem;
}

}

&:hover h2 {

opacity : 1;
display:block
}
}

`