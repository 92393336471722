import React, {useEffect, useState, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Form, Input,message, Button } from 'antd';
import Loader from '../../shared/loader';
import {useParams} from 'react-router-dom'
import TextArea from 'antd/lib/input/TextArea';
import {useLogin} from '../../../api/services/auth'
import {selectAuth} from '../../../api/authSlice'
import { useAppSelector } from '../../../api/hooks';
import {useFetchList, useEdit, useFetchOne} from '../../../api/services/backend'
// import {updateAdminProfile,fetchOneAdmin, adminSelector} from '../../../api/admin'
import styled from 'styled-components'

export default function Account() {
  const dispatch = useDispatch()
  const {mutate} = useEdit('editprofile')
  const [form] = Form.useForm();
  const {id,loading} = useParams()
  const { user } = useAppSelector(selectAuth)
 console.log({admin:user});
 const {data:res} = useFetchOne('user', id)

  // useEffect(()=>{
  
  //     dispatch(fetchOneAdmin(id))
      
      
  //     }, [dispatch])
  
 
  
      useEffect(()=>{
  
          form.setFieldsValue({
            email:user && user.email,
            phone_no:user && user.phone_no,
            address:user && user.address,
            
            });
  
  
      }, [user])

      const layout = {
        labelCol: {
          span: 12,
        },
        wrapperCol: {
          span: 12,
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 4,
          span: 16,
        },
      };
  


      const onFinishAccount = (values) => {

        var numbers = /^[0-9]+$/;
        if(!values.phone_no.match(numbers))
        {
          return message.error("Phone number must be only numbers")
   
        }
        if(values.phone_no.length !== 10){
            message.error("Phone number must be 10 numbers")
        }
else{

        const imgdata = {

          
          address:values.address,
          phone_no:values.phone_no,
          email:values.email,
          password:values.password,
          new_password:values.new_password,
          confirm_password:values.confirm_password, 
          id:res?.admin?._id
        }
      
      
        mutate(imgdata)
}
        };
        const onFinishFailed = (errorInfo) => {
          console.log('Failed:', errorInfo);
        };


    return (
      <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
        <ProfileWrap>
                 {
                     loading ? <Loader/> : 
                     <div  style={{overflowY:'auto', height:'83vh', overflowX:'hidden' }}>
                                
                 <Form
                // {...layout}
                form={form}
                  className="form"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinishAccount}
                onFinishFailed={onFinishFailed}
              >                         
              <div >
                        <Button type="primary" style={{borderRadius:'25px 25px 25px 25px', cursor:'default'}} className="text-success text-capitalize mt-5 mb-7" > CONTACT DETAILS </Button>
              </div>
                   
                <div className="mx-10 grid grid-cols-3 mb-4">
                    <div className="col-span-2">
                            <Form.Item
                                label={<p className='w-36 text-left m-0'>Mobile No. </p>}
                                name='phone_no'
                                rules={[{ required: true,  },
                                //   {min: 10},
                                //   {max:10},
                                //   {pattern:"[0-9]", message:"Only Numbers"} 
                                  ]}
                              >

                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={<p className='w-36 text-left m-0'>Email ID  </p>}
                                name='email'
        
                                rules={[
                                  {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                  },
                                  {
                                    required: true,
                                    message: 'Please input E-mail!',
                                  },
                                ]}
                              >

                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={<p className='w-36 text-left m-0'>Address</p>}
                                name="address"
                                rules={[{ required: true, message: 'Please input address!' }]}
                            >

                                <TextArea rows={3}/>
                            </Form.Item>

                    </div>
                    
                </div>
                <div >
                        <Button type="primary" style={{borderRadius:'25px 25px 25px 25px', cursor:'default'}} className="text-success text-capitalize mb-7 shadow" > CHANGE PASSWORD </Button>
              </div>
              <div className="mx-10 grid grid-cols-3 mb-5">
                    <div className="col-span-2">
                            <Form.Item
                                label={<p className=' w-40 text-left m-0'>Old Password </p>}
                                name="password"  
                                rules={[
                                {
                                    min: 8,
                                    
                                    message: 'password must be at list 8 character!',
                                },
                                ]}
                                hasFeedback
                            >
                                <Input.Password style={{ width: '100%'}} placeholder="Old Password" />
                            </Form.Item>

                            <Form.Item
                                label={<p className='w-40 text-left m-0'>New Password  </p>}
                                name='new_password'
                                className='  border-none'
                                rules={[
                                {
                                    min: 8,
                                   
                                    message: 'password must be at list 8 character!',
                                },
                                ]}
                                hasFeedback
                            >

                            <Input.Password placeholder="New Password" />
                           </Form.Item>

                            <Form.Item
                                label={<p className=' w-40 text-left m-0'>Confirm Password </p>}
                                name='confirm_password'
       
                                className='  border-none'
                                 dependencies={['new_password']}
                                 hasFeedback
                                 rules={[
                                   { 
                                     min: 8,
                             
                     
                                   },
                                   ({ getFieldValue }) => ({
                                     validator(rule, value) {
                                       if (!value || getFieldValue('new_password') === value) {
                                         return Promise.resolve();
                                       }
                         
                                       return Promise.reject('The two Passwords that you Entered Do Not Match!');
                                     },
                                   }),
                                 ]}
                               >
                                 <Input.Password  placeholder="Confirm Password" />
                               </Form.Item>

                    </div>
           
                </div>

                <Form.Item wrapperCol={{ span: 20, offset: 20}}>
                <Button type="primary" style={{width:'110px'}} htmlType="submit">
                    Save
                </Button>
                </Form.Item>
            </Form>
            </div>
                }
             
        </ProfileWrap>
        </div>
    )
}


const ProfileWrap = styled.div`


`