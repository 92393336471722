import { Button } from 'antd';
import React from 'react'
import ReactExport from "react-export-excel";
import { DownloadOutlined  } from '@ant-design/icons';
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function ExportExcel({data}) {
    console.log(data);
    
         
    return (
        
        <ExcelFile filename="product" element={<Button icon={<DownloadOutlined  style={{transform:"translateY(2px)" }}/>} className="mr-1" type="primary">Download</Button>}>
               
        <ExcelSheet data={data?.product} name="product" >
          
            
        <ExcelColumn label="Category type" value={col => col.category_id.category_info ? col.category_id.category_info.category_name: "null"}/> 
            <ExcelColumn label="Product Name" value={col => col.product_info ? col.product_info.product_name: "null"}/> 
            <ExcelColumn label="Product ID" value={col => col ? col.product_id: "null"}/> 
            <ExcelColumn label="Cost" value={col => col.product_info ? col.product_info.cost: "null"}/> 
            <ExcelColumn label="Units" value={col => col.product_info ? col.product_info.units: "null"}/>       
            <ExcelColumn label="Qty" value={col => col.product_info ? col.product_info.qty: "null"}/>  
            <ExcelColumn label="Qty" value={col => col.product_info ? col.product_info.hsn_code: "null"}/>  
            <ExcelColumn label="Qty" value={col => col.product_info ? col.product_info.gst: "null"}/>     
   
   
            <ExcelColumn label="Selling Price" value={col => col.product_info ? col.product_info.selling_price: "null"}/>       
            <ExcelColumn label="Expiry date" value={col => col.product_info ? moment(col.product_info.expiry_date).format('DD/MM/YYYY') : "null"}/>  
            <ExcelColumn label="Procurement Date" value={col =>col.product_info ? moment(col.product_info.date_of_procurement).format('DD/MM/YYYY'): "null"}/> 
        </ExcelSheet>
   
    </ExcelFile>
    )
}


