import { Button, Checkbox, Collapse, Form, Input, message, Select } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useCreate, useFetchList, useFetchOne } from '../../../api/services/backend';
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
}

export default function Offer2() {
    const [form] = Form.useForm();
    const [selectedProduct, setSelectedProduct] = useState() as any;
    const [hasOffer, setHasOffer] = useState(false);


    const { data, isLoading, refetch } = useFetchList(`product`);
    const { mutate, isLoading: updateLoading } = useCreate(`offer2/${selectedProduct?._id}`, {
        enabled: !!selectedProduct?._id,
        onSuccess: ({ data }: any) => {
            refetch();
            if (!hasOffer) {
                setSelectedProduct();
                form.setFieldsValue({
                    product: null
                })
            }
            message.success(data?.msg)
        },
    });

    const onFinish = ({ product, hasOffer: t, ...rest }: any) => {
        let tmp = hasOffer
            ? { ...rest, hasOffer }
            : { hasOffer, offerPrice: 0 }
        mutate(tmp)
    }
    const onFinishFailed = () => { }
    const onProductSelect = (id: any) => setSelectedProduct(data?.product.find((item: any) => item._id === id));

    useEffect(() => {
        form.setFieldsValue({
            cost: selectedProduct?.product_info?.cost,
            offerPrice: selectedProduct?.offerPrice,
            cartValue: selectedProduct?.cartValue,
        })
        setHasOffer(selectedProduct?.hasOffer);
    }, [selectedProduct])

    return (
        <div className="grid grid-cols-2 gap-10 mt-6 mx-4" style={{ minHeight: "350px" }}>
            <Form
                {...layout}
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div className="grid grid-cols-2 gap-10 mt-6 mx-4 ">

                    <div className="col-span-2 grid grid-cols-2">
                        <Form.Item
                            label={<p className="text-left m-0">Products</p>}
                            name="product"
                            rules={[{ required: true, message: 'required!' }]}
                        >
                            <Select
                                loading={isLoading}
                                showSearch
                                className='capitalize'
                                value={selectedProduct}
                                placeholder="Products"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={onProductSelect}>

                                {
                                    data?.product.map((item, i) => <Select.Option style={{ textTransform: "capitalize" }} key={i} value={item._id} >{item.product_info.product_name}</Select.Option>)
                                }

                            </Select>
                        </Form.Item>
                    </div>

                    {
                        selectedProduct && <>
                            <div className="col-span-2">
                                <Form.Item name="hasOffer">
                                    <Checkbox checked={hasOffer} onChange={(e: any) => setHasOffer(e.target.checked)}>Has Offer</Checkbox>
                                </Form.Item>
                            </div>
                            <Form.Item
                                label={<p className="text-left m-0 ">Current Price</p>}
                                name="cost"
                                rules={[{ required: true, message: 'required' }]}
                            >
                                <Input disabled type="number" style={{ width: '100%' }} />
                            </Form.Item>
                            {
                                hasOffer && <>
                                    <Form.Item
                                        label={<p className="text-left m-0 ">New Offer</p>}
                                        name="offerPrice"
                                        rules={[{ required: true, message: 'required' }]}
                                    >
                                        <Input type="number" style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item
                                        label={<p className="text-left m-0 ">Cart Price</p>}
                                        name="cartValue"
                                        rules={[{ required: true, message: 'required' }]}
                                    >
                                        <Input type="number" style={{ width: '100%' }} />
                                    </Form.Item>
                                </>
                            }
                        </>
                    }
                </div>

                {
                    selectedProduct && <>
                        <br />
                        <Button disabled={updateLoading} loading={updateLoading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </>
                }

                {/* {
                hasOffer && <>
                    <br />
                    <Button disabled={updateLoading} loading={updateLoading} type="primary" htmlType="submit">
                        Submit
                    </Button>
                </>
            } */}
            </Form>
            <div className="py-1 overflow-y-auto">
                <Collapse>
                    {
                        data?.product?.filter(item => item.hasOffer)?.map(item => <Collapse.Panel header={<p className='capitalize'>{item?.product_info.product_name}</p>} key={item._id}>
                            <div className="grid grid-cols-3">
                                <div>
                                    <div>
                                        <span>Price: </span> <span>{item.product_info.selling_price}</span><br />
                                        <span>GST: </span> <span>{item.product_info.gst}</span><br />
                                        {
                                            item.hasOffer && <>
                                                <span>Offer Price: </span> <span>{item.offerPrice}</span>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Collapse.Panel>)
                    }
                </Collapse>
            </div>
        </div>
    )
}
