import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useQuery } from 'react-query'
import key from '../../../key'
import { Table } from 'antd'
import { useFetchList } from '../../../api/services/backend'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Select } from 'antd'
import { Link } from 'react-router-dom'
import ModalForm from '../../shared/modal'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import DataTable from './datatable'
import ExcelBtn from './exportexcel'
import { useDebounce } from "use-debounce";
import axios from 'axios'
import { selectAuth, fethFilter } from '../../../api/authSlice'
import styled from 'styled-components'
import moment from 'moment'
const { Option } = Select;
interface Props {

  title?: string
}

const Apartment: React.FC<Props> = ({ title }) => {



  const [date, setDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  const [data, setData] = useState<any>([])

  const { data: res, isLoading } = useFetchList('apartment')


  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { filter } = useSelector(selectAuth)
  const [debouncedText] = useDebounce(search, 2000);




  useEffect(() => {
    dispatch(fethFilter('apartment', debouncedText))
    setLoading(false)
  }, [dispatch, debouncedText])


  useEffect(() => {

    setData(res)

  }, [res])



  function onChange(value) {

    if (value !== 'all') {
      const date = moment().subtract(value, 'days').format('YYYY-MM-DD')
      axios.get(key.BACK_ENDURL + `/apartment?date=${date}`).then(({ data }) => {
        setData(data)
      })
    }
    else {

      axios.get(key.BACK_ENDURL + `/apartment?date=${'null'}`,).then(({ data }) => {
        setData(data)
      })
    }
  }


  useEffect(() => {
    if (filter.length < 1) {
      setSearch('')
    }
  }, [filter])


  const onSearch = (e) => {
    setLoading(true)
    setSearch(e.target.value)

  }

  return (

    <div className="p-5 rounded-lg bg-white" style={{ border: '1px solid #e5e5e5' }}>
      <div className="flex mb-5 items-center justify-between">
        <div>
          <Link to="/dashboard/create-apartment">
            <Button onClick={() => setVisible(true)} type="primary" icon={<PlusOutlined />}>
              Add Apartment</Button>
          </Link>
        </div>

        <div className="flex  items-center justify-end">


          <Select
            style={{ width: "200px" }}
            placeholder="Days"
            optionFilterProp="children"
            onChange={onChange}
          >
            <Option value="7">Past 7 Days</Option>
            <Option value="30">Past 30 Days</Option>
            <Option value="90">Past 90 Days</Option>
            <Option value="all">All</Option>
          </Select>


          <SearchWrap className="mx-4 " style={{ borderRadius: "4px" }}>

            <Input value={search} className="px-4 py-2 focus:outline-none"
              prefix={<SearchOutlined style={{ color: '#3e79f7', fontWeight: 'bold', }} />
              }
              placeholder="Search" onChange={onSearch} />
          </SearchWrap>



          <ExcelBtn data={data} />

        </div>


      </div>

      <DataTable loading={isLoading}
        data={(debouncedText && filter.length > 0) ? filter : data}
      />

      {/* <ModalForm 
          isVisible={visible} 
          title={<h2 className=" font-semibold m-0" style={{color:"grey"}}>CREATE REGION</h2>}
          footer={false}
          className=""
          width="45%"
          cancel={()=>setVisible(!visible)}>
            
            <CreateRegion cancel={()=>setVisible(!visible)} />
            
             </ModalForm>  */}

    </div>



  )
}

export default Apartment



const SearchWrap = styled.div`
width:100%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}

`