import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button , Checkbox, DatePicker, InputNumber  } from 'antd';
import styled from 'styled-components'
import {useFetchList, useCreate, useFetchOne} from '../../../../api/services/backend'
import { advertisementSelector } from '../../../../api/services/advertisements'

const { RangePicker } = DatePicker;


    export default function Shop({ actionMethod }) {

        const [form] = Form.useForm();
        const dispatch = useDispatch()
        const {mutate} = useCreate('advertisement')
        const [price, setPrice] = useState(0);
        const [discount, setDiscount] = useState(0);

        const { basic_data, media_data, checklist_data } = useSelector(advertisementSelector)

        // const {data:farmer} = useFetchOne('farmer', basic_data && basic_data.farmer)

        useEffect(()=>{          
            basic_data && form.setFieldsValue({
            asking_price: basic_data.basic_info && basic_data.basic_info.asking_price,
            })
        }, [basic_data])
  


        const onFinish = (values:any) => {
            const data = {
                basic_info: basic_data?.basic_info,
                media_info: media_data,
                checklist_info: checklist_data?.checklist,
                shop_info : {
                    price_per_unit: values.price_per_unit ,
                    selling_price: values.selling_price,  
                    discount: values.discount ,
                    discount_coupon: values.discount_coupon,                      
                    delivey_date: values.delivey_date,
                } ,
                farmer: basic_data?.farmer,
                category: basic_data?.category,
                rm: checklist_data?.rm,                                       

            }
            mutate(data)
            dispatch(actionMethod())
        }

        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
          };

     
          const onChangeDate = ((date, dateString) => {
            console.log(date, dateString);
          })

          const onChangePrice =(e)=>{
            setPrice(e)
            const sp  =  Math.floor( (e *((100- discount )/(100))))
                form.setFieldsValue({
                selling_price: sp,
                })
          }

          const onChangeDiscount =(e)=>{
            setDiscount(e)
            const sp  =  Math.floor( (price *((100- e )/(100))))
             form.setFieldsValue({
                selling_price: sp,
                })
          }

    
    return (
      <FormWrap> 
        <div className="mt-5 mx-5">
            <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
             >
            <div className="grid grid-cols-2 gap-x-20">
            <div className="col-span-1">
      
            <Form.Item
                    label={<p className="text-left m-0">Farmer Price per unit</p>}
                    name="asking_price"
                    // rules={[{ required: true, message: 'required!' }]}
                >
                    <InputNumber disabled={true} />
            </Form.Item>

            <Form.Item
                label={<p className="text-left m-0 ">Price per unit</p>}
                name="price_per_unit"
                rules={[{ required: true, message: 'required!' }]}
            >
                <InputNumber  style={{width:'100%'}} onChange={onChangePrice}/>
            </Form.Item>   
 
            <Form.Item
                label={<p className="text-left m-0 ">Discount %</p>}
                name="discount"
                rules={[{ required: true, message: 'required!' }]}
            >
                <InputNumber style={{width:'100%'}} onChange={onChangeDiscount}/>
            </Form.Item>  

            <Form.Item
                label={<p className="text-left m-0 ">Selling Price</p>}
                name="selling_price"
                rules={[{ required: true, message: 'required!' }]}
            >
                <InputNumber disabled={true} style={{width:'100%'}}/>
            </Form.Item>   

             
            <Form.Item
                label={<p className="text-left m-0 ">Create discount coupon</p>}
                name="discount_coupon"
                rules={[{ required: true, message: 'required!' }]}
            >
                <Input  style={{width:'100%'}}/>
            </Form.Item>            

            <Form.Item
                label={<p className="text-left m-0 ">Delivery Date</p>}
                name="delivey_date"
                rules={[{ required: true, message: 'required!' }]}
            >
                    <DatePicker  onChange={onChangeDate} />
            </Form.Item>   
           
            <div className="mt-10">
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                    </Form.Item>
                </div>
    </div>


                
            </div>
          </Form>
     </div>
     </FormWrap>
    )
}

const FormWrap = styled.div`



`