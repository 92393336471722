import  React,{ useEffect , useState} from 'react'
import { Form, Input, Button, Select } from 'antd';
import {useFetchList, useEdit } from '../../../../api/services/backend'
import { useDispatch, useSelector } from 'react-redux'
import { farmerSelector } from '../../../../api/services/farmer'

  export default function Createfarmer({ farmer}) {

    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const {mutate} = useEdit('farmer')

  const {data} = useFetchList('user')

  
  
  useEffect(()=>{   
   farmer && form.setFieldsValue({
    rm: farmer.rm && farmer.rm._id,
    ph_no: farmer.rm && farmer.rm.ph_no,
    email: farmer.rm && farmer.rm.email,
  });
}, [farmer, data])



  const onFinish = (values: any) => {

    const data ={
        rm:values.rm,
        id:farmer._id
    }
    mutate(data)
  };


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  const handleManagerSelect = (value) => {
      
    const user = data && data.rows.filter((item)=>{
        return item._id === value
    })
    user && form.setFieldsValue({
        ph_no: user[0].ph_no,
        email: user[0].email,
    })
}


  return (

    <div className="mt-5 mx-5">
    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<div className="grid grid-cols-2">

          <div>
              <Form.Item
                label={<p className=" text-left m-0">Name</p>}
                  name="rm"
                  rules={[{ required: true, message: 'required!' }]}
                >
                      <Select 
                        showSearch
                        placeholder="Name" 
                        filterOption={(input, option:any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                       } 
                        onChange={handleManagerSelect}>

              {
                  data && data.admin.map((item, i)=>{      
                    return <option key={i} value={item._id} >{item.admin_name}</option>

              })
              }</Select>
              </Form.Item>

              <Form.Item
                label={<p className="text-left m-0 ml-2">Phone No.</p>}
                name="ph_no"
              >
                <Input  disabled={true}/>
              </Form.Item>

              <Form.Item
                label={<p className="text-left m-0 ml-2">Email</p>}
                name="email"
              >
                <Input disabled={true}/>
              </Form.Item>


        <div className="mt-14">
            <Form.Item className="text-right">
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
            </div>
          </div>
      </div>
     
    </Form>

    </div>  
  );

  }