import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;




export default function ProductSaleExcel({data}) {
  return (
    <ExcelFile filename="Products" element={<Button icon={<DownloadOutlined  style={{transform:"translateY(2px)" }}/>} className="mx-2" type="primary">Export Products Sale</Button>} >
    <ExcelSheet data={data} name="Product Details">
        <ExcelColumn label="Product Name" value="product_name" widthPx={350}/>
        <ExcelColumn label="id" value="product_id"/>
        <ExcelColumn label="Total Sale" value="totalQuantity"/>
        <ExcelColumn label="Number of times ordered" value="totalSales"/>

    </ExcelSheet>
</ExcelFile>
  )
}
