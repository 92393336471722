import  React,{ useEffect,useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useFetchList} from '../../../api/services/backend'
import {Button,Upload,Input, DatePicker,message} from 'antd'
import {UploadOutlined,SearchOutlined} from '@ant-design/icons'
import DataTable from './datatable'
import styled from 'styled-components'
import ExcelBtn from './exportexcel'
import ImgUri from '../../../key'
import { selectAuth,fethFilter} from '../../../api/authSlice'
import { useDebounce } from "use-debounce";
import axios from 'axios'
import key from '../../../key'
import { loadPlugin } from 'immer/dist/internal'


const { RangePicker } = DatePicker;


interface Props {

    title? :string
}

 const Order: React.FC<Props> = ({title}) => {

   const {data, isLoading, error} = useFetchList('sample-order')
   
   const [visible, setVisible] = useState(false)

   const [reload, setReload] = useState(false)


    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    const [exceldata, setExcelData] = useState<any>(null)

    const dispatch = useDispatch()
    const {filter} = useSelector(selectAuth)
    const [debouncedText] = useDebounce(search, 2000);



  useEffect(()=>{
    dispatch(fethFilter('sample-order', debouncedText ))
  setLoading(false)
    }, [dispatch, debouncedText])



  useEffect(()=>{     
    if(filter.length < 1) {
      setSearch('')
    }
    }, [filter])

    

  useEffect(()=>{     
      if(reload){
        message.success('Successfully uploaded')
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }
  }, [reload])
    

    
  const onChangeUpload =  () =>{
      setReload(true)
  }


  const onSearch = (e) => {
    setLoading(true)
    setSearch(e.target.value)
  }



const onChangeDate = (date, dateString) =>{
  
  console.log(date, dateString);
  
  axios.post(key.BACK_ENDURL + `/sampleorder-date`, date).then(({data})=>{

    const orderarray = [] as any

    console.log(data);
    
    data?.order?.map((item:any, i:any) => {
      
      orderarray.push(item)

      const name = item?.sampleproducts.map(pr=>{
        
        

        return {product: pr.name}
      })

      orderarray[i].prd = JSON.stringify(name)  
      // console.log(JSON.stringify(name.toString()))

})
    setExcelData(orderarray)
     })
}


console.log(exceldata);



const orderarray = [] as any

    data?.order.map((item:any, i:any) => {
      
      orderarray.push(item)

      const name = item?.sampleproducts.map(pr=>{
 
        return {product: pr.name, }

      })

      orderarray[i].prd = JSON.stringify(name)  
      console.log(JSON.stringify(name.toString()))

})




    return (
      
      <div className="p-5 rounded-lg bg-white" style={{border:'1px solid #e5e5e5'}}> 
          <div className="flex mb-5 items-center justify-between">    
          
          <div> 
            <SearchWrap className="ml-4 flex  items-center justify-start" >

            <Input value={search}  className="px-4 py-2 focus:outline-none" prefix ={  <SearchOutlined  style={{color:'#3e79f7', fontWeight:'bold'}} width="20px" /> }  placeholder="Search" onChange={onSearch}  />
            </SearchWrap>
            
            

            </div>
         
           <div className="flex  items-center justify-end ">

            <RangePicker onChange={onChangeDate} />

            <ExcelBtn  data={(exceldata && exceldata?.length > 0  ) ? exceldata : orderarray} />

            <Upload
            action={ImgUri.BACK_ENDURL + `/sampleorderByExcel`}
            // listType="file"
            onChange={onChangeUpload}
            name="file"
            className="mr-3"
          >
            <Button icon={<UploadOutlined /> } type="primary">Upload</Button>
           </Upload> 

           </div>
        </div>
       
        <DataTable   loading={loading || isLoading} data={(filter.length> 0) ? filter :data?.order} />
      
     </div>

    )
}

export default Order


const SearchWrap = styled.div`
width:100%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}

`