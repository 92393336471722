import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {useDispatch,useSelector} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'
import {Link} from 'react-router-dom'
import moment from 'moment'
export default function Datatable({data, loading}) {

  const [page, setPage] = useState(1);
  const {mutate} = useDelete('campaign')
  const [visibleEdit, setEditModal] = useState(false);
 
console.log(data);

    const confirm = (e, id) => {
      console.log(id._id);
      mutate(id._id)
    }

    const cancel = (e) =>{
      return null
    }

    const handleEditClick = (e, isvisible, id) =>{
      e.preventDefault()
     
      setEditModal(isvisible)
      }

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },


    {
      title: 'Apartment Name',
      dataIndex:'apartment_info',
      key:'apartment_info',
      render: text => <p className="m-0 capitalize  font-medium" style={{color:"#1A3353"}}>{text.name}</p>,
    },

    {
      title: 'Phone No.',
      dataIndex: 'apartment_info',
      key: 'apartment_info',
      render: text => <p className="m-0">{text.phone_no}</p>
    },
      {
        title: 'Status',
        dataIndex: 'apartment_info',
        key: 'apartment_info',
        render: text => <p className="m-0 capitalize">{text.status === 'progress' ? 'In Progress' : text.status}</p>
      },

      {
        title: 'Added Date',
        dataIndex: 'apartment_info',
        key: 'apartment_info',
        render: text => <p className="m-0">{moment(text).format('DD/MM/YYYY')}</p>

      },

      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
            <Link to={`/dashboard/edit-launchapartment/${id._id}`}>
             <h5 style={{color: 'var(--brandColor' }} className="text-secondary  m-0 " >
              <FaRegEdit />                           
              </h5>
              </Link>

        <h5 className="m-0">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },

     ]

 

      return (
        <DataTableWrap>
      

    { 
     <Table 
     
    loading={loading}
    columns={columns} 
    dataSource={data} 
    rowKey={(record)=>record._id}
    pagination={{
      onChange(current) {
        setPage(current)
      }
    }}
    expandable={{
     
      
        expandedRowRender: record => {
         
         return <div className="px-5 py-2 text-sm " style={{width:'100%', color:'grey'}}>
         
         <div className="text-left" style={{ letterSpacing:"0.03rem"}}>
         <div className="grid grid-cols-6 gap-2">  

      <div>
      <p><b>Address : </b> {record.apartment_info && record.apartment_info.address || "null"}</p>
      <p><b>Locality : </b> {record.apartment_info && record.apartment_info.address || "null"}</p>
  
      </div>
       
               
           </div>                
        </div>
       </div>
      }, 
    //   rowExpandable: record => record.name  !== null,
    } }
    />

    }       


          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;



`