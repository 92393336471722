import React, {useEffect, useState} from 'react'
import { StickyContainer, Sticky } from 'react-sticky';
import Loader from '../../shared/loader';
import { Form,Tabs, } from 'antd';
import Account from './myProfile'
import MyProfile from './myProfile'



const { TabPane } = Tabs;

export default function Profile() {
 
    const renderTabBar = (props, DefaultTabBar) => (
        <Sticky bottomOffset={80}>
          {({ style }) => (
            <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
          )}
        </Sticky>
      );




    return (
        <div >        
        {/* <StickyContainer>
        <Tabs defaultActiveKey="1" type='line' renderTabBar={renderTabBar}  >
        <TabPane tab="My Profile" key="1" > */}
            <MyProfile/>
           
           
        {/* </TabPane>
      

        </Tabs>
    </StickyContainer> */}
        </div>
    )
}
