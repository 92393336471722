/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useCreate, useFetchList } from "../../../api/services/backend";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Image,
  Row,
  Col,
  Radio,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import storage from "../../shared/storage";
import Loader from "../../shared/loader";

const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function Createbroadcast() {
  const { mutate } = useCreate("broadcast");
  const { mutate: whatsapp } = useCreate("broadcast/whatsapp");

  const { data: advertisement } = useFetchList("advertisement");
  const [imgurl, setImgurl] = useState<any>([]);
  const [consumerInfo, setConsumerInfo] = useState(null);
  const [loading1, setLoading1] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [notify, setNotify] = useState("");
  const [filter, setFilter] = useState("zones");
  const [location, setLocation] = useState([]) as any;

  const [form] = Form.useForm();

  //   const {data, isLoading, error} = useFetchList('farmer')

  //   console.log(data);

  let url = `https://graph.facebook.com/v15.0/111296721769242/message_templates?limit=35&access_token=EAAkXBrE5DGwBAKBCIf8tZC1G9U4JRAfwTQmHJc7EcWVzMrZCdZACWevOsPwmmM92zJScJiq3WY1JHsuwuPLxIWigq4gFjP9FlpbsHDwJmCEIEj3uCQ3ynKiwQtYfVypmJQ1MLDCthSJRFQHXycnBt7q6a1IS8InrAbMlWFIFLAGHszsM1hb`;

  const { data } = useFetchList("location", () => null);
  const { data:appartments } = useFetchList("apartment?broadcast=whatsapp", () => null);


  useEffect(() => {

    


    data && setLocation(data.zones);

  }, [data]);


 

  useEffect(() => {
    axios
      .get(url)
      .then(({ data }: any) => {
        let templates_name = data?.data?.map((item: any) => {
          return item?.name;
        });
        setTemplates(templates_name);
      })
      .catch((errr: any) => {
        console.log(errr);
      });
  }, []);

  const onFinish = (values: any) => {
    // delete values.notify;
    const data = {
      ads_id: values.advertisement,
      audiance: values.audiance,
      message: values.message,
      notify: values.notify,
      template: values.template,
      imgurl: imgurl,
      search_name: values?.search_name,
      filter_name:
        filter === "zones"
          ? "zone_id"
          : filter === "pins"
          ? "pincode_id"
          : filter === "locality"
          ? "locality_id"
          : filter,
    };
    if (values.notify == "whatsapp") {
      // form.resetFields()

      console.log({ values: data });

      return whatsapp(data);
    }
    mutate(data);
    // form.resetFields()
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleChangeSelect = (value) => {
    console.log(value);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
    setConsumerInfo(value);
  }

  const handleChangeAd = (value) => {
    // console.log(value);
  };

  const onChangeNotify = (value) => {
    setNotify(value);
  };

  const handleChange = (info) => {
    setLoading1(true);

    storage
      .ref("images/" + info.file.name)
      .put(info.file.originFileObj)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL();
      })
      .then((url) => {
        console.log(url);
        setImgurl([...imgurl, url]);
        setLoading1(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const remove = (e, url) => {
    setImgurl((prev) => prev.filter((item) => item !== url));
  };

  const uploadButton = (
    <div>
      {loading1 ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8, fontSize: "14px" }}>
        {loading1 ? "uploading" : ""}
      </div>
    </div>
  );

  const handleFilter = (value) => {
    setFilter(value.target.value);

if(value.target.value === 'appartment'){

    
   return appartments && setLocation(appartments);
} else {

    data && setLocation(data[`${value.target.value}`]);

}

  
  };

  return (
    <div
      className=" rounded-lg bg-white p-5 shadow-sm"
      style={{ height: "100%", border: "1px solid #e5e5e5" }}
    >
      <Form
        {...layout}
        name="basic"
        // labelCol={{ span: 4 }}
        // wrapperCol={{ span: 10 }}
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="grid grid-cols-2 gap-10 mt-6 mx-4 ">
          <div>
            <Form.Item
              label={<p className="text-left m-0 ">Select Audiance</p>}
              name="audiance"
              rules={[{ required: true, message: "required!" }]}
            >
              <Select
                placeholder="Select audiance"
                optionFilterProp="children"
                onChange={onChange}
              >
                <Option value="farmer">Farmer</Option>
                <Option value="consumer">Consumer</Option>
                <Option value="user">Farmer & Consumer</Option>
                <Option value="all">All</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={<p className="text-left m-0 ">Notify</p>}
              name="notify"
              rules={[{ required: true, message: "required!" }]}
            >
              <Select
                placeholder="Select Type"
                optionFilterProp="children"
                onChange={onChangeNotify}
              >
                <Option value="whatsapp">WhatsApp</Option>
                <Option value="notification">Notification</Option>
                {/* <Option value="both">Both</Option> */}
              </Select>
            </Form.Item>

            {notify === "whatsapp" && (
              <>
                <Form.Item
                  label={<p className="text-left m-0 ">Template</p>}
                  name="template"
                  // rules={[{ required: true, message: 'required!' }]}
                >
                  <Select
                    placeholder="Select Type"
                    optionFilterProp="children"
                    // onChange={onChangeTemplate}
                  >
                    {templates?.map((item: any, i: any) => {
                      return (
                        <Option value={item} key={i}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  required
                  name="filter_name"
                  label={<p className="text-left m-0 ">Filter Name</p>}
                >
                  <Radio.Group
                    onChange={handleFilter}
                    defaultValue={filter}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="pins">pincode</Radio.Button>
                    <Radio.Button value="locality">locality</Radio.Button>
                    <Radio.Button value="zones">zone</Radio.Button>
                    <Radio.Button value="appartment">
                      appartment
                    </Radio.Button>
                    <Radio.Button value="advertisement">
                      advertisement
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>

                {(filter !== "advertisement" && filter !== "all" )? (
                  <Form.Item
                    label={<p className="text-left m-0 ">{filter}</p>}
                    name="search_name"
                    // rules={[{ required: true, message: 'required!' }]}
                  >
                    <Select showSearch placeholder={`Select ${filter}`}
                      filterOption={(input, option:any) =>{
                        

                        return (option.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                        
                      }
                    
                    >
                      {(data && filter!== 'appartment') ?
                        location.map((item, i) => {

                          let opData =  filter === "pins"
                          ? item.pincode
                          : filter === "zones"
                          ? item.zone
                          : item.locality
                          
                          return (
                            <option key={i} value={item?.id} label={opData}>
                             {opData}
                            </option>
                          );
                        }) :  location.map((appartment, i)=>{                             
                          return  <option key={i} value={appartment?._id} label={appartment?.apartment_info?.name}>
                             {appartment?.apartment_info?.name}
                            </option>
                        }) }

                        
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={<p className="text-left m-0 ">Advertisement</p>}
                    name="advertisement"
                    // rules={[{ required: true, message: 'required!' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Advertisement"
                      optionFilterProp="children"
                      disabled={consumerInfo !== "consumer" ? true : false}
                      onChange={handleChangeAd}
                    >
                      {advertisement &&
                        advertisement.advertisement.map((item, i) => {
                          let isexpire = moment().isBetween(
                            item?.basic_info?.sale_date[0],
                            item?.basic_info?.sale_date[1],
                            undefined,
                            "()"
                          );
                          const crop = item.farmer?.crop_info.find(
                            (c) => c.id === item.basic_info?.crop
                          );
                          if (isexpire) {
                            return (
                              <option key={i} value={item._id}>
                                {item.ad_no} (
                                {item.farmer?.farmer_info?.farmer_name}) (
                                {crop.crop_name})
                              </option>
                            );
                          }
                          return;
                        })}
                    </Select>
                  </Form.Item>
                )}
              </>
            )}

            {notify != "whatsapp" && (
              <Form.Item
                label={<p className="text-left m-0 ">Message</p>}
                name="message"
                rules={[{ required: true, message: "required!" }]}
              >
                <Input.TextArea style={{ width: "100%" }} rows={5} />
              </Form.Item>
            )}

            {notify == "whatsapp" && (
              <Form.Item
                label={<p className="text-left m-0 ml-2 ">Images</p>}
                name="image"
              >
                <div className=" grid grid-cols-4 gap-5">
                  {imgurl.map((img, i) => {
                    return (
                      <div
                        className=" imglist  bg-gray-50 text-center"
                        style={{ height: "100px" }}
                      >

                        <Image
                          preview={false}
                          key={i}
                          className="  rounded col-span-1  block  object-cover"
                          style={{ height: "100%", width: "100%" }}
                          src={img}
                          placeholder={<Loader />}
                        />
                        <h2
                          onClick={(e) => remove(e, img)}
                          className="p-1 text-white  text-xl "
                        >
                          {" "}
                          <MdDelete />
                        </h2>
                      </div>
                    );
                  })}

                  {imgurl?.length < 1 && (
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}
                      multiple={true}
                      className="ml-2"
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                  )}
                </div>
              </Form.Item>
            )}

            <div className="mt-6">
              <Form.Item {...tailLayout} className="text-right">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
