import { useState } from 'react'
import { BarChartOutlined, DiffOutlined, TeamOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import { Button, Select, Form, DatePicker } from 'antd'
import { useFetchList } from '../../../../api/services/backend';
import moment from 'moment';

export default function Index() {

  const [form] = Form.useForm();

  const [from_date, setFromDate] = useState(null) as any;
  const [to_date, setTo_date] = useState(null) as any;

  const { data, isLoading } = useFetchList(`admin-stat?from_date=${from_date}&to_date=${to_date}`)

  const { RangePicker } = DatePicker;


  let dashcard = [
    {
      title: "Apartments Enrolled",
      icon: <BarChartOutlined />,
      stat: data?.totalAppartments || 0,
      desc: 'Total Apartments Enrolled',
      backColor: "#263055",
      link: "apartment"
    },
    {
      title: "Customers Onboard",
      icon: <TeamOutlined />,
      stat: data?.no_customer || 0,
      desc: 'Total Customers Onboard',
      backColor: "#1BC943",
      link: "customer"
    },

    {
      title: "Orders",
      icon: <DiffOutlined />,
      stat: data?.totalOrders || 0,
      desc: 'Total Orders',
      backColor: "#5383FF",
      link: `#`
    },

    {
      title: "Total Sales",
      icon: <CreditCardOutlined />,
      stat: Number(data?.totalSales).toFixed(0) || 0,
      desc: 'Total Sales ',
      backColor: "#F83245",
      link: `#`
    },

  ]



  const onDateChange = (value: any, date: any) => {
    console.log(date);

    setFromDate(date[0])
    setTo_date(date[1])

  }

  const reset = () => {
    setTo_date(null)
    setFromDate(null)
    form.resetFields();
  }



  return (
    <>
      <h5 className="mx-2 text-secondary mb-2 " style={{ fontSize: "1.2rem", color: "gray", paddingBottom: "4px" }}> Statistics </h5>

      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
      >
        <div className="py-2 grid grid-cols-4 gap-x-2">
          <Form.Item name="dates">
            <RangePicker
              disabledDate={
                (current) => {
                  // Can not select days after today and before start Date
                  const start = moment('2020-01-01', 'YYYY-MM-DD');
                  return current < start || current > moment();
                }

              }
              onChange={onDateChange}
              format="YYYY-MM-DD HH:mm"

            />

          </Form.Item>
          <div>
            <Button type="primary" disabled={!from_date && !to_date} onClick={reset}>Reset</Button>
          </div>
        </div>
      </Form>


      <div className="grid gap-4 md:grid-cols-4 grid-cols-3">
        {
          dashcard.map(item => <Link key={item.title} to={item.link} title={item.title}><div className="shadow-md hover:shadow-lg rounded-lg p-2 flex flex-col justify-between items-center" style={{ minHeight: "200px" }}>
            <div className='flex items-center w-full gap-x-2'>
              <div className="p-3 rounded-full text-white grid place-items-center text-2xl place-self-start" style={{ backgroundColor: item.backColor }}>{item.icon}</div>
              <div className='text-base font-medium text-gray-500 md:break-word truncate' >{item.title}</div>
            </div>
            <h2 className='text-4xl pt-4 font-normal'>{item.stat}</h2>
            <h4 className='font-normal text-gray-400'>{item.desc}</h4>
          </div></Link>)
        }
      </div>
      <br />
      {/* <Stats /> */}
    </>
  )
}
