import  React,{ useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, Select } from 'antd';
import {useFetchList, useCreate, useFetchOne} from '../../../../api/services/backend'


  export default function Createfarmer({ actionMethod, info }) {

    const dispatch = useDispatch()

  
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
        console.log(values);

    const data = {
        bank_name:values.bank_name,
        branch_name:values.branch_name,
        account_no:values.account_no,
        IFSC_code:values.IFSC_code,
        upi_id:values.upi_id
  }
  dispatch(actionMethod())
  dispatch(info(data))

  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleManagerSelect = (value) =>{
    console.log(value);
  
}


  return (

    <div className="mt-5 mx-5">
    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<div className="grid grid-cols-2  ">
 

 <div className="col-span-1"> 
          <Form.Item
        label={<p className="text-left m-0">Bank Name</p>}
        name="bank_name"
        // rules={[{ required: true, message: 'required!' }]}
      >
        <Input />
      </Form.Item> 
      

      <Form.Item
        label={<p className="text-left m-0">Branch Name</p>}
        name="branch_name"
        // rules={[{ required: true, message: 'required!' }]}
      >
        <Input />
      </Form.Item>
      

       <Form.Item
        label={<p className="text-left m-0">Account No.</p>}
        name="account_no"
        // rules={[{ required: true, message: 'required!' }]}
      >
        <Input />
      </Form.Item>


      <Form.Item
        label={<p className="text-left m-0">IFSC No.</p>}
        name="IFSC_code"
        // rules={[{ required: true, message: 'required!' }]}
      >
        <Input />
      </Form.Item>

<h4 className="text-center">OR</h4>

      <Form.Item
        label={<p className="text-left m-0">UPI ID</p>}
        name="upi_id"
        // rules={[{ required: true, message: 'required!' }]}
      >
        <Input />
      </Form.Item>


      <div className="mt-14">
      <Form.Item className="text-right">
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form.Item>
      </div>

      </div>       
          </div>  
    </Form>

    </div>  
  );

  }