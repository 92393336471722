import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import keyUri from '../../key';
import {message} from 'antd'

const initialState = {

    loading:false,
    all_ids:[],
    hasError:false
    

}


export const deleteManySlice = createSlice({
  name: 'deleteMany',
  initialState,
  reducers: {

 getLoading: (state)  =>{

        state.loading = true

 },

getAll_delete_Ids: (state, {payload})  =>{
  console.log({K:payload});

    state.all_ids = payload
    state.loading = false

},
getError: (state)  =>{

      state.hasError = true
      state.loading = false

},



  },
})


export const { getAll_delete_Ids, getLoading, getError } = deleteManySlice.actions;



export const deleteIdSelector = state => state.delete_many;

const config = {
  headers: {
      Accept: "application/json",
  }
};


export const deleteManyItems = (path, ids)=> async dispatch =>{
 

  dispatch(getLoading())
  const key = 'delete';
  message.loading({ content: 'loading...', key })
  

  try {
  

  const {data} = await axios.post(keyUri.BACK_ENDURL + `/${path}`, ids)
data && message.success({ content: data.msg, key, duration: 2 });
dispatch(getAll_delete_Ids([]))
  } catch ({response}) {

    response.data && message.error({ content: response.data.msg, key, duration: 2 });

  }
}



export default deleteManySlice.reducer;


