import React from 'react'
import { createSlice } from '@reduxjs/toolkit';



export const initialState = {

    loading: false,
    basic:false,
    media:false,
    checklist:false,
    shop:false,
    activetab:"1",
    basic_data:null,
    media_data:null,
    checklist_data : null,
    shop_data : null,

}

export const advertisementSlice = createSlice({

    name:"advertisement",
    initialState,
    reducers:{

        getBasic: state =>{

            state.loading = true
            state.basic = true
            state.activetab = "1"
        },

        getMedia: state =>{
            state.loading = true
            state.media = true
            state.activetab = "2"

        },

        getChecklist: state =>{

            state.loading = true
            state.basic = true
            state.media = true
            state.checklist = true
            state.activetab = "3"

        },


        getShop: state =>{

            state.loading = true
            state.basic = true
            state.media = true
            state.checklist = true
            state.shop = true
            state.activetab = "4"

        },

        getFinish: state =>{

            state.loading = true
            state.basic = true
            state.media = false
            state.checklist = false
            state.shop = false
            state.activetab = "1"

        },
     
        basicInfo: (state, {payload}) =>{ 
            console.log(payload);                    
            state.loading = true
            state.basic_data = payload
        },

        mediaInfo: (state, {payload}) =>{
            console.log(payload);                    
            state.loading = true
            state.media_data = payload
    
        },
        checklistInfo: (state, {payload}) =>{
            console.log(payload);                    
            state.loading = true
            state.checklist_data = payload
        },
  
        shopInfo: (state, {payload}) =>{

            state.loading = true
            state.shop_data = payload
    
        },
    }
})

export const { getMedia, getBasic, getChecklist, getShop, basicInfo, mediaInfo, getFinish, checklistInfo, shopInfo} = advertisementSlice.actions

export const advertisementSelector = state => state.advertisement;



export const Pbasic = () => async dispatch =>{
try {
    dispatch(getBasic())  
} catch (error) {
   console.log(error);
}
}


export const PMedia = () => async dispatch =>{
    try {
        dispatch(getMedia())       
    } catch (error) {
        
        console.log(error);
    }
}

export const Pchecklist = () => async dispatch =>{
    try {
        dispatch(getChecklist())       
    } catch (error) {       
        console.log(error);
    }
}



export const Pshop = () => async dispatch =>{
    try {
        dispatch(getShop())       
    } catch (error) {       
        console.log(error);
    }
}


export const pFinish = () => async dispatch =>{
    try {
        dispatch(getFinish())       
    } catch (error) {       
        console.log(error);
    }
}


export const getBasicInfo = (info) => async dispatch =>{
    try {
        dispatch(basicInfo(info))       
    } catch (error) {       
        console.log(error);
    }
}


export const getMediaInfo = (info) => async dispatch =>{
    console.log(info);
    try {
        dispatch(mediaInfo(info))       
    } catch (error) {       
        console.log(error);
    }
}

export const getChecklistInfo = (info) => async dispatch =>{
    try {
        dispatch(checklistInfo(info))      
    } catch (error) {       
        console.log(error);
    }
}


export const getShopInfo = (info) => async dispatch =>{
    try {
        dispatch(shopInfo(info))      
    } catch (error) {       
        console.log(error);
    }
}

export default advertisementSlice.reducer
