import React, { useEffect, useState } from 'react'
import { useCreate, useEdit, useFetchOne } from '../../../api/services/backend'
import { Form, InputNumber, Button, Select } from 'antd';
import storage from '../../shared/storage'
import { MdClose, MdDelete } from 'react-icons/md'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Loader from '../../shared/loader';
import styled from 'styled-components'
const { Option } = Select;


const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};



export default function Createcategory() {
  const { mutate } = useCreate('offer')
  const [form] = Form.useForm();

  const { data } = useFetchOne('offer', 'id')

  console.log(data);



  useEffect(() => {
    if (data?.offers) {
      form.setFieldsValue({
        first_order_discount: data?.offers?.offerDicount,
        referral_amount: data?.offers?.referral_amount,
        referral_minimum_order: data?.offers?.referral_minimum_order,
        referral_balance_max_usage: data?.offers?.referral_balance_max_usage,
      })
    }
  }, [data])



  const onFinish = (values: any) => {

    const data = {
      first_order_discount: values.first_order_discount,
      referral_amount: values.referral_amount,
      referral_minimum_order: values.referral_minimum_order,
      referral_balance_max_usage: values.referral_balance_max_usage
    }
    mutate(data)
    setTimeout(()=>{
          window.location.reload()

    }, 2000)
  };



  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };






  return (
    <div className='relative'>
      <Form
        {...layout}
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="grid grid-cols-2 gap-10 mt-6 mx-4 ">
          <div >

            <Form.Item
              label={<p className="text-left m-0 ">First Order Discount %</p>}
              name="first_order_discount"
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>


            {/* Referral */}
            <Form.Item
              label={<p className="text-left m-0 ">Referral Amount</p>}
              name="referral_amount"
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label={<p className="text-left m-0 ">Referral Minimum Order value</p>}
              name="referral_minimum_order"
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label={<p className="text-left m-0 ">Referral Balance Maximum Usage %</p>}
              name="referral_balance_max_usage"
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>


            <div className="mt-14">
              <Form.Item className="text-right">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}




















