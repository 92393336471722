import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../api/store';
import axios from 'axios'
import key from '../key'
import {saveAs} from 'file-saver' 


const token = localStorage.getItem('sftoken')


export interface CounterState {
  loading: boolean;

}

const initialState: CounterState = {
  loading: false,

};


export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        getLoading: state =>{
            state.loading = true
        },
    },
 }
);

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};


export const {getLoading} = orderSlice.actions;



export const createSpecialityOrderPdf = (pdfValues) => async dispatch => {

    axios.post(key.BACK_ENDURL +'/create-specialitiese-pdf', pdfValues, config)
    .then(() => axios.get(key.BACK_ENDURL +'/fetch-pdf', { responseType: 'blob' })) 
    .then((res) => {  
        console.log(res.data);      
        const pdfBlob = new Blob([res.data], 
            { type: 'application/pdf' });
    saveAs(pdfBlob, 'invoice.pdf');      
}   
)
}


export const createSeasonalOrderPdf = (pdfValues) => async dispatch => {

    axios.post(key.BACK_ENDURL +'/create-seasonal-pdf', pdfValues, config)
    .then(() => axios.get(key.BACK_ENDURL +'/fetch-pdf', { responseType: 'blob' })) 
    .then((res) => {  
        console.log(res.data);      
        const pdfBlob = new Blob([res.data], 
            { type: 'application/pdf' });
    saveAs(pdfBlob, 'invoice.pdf');      
    }   
  )
}



export default orderSlice.reducer

