import React, {useEffect, useState} from 'react'
import {useCreate, useEdit, useFetchList, useFetchOne} from '../../../../api/services/backend'
import { Form, Input,Select, Button, Upload,message ,Image, Row, Col} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Loader from '../../../shared/loader';
import storage from '../../../shared/storage'
import { MdDelete } from 'react-icons/md';
import {useParams} from 'react-router-dom'
import styled from 'styled-components';


const { Option } = Select;


  



  export default function EditTestimonial() {

  const {mutate} = useEdit('testimonial')
  const {id} = useParams()

  const [form] = Form.useForm();



  const {data:testimonial} = useFetchOne('testimonial', id)

  
    useEffect(()=>{
        
      testimonial && form.setFieldsValue({
        name: testimonial.name,
        designation:testimonial.designation,
        feedback:testimonial.feedback
        })
  }, [testimonial])


  const onFinish = (values: any) => {

    values.id = id
   
  mutate(values)
  form.resetFields()

  
}
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };





   


  



  return (
   
    <FormWrap className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%',  border:'1px solid #e5e5e5'}}>

    <div style={{width:'80%'}}>
     <Form
 layout='vertical'
 name="basic"
       // labelCol={{ span: 4 }}
       // wrapperCol={{ span: 10 }}
       form={form}
       initialValues={{ remember: true }}
       onFinish={onFinish}
       onFinishFailed={onFinishFailed}
     >
 
 
  
   
       <Form.Item
         label={<p className="text-left m-0 "> name </p>}
         name="name"
         rules={[{ required: true, message: 'required!' }]}
       >
         <Input  style={{width:'100%'}}/>
       </Form.Item>
 
       <Form.Item
         label={<p className="text-left m-0 "> designation </p>}
         name="designation"
         rules={[{ required: true, message: 'required!' }]}
       >
         <Input   style={{width:'100%'}}/>
       </Form.Item>
 
           <Form.Item
         label={<p className="text-left m-0 "> feedback </p>}
         name="feedback"
         rules={[{ required: true, message: 'required!' }]}
       >
         <Input.TextArea rows={5}  style={{width:'100%'}}/>
       </Form.Item>
       
 
 <div className="mt-6">
       <Form.Item  className="text-right">
         <Button type="primary" htmlType="submit">
           Submit
         </Button>
       </Form.Item>
 </div>
     </Form>
     </div>
     </FormWrap>
  );

  }


  const  FormWrap = styled.div`

  position: relative;
  
  
  .imglist {
  overflow: hidden;
  
  h2 { opacity : 0;
  display:none;
  position: absolute;
  margin: 0;
  bottom: 0%;
  background-color: rgba(0,0,0,0.5);
  cursor: pointer;
  transition: 0.1s;
  
  &:hover {
  
  font-size: 1.5rem;
  }
  
  }
  
  &:hover h2 {
  
  opacity : 1;
  display:block
  }
  }
  
  `