import React, {useEffect, useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import styled from 'styled-components'
import { useDelete, useFetchList} from '../../../../api/services/backend'
import moment from 'moment'
import Link from 'antd/lib/typography/Link';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import DeleteConfirm from '../../../shared/deleteConfirm';

export default function DatatableSetings() {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const {mutate} = useDelete('banner')
  const [visibleEdit, setEditModal] = useState(false);
  const [curr_product, setProduct] = useState(null);
  const {data, isLoading, error} = useFetchList('banner')




 
  const confirm = (e, id) => {
    console.log(id._id);
    mutate(id._id)
  }

  const cancel = (e) =>{
    return null
  }

      
  const columns = [

    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },

      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: text => <p className="m-0">{text}</p>
      },

      {
        title: 'image',
        key: 'image',
        render: image =>{

          console.log({image});
          

return image.mediaType === 'image' ? <img className='w-14' src= {image.image} alt=""/> : "Video"

        } 
      },

      {
        title: 'Media Type',
        dataIndex: 'mediaType',
        key: 'mediaType',
        render: mediaType => <p>{mediaType}</p>
      },

    
      {
        title: 'products',
        dataIndex: 'products',
        key: 'products',
        render:text => <p className="m-0">{text?.map(item => item.product_info.product_name).join(', ')}</p>
      },
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
             <a href={`/dashboard/edit-banner/${id._id}`}>
             <h5  style={{color: 'var(--brandColor' }}  className="text-secondary  m-0 ">
           <FaRegEdit />  
            </h5>
            </a>

        <h5 className="m-0">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="poster" cancel={cancel} >
                <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },
        
     ]

 
      return (
        <DataTableWrap>
        { 
        !isLoading && <Table 
      
        loading={isLoading}
        columns={columns} 
        dataSource={data} 
        rowKey={(record)=>record._id}
        
        />

        }       
          </DataTableWrap>  
     )
 }
   
const DataTableWrap = styled.div`

min-height: 70vh;

`