import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchList } from '../../../api/services/backend'
import { Button, Upload, Input, DatePicker, message, Select } from 'antd'
import { UploadOutlined, SearchOutlined } from '@ant-design/icons'
import DataTable from './datatable'
import styled from 'styled-components'
import ExcelBtn from './exportexcel'
import ImgUri from '../../../key'
import { selectAuth, fethFilter } from '../../../api/authSlice'
import DeleteMany from '../../shared/deleteM'
import { useDebounce } from "use-debounce";
import axios from 'axios'
import key from '../../../key'
import moment from 'moment'
import { loadPlugin } from 'immer/dist/internal'
import ProductSaleExcel from './productSaleExcel'
const { Option } = Select;

const { RangePicker } = DatePicker;


interface Props {

  title?: string
}

const Order: React.FC<Props> = ({ title }) => {

  const { data: res, isLoading, refetch } = useFetchList('specialitiesorder')

  const [reload, setReload] = useState(false)
  const [data, setData] = useState() as any;



  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [exceldata, setExcelData] = useState<any>(null)
  const dispatch = useDispatch()
  const { filter } = useSelector(selectAuth)
  const [debouncedText] = useDebounce(search, 2000);
  const [productSale, setProductSale] = useState([])



  useEffect(() => {
    dispatch(fethFilter('specialitiesorder', debouncedText))
    setLoading(false)
  }, [dispatch, debouncedText])


  useEffect(() => {
    // const date= moment().subtract(7, 'days').format('YYYY-MM-DD')

    !data && setData(res)

  }, [res])



  function onChange(value) {
    if (value !== 'all') {
      const date = moment().subtract(value, 'days').format('YYYY-MM-DD')
      axios.get(key.BACK_ENDURL + `/specialitiesorder?date=${date}`).then(({ data }) => {
        setData(data)
      })
    }
    else {
      axios.get(key.BACK_ENDURL + `/specialitiesorder?date=${'null'}`,).then(({ data }) => {
        setData(data)
      })
    }
  }


  useEffect(() => {
    if (filter.length < 1) {
      setSearch('')
    }
  }, [filter])



  useEffect(() => {
    if (reload) {
      message.success('Successfully uploaded')
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }
  }, [reload])



  const onChangeUpload = () => {
    setReload(true)
  }


  const onSearch = (e) => {
    setLoading(true)
    setSearch(e.target.value)
  }


  const onChangeDate = (date, dateString) => {

    console.log(date, dateString);


    axios.post(key.BACK_ENDURL + `/product-sale`, date).then(({ data }) => {

      console.log({productSale:data});
      

      setProductSale(data)


    })


    axios.post(key.BACK_ENDURL + `/specialitiesorder-date`, date).then(({ data }) => {

      const orderarray = [] as any

      data?.order?.map((item: any, i: any) => {

        orderarray.push(item)

        const name = item?.products?.map(pr => {

          return { product: pr.product_name, qty: pr.units }
        })

        orderarray[i].prd = JSON.stringify(name)
        // console.log(JSON.stringify(name.toString()))

      })
      setExcelData(orderarray)
    })
  }


  const orderarray = [] as any

  data?.map((item: any, i: any) => {

    orderarray.push(item)

    const name = item?.products?.map(pr => {

      return { product: pr.product_name, qty: pr.units }
    })

    orderarray[i].prd = JSON.stringify(name)
    console.log(JSON.stringify(name.toString()))

  })

  console.log(orderarray)




  return (

    <div className="p-5 rounded-lg bg-white" style={{ border: '1px solid #e5e5e5' }}>
      <div className="flex mb-5 items-center justify-between">

        <div className='flex items-center justify-between'>
          <SearchWrap className="ml-4 mr-4" >


            <Input value={search} className="px-4 py-2 focus:outline-none" prefix={<SearchOutlined style={{ color: '#3e79f7', fontWeight: 'bold' }} width="20px" />} placeholder="Search" onChange={onSearch} />
          </SearchWrap>


          {/* <DatePicker /> */}
          <Select
            placeholder="Days"
            style={{ width: "200px" }}
            optionFilterProp="children"
            onChange={onChange}
          // defaultValue="Past 7 Days"
          >
            <Option value="7">Past 7 Days</Option>
            <Option value="30">Past 30 Days</Option>
            <Option value="90">Past 90 Days</Option>
            <Option value="all">All</Option>
          </Select>

          <DeleteMany api_path='order-many' />

        </div>
        <div className="flex  items-center justify-end ">

          <RangePicker onChange={onChangeDate} />

          <ExcelBtn data={(exceldata && exceldata?.length > 0) ? exceldata : orderarray} />


          <ProductSaleExcel data={productSale} />

          <Upload
            action={ImgUri.BACK_ENDURL + `/specialityorderByExcel`}
            // listType="file"
            onChange={onChangeUpload}
            name="file"
            className="mr-3"
          >
            <Button icon={< UploadOutlined />} type="primary">Upload</Button>
          </Upload>

        </div>
      </div>

      <DataTable refetch={refetch} loading={loading || isLoading} data={(debouncedText && filter.length > 0) ? filter : data} />

    </div>

  )
}


export default Order


const SearchWrap = styled.div`
width:100%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}

`