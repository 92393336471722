import { Table, Tag, Space, } from 'antd';
import styled from 'styled-components'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import DeleteConfirm from '../../../shared/deleteConfirm'

export default function Datatable({data, isLoading, edit, deleteFarm }) {

  
  const confirm = (e, id) => {
    console.log(id._id);
    deleteFarm(id)
  }
  
  const cancel = (e) =>{
    return null
  }


  const columns = [
    {
      title: 'Farm Name',
      key: "farm_name",
      dataIndex: 'farm_name',
      render: text => <p className="m-0 capitalize">{text}</p>,
    },
    {
      title: 'Phone No.',
      key: "ph_no",
      dataIndex: 'ph_no',
      render: text => <p className="m-0">{text}</p>,
    },
    {
      title: 'District',
      key: "district",
      dataIndex: 'district',
      render: text => <p className="m-0">{text}</p>,
    },    
    {
      title: 'Pincode',
      key: "pincode",
      dataIndex: 'pincode',
      render: text => <p className="m-0">{text}</p>,
    },
   
    {
      title: 'Action',
      key: 'action',
      render: (id) => (
        <Space size="middle">      
               <h5  onClick={(e)=>edit(e, id)}  style={{color: 'var(--brandColor' }} className="text-secondary  m-0 ">
                <FaRegEdit />   
                </h5> 

                <h5 className="text-danger  m-0 "  >                         
                  <DeleteConfirm  confirm={(e)=>confirm(e, id)} title="college" cancel={cancel} >
                  <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
                  </DeleteConfirm>                    
              </h5>
        </Space>
    ),
  },
];



      return (
        <DataTableWrap>
               <div className=" p-4  "> 

    { 
       <Table 
       pagination={false}
    loading={isLoading}
    columns={columns} 
    rowKey={(record)=>record._id}
    dataSource={data} 
    />
    }            
</div>
       </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

position : relative;
margin-bottom: 30px;
/* min-height: 70vh; */

thead[class*="ant-table-thead"] th {
background-color: #f0f0f0 !important;
  }

  .ant-table-tbody > tr > td {  
    background-color: #FAFAFA !important;
  }

`