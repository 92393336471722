import React, {useEffect, useState} from 'react'
import {useFetchList, useEdit, useFetchOne} from '../../../api/services/backend'
import { Form, Input, Button, Upload , Select, DatePicker} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {MdClose} from 'react-icons/md'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import { current } from '@reduxjs/toolkit';
import advertisements from '../../../api/services/advertisements';

const { Option } = Select;

const layout = {
    labelCol: { span: 24},
    wrapperCol: { span: 24 },
  }
  
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

  export default function EditBroadcast() {


  const {mutate} = useEdit('broadcast')

  const {data:res, isLoading, error} = useFetchList('advertisement') 
  const [consumerInfo, setConsumerInfo] =  useState()
  const [loading1, setLoading1] = useState(false)
  const [fileList, setFileList] = useState([])
  
  const {id} = useParams()

  const [form] = Form.useForm();


  const {data} = useFetchOne('broadcast', id)


  useEffect(()=>{
    console.log(data);
          
    data && form.setFieldsValue({
      advertisement:data?.broadcast?.ads_id?.ad_no,
        message:data?.broadcast?.message,
        audiance:data?.broadcast?.audiance
      
      })
}, [data])




  const onFinish = (values: any) => {
        console.log(values);

        const broadcastdata = {
    
               message:values.message,
                audiance:values.audiance,
                 id:data?.broadcast?._id
       }
  mutate(broadcastdata)
 
  };

  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeSelect = (value) =>{
    console.log(value);
}
function onChange(value) {
    console.log(`selected ${value}`);
    setConsumerInfo(value)
  }
  const handleChangeAd = (value) =>{

    // console.log(value);
  }
  
return (
   
    <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>

   
    <Form
    {...layout}
      name="basic"
      // labelCol={{ span: 4 }}
      // wrapperCol={{ span: 10 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
   <div className="grid grid-cols-2 gap-10 mt-6 mx-4 ">   
  <div >

  <Form.Item
            label={<p className="text-left m-0 ">Select Audiance</p>}
            name="audiance"
            rules={[{ required: true, message: 'required!' }]}
            >
            <Select
            disabled
              placeholder="Select audiance"
              optionFilterProp="children"
              onChange={onChange}
            >
          <Option value="former">Former</Option>
            <Option value="consumer">Consumer</Option>
            <Option value="user">Former & Consumer</Option>
            <Option value="user">All</Option>      </Select>
    </Form.Item>

  <Form.Item
            label={<p className="text-left m-0   ">Advertisement</p>}
            name="advertisement"
            // rules={[{ required: true, message: 'required!' }]}
          >
             <Select 
             disabled
             showSearch
             placeholder="Select Advertisement"    
             optionFilterProp="children"
             filterOption={(input, option:any) =>
               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
    
             onChange={handleChangeAd}>

                  {
              //     res && res.res.map((item, i)=>{ 
              //       // console.log(item?.basic_info?.sale_date);
                    
              //     let isexpire =  moment().isBetween(item?.basic_info?.sale_date[0], item?.basic_info?.sale_date[1], undefined, '()');  
              //     // console.log(isexpire);
                  
              //       const crop = item.farmer?.crop_info.find(c=> c.id === item.basic_info?.crop)                     
              //            if(isexpire){
              //              return <option key={i} value={item._id} >{item.ad_no} ({item.farmer?.farmer_info?.farmer_name}) ({crop.crop_name})</option>
              //            }         
              //     return 

              // })
            }
   
   
             </Select>
          </Form.Item>

<Form.Item
        label={<p className="text-left m-0 ">Message</p>}
        name="message"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input.TextArea  style={{width:'100%'}}rows={5} />
      </Form.Item>
  
      <div className="mt-14">
      <Form.Item className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      </div> 
      </div>
   </div>

    </Form>

    </div>
  );

  }