const key = {
    // BACK_ENDURL:"http://localhost:5000/api"
      BACK_ENDURL: "https://seashell-app-4637a.ondigitalocean.app/api"

      // BACK_ENDURL:"https://whale-app-dwdj9.ondigitalocean.app/api"

}

export default key  






