import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, DatePicker , Select, InputNumber } from 'antd';
import { useFetchList, useEdit, useFetchOne } from '../../../../api/services/backend'
import moment from 'moment'
const { Option } = Select;

const { RangePicker } = DatePicker;

    export default function Basics({ advertisement }) {

        const dispatch = useDispatch()
        const [form] = Form.useForm();

        const {mutate} = useEdit('advertisement')

        const {data, isLoading, error} = useFetchList('farmer') 
        const {data:category} = useFetchList('seasonal-category')
        console.log(advertisement);
        

        const [farmChange, setFarmChange] =  useState<any>(false)
        const [cropChange, setCropChange] =  useState<any>(false)

        const [farmerInfo, setFarmerInfo] =  useState<any>(null)
        const [farm, setFarm] =  useState<any>([])
        const [crops, setCrops] =  useState<any>([])

        
        const farmOne =  advertisement && advertisement.farmer && advertisement.farmer.farm_info.find(item => {
            return item.id === advertisement.basic_info.farm 
          } )  
         
       const cropOne =  advertisement && advertisement.farmer && advertisement.farmer.crop_info.find(item => {
         return item.id === advertisement.basic_info.crop 
         } )   


        useEffect(()=>{
            setFarmerInfo(advertisement && advertisement.farmer)   
            setFarm(advertisement && advertisement.farmer && advertisement.farmer.farm_info)

            const cropsArray = advertisement && advertisement.farmer && advertisement.farmer.crop_info.filter((item, i)=> {
                return item.farm === advertisement.basic_info.farm} )

            setCrops(cropsArray)
                  
            advertisement  && form.setFieldsValue({
                farmer: advertisement.farmer && advertisement.farmer._id,
                farm: farmOne.farm_name,
                crop: cropOne.crop_name,
                price: advertisement.basic_info && advertisement.basic_info.asking_price,
                quantity: advertisement.basic_info && advertisement.basic_info.quantity,
                submit_date: advertisement.basic_info && moment(advertisement.basic_info.submit_date),
                sale_date: advertisement.basic_info && advertisement.basic_info.sale_date.map((item)=> moment(item)),
                units: advertisement.basic_info && advertisement.basic_info.units, 
                category:advertisement.category && advertisement.category._id,
                target_customers:advertisement.target_customers
              });
        }, [advertisement])


        const onFinish = (values: any) => {
            console.log(values);

            const data = {
                   basic_info:{ 
                    farm: farmChange ? values.farm :  farmOne.id,
                    crop: cropChange ? values.crop : cropOne.id,
                    asking_price: values.price,
                    units:values.units == 'whole fruit'? 'whole': values.units,  
				    quantity: values.quantity,   
                    sale_date: values.sale_date
                },
                category:values.category,
                farmer: values.farmer,
                id: advertisement._id        
        }

           mutate(data)
        }

        
        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
          };


          const handleFarmerSelect = (value) =>{
            form.resetFields(['farm'])
            form.resetFields(['crop'])
              const farmerFound = data && data.farmer.find((item, i)=> item._id === value )            
              setFarmerInfo(farmerFound)
              setFarm(farmerFound.farm_info)
              setCrops([])
        }


        const handleFarmSelect = (value) =>{
            form.resetFields(['crop'])
            const crops = farmerInfo.crop_info.filter((item, i)=> item.farm === value )

           setCrops(crops)
           setFarmChange(true)
        }


        const handleCropSelect = (value) =>{
            setCropChange(true)
        }

        const handleCategorySelect = (value) =>{
            console.log(value);
        }

        const onChangeDate = ((date, dateString) => {
            console.log(date, dateString);
          })

          
        const handleUnitSelect = (value) =>{
            console.log(value);
            }
    

    return (
      
     <div className="mt-5 mx-5">
            
      <Form
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
    >
          <div className="grid grid-cols-2">
            <div className="col-span-1">
                <Form.Item
                    label={<p className=" text-left m-0 w-32">Select Farmer</p>}
                    name="farmer"
                    rules={[{ required: true, message: 'required!' }]}
                    >
                        <Select 
                        disabled
                        showSearch
                        placeholder="Farmer Name"  
                        filterOption={(input, option:any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                         } 
                        onChange={handleFarmerSelect}>
                    {
                        data && data.farmer.map((item, i)=>{     
                                                
                        return <option key={i} value={item._id} >{item.farmer_info.farmer_name}</option>

                    })
                    }
                            
                    </Select>
                </Form.Item>

                <Form.Item
                    label={<p className=" text-left m-0 w-32">Select Farm</p>}
                    name="farm"
                    rules={[{ required: true, message: 'required!' }]}
                    >
                        <Select
                        disabled
                         showSearch
                         placeholder="Farm Name" 
                         filterOption={(input, option:any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                         } 
                        onChange={handleFarmSelect}>
                    {
                        farm && farm.map((item, i)=>{                                                   
                        return <option key={i} value={item.id} >{item.farm_name}</option>
                    })
                    }                   
                </Select>
                </Form.Item>


                <Form.Item
                    label={<p className=" text-left m-0 w-32">Select Crop</p>}
                    name="crop"
                    rules={[{ required: true, message: 'required!' }]}
                    >
                        <Select 
                        disabled
                        showSearch
                        placeholder="Crop Name"  
                        filterOption={(input, option:any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                         } 
                        onChange={handleCropSelect}>
                    {
                        crops && crops.map((item, i)=>{                                                  
                        return <option key={i} value={item.id} >{item.crop_name}</option>
                    })
                    }                  
                </Select>
                </Form.Item>

                <Form.Item
                    label={<p className=" text-left m-0 w-34">Select Category</p>}
                    name="category"
                    rules={[{ required: true, message: 'required!' }]}
                    >
                        <Select 
                        disabled
                        placeholder="Category Name" 
                        filterOption={(input, option:any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                         } 
                        onChange={handleCategorySelect}>
                    {
                     
                     category && category.category.map((item, i)=>{     
                                                
                        return <option key={i} value={item._id} >{item.category}</option>
                    })
                    }                  
                </Select>
                </Form.Item>

                <div className="grid grid-cols-2 gap-x-10">

                <Form.Item
                    label={<p className="text-left m-0">Available Quantity</p>}
                    name="quantity"
                    rules={[{ required: true, message: 'required!' }]}
                >
                    <InputNumber />
                </Form.Item>            
                
              <Form.Item
                label={<p className=" text-left m-0">Units</p>}
                  name="units"
                //   rules={[{ required: true, message: 'required!' }]}
                >
                      {/* <Select  onChange={handleUnitSelect}>

                      <Option value="kilogram">kilogram</Option>
                      <Option value="grams">grams</Option>

                </Select> */}
                          <Input  style={{width:'100%'}} disabled={true} />

              </Form.Item>

                <Form.Item
                    label={<p className="text-left m-0">Farmer Price per unit</p>}
                    name="price"
                    rules={[{ required: true, message: 'required!' }]}
                >
                    <InputNumber />
                </Form.Item>

                    
                

                {/* <Form.Item
                    label={<p className="text-left m-0">Submit Date</p>}
                    name="submit_date"
                    rules={[{ required: true, message: 'required!' }]}
                >
                    <DatePicker  onChange={onChangeDate} />
                </Form.Item> */}

                <Form.Item
                    label={<p className="text-left m-0">Sale Date</p>}
                    name="sale_date"
                    rules={[{ required: true, message: 'required!' }]}
                >
                    <RangePicker  />
                </Form.Item>

                <Form.Item
                    label={<p className="text-left m-0">Target Customers</p>}
                    name="target_customers"
                    rules={[{ required: true, message: 'required!' }]}
                >
                    <InputNumber disabled/>
                </Form.Item>



                </div>

                <div className="mt-10">
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                </div>
            </div>
            
          </div>

    </Form>
     </div>

    )
}

