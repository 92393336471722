import React, {useEffect, useState} from 'react'
import {useCreate, useFetchList} from '../../../api/services/backend'
import { Form, Input,Select, Button, Upload,message ,Checkbox, Row, Col} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {MdClose} from 'react-icons/md'
const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };



  export default function Createuser() {

  const {mutate} = useCreate('user')
  const [imgurl, setImgurl] = useState('')
  const [loading1, setLoading1] = useState(false)
  const [fileList, setFileList] = useState([])

  const [form] = Form.useForm();

  // const {data, isLoading, error} = useFetchList('farmer')
   
  // console.log(data);


  const onFinish = (values: any) => {
    console.log(values);
    var numbers = /^[0-9]+$/;
    if(!values.phone_no.match(numbers) )
    {
    return message.error("Phone number must be only numbers") 
    }
    if(values.phone_no.length !== 10 ){
     return message.error("Phone number must be 10 numbers")
    }
   
else{

    const data = {
    
          admin_name: values.admin_name,
          role:values.role,
          email:values.email,
          phone_no: values.phone_no,
          password: values.password,
          confirm:values.confirm,
         
          
  }
  mutate(data)
  form.resetFields()

  }
}
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeSelect = (value) =>{
    console.log(value);
}

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

   
function onChange(value) {
  console.log(`selected ${value}`);
 
}

  return (
   
    <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>

   
    <Form
    {...layout}
      name="basic"
      // labelCol={{ span: 4 }}
      // wrapperCol={{ span: 10 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

        
<div className="grid grid-cols-2  mt-6 mx-4 "> 
  <div  className="col-span-1">
  {/* <Form.Item
        label={<p className="text-left m-0 "> Name</p>}
        name="name"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item> */}
  
      <Form.Item
        label={<p className="text-left m-0 "> Name</p>}
        name="admin_name"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>


      <Form.Item
        label={<p className="text-left m-0  ">Mobile No.</p>}
        name="phone_no"
        rules={[{ required: true, message: 'required!' },
       
      ]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>


    
          
<Form.Item
        label={<p className="text-left m-0  ">Email</p>}
        name="email"
        rules={[{ required: true, message: 'required!' },
        {type:"email", message: ' email is not valid!'}]}
      >
      <Input  style={{width:'100%'}}/>
      </Form.Item>

        <Form.Item
        label={<p className="text-left m-0   ">Role</p>}
        name="role"
        rules={[{ required: true, message: 'required!' }]}
      >
       <Select
              placeholder="Select Role"
              optionFilterProp="children"
              onChange={onChange}
            >
            <Option value="Seasonal Admin">Seasonal Admin</Option>
            <Option value="Specialities Admin">Specialities Admin</Option>
            <Option value="Sub Admin"> Sub Admin</Option>
            <Option value="Campaign Group">Campaign Group</Option>
      </Select>
      </Form.Item>    
      

      <Row gutter={20}>

<Col span={12}> 
      <Form.Item
            label={<p className="text-left m-0   ">Create Password</p>}
            name="password"
            rules={[
              {
                  min: 8,
                  // required: true,
                  message: 'password must be at list 8 character!',
              },
              ]}
              hasFeedback >
            <Input.Password  />
          </Form.Item>

     </Col>




     <Col span={12}> 

          <Form.Item
            label={<p className="text-left m-0   ">Confirm Password</p>}
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              { 
                // min: 8,
              //  required:true

              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
    
                  return Promise.reject('The two Passwords that you Entered Do Not Match!');
                },
              }),
            ]}
          >
            <Input.Password  />
          </Form.Item>

          </Col>
     <Col span={12}>

          </Col>  

</Row>

{/* <Form.Item  name="notify" valuePropName="checked" className="text-right">
            <Checkbox>Notify via email </Checkbox>
      </Form.Item> */}

<div className="mt-6">
      <Form.Item {...tailLayout} className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
</div>
</div>
</div>
    </Form>

    </div>
  );

  }