import {
  BarChartOutlined,
  DiffOutlined,
  TeamOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import ChartRight from "./ChartRight";
import ChartLeft from "./ChartLeft";
import { useState } from "react";
import { useEffect } from "react";
import { RiPercentFill } from "react-icons/ri";
import { useAppSelector } from "../../../api/hooks";
import { selectAuth } from "../../../api/authSlice";
import { useUpdateCommition, useFetchList } from "../../../api/services/backend";
import { Select, DatePicker, Form, Button, Input  } from "antd";
import moment from 'moment';

export default function Index() {
  const { user } = useAppSelector(selectAuth);
  const { data: agencyList, isLoading: agencyListLoading } = useFetchList("apartment");
  const [selectedAgency, selectAgency] = useState() as any;
  const { RangePicker } = DatePicker;
  const [from_date, setFromDate] = useState(null) as any;
  const [to_date, setTo_date] = useState(null) as any;
  const [form] = Form.useForm();

  const { data, isLoading, refetch } = useFetchList(`agency?apartment_id=${selectedAgency}&from_date=${from_date}&to_date=${to_date}`, 
  { enabled: !!selectedAgency })
  const { data:commition  } = useFetchList(`get-commition`)
  const { mutate } = useUpdateCommition('update-commition')


  useEffect(()=>{

    console.log({commition});
    
    form.setFieldsValue({C0_50: commition?.C0_50})

  }, [commition])




  let dashcard = [
    {
      title: "Customers Onboard",
      icon: <TeamOutlined />,
      stat: data?.no_customers,
      desc: "Total Customers Onboard",
      backColor: "#1BC943",
    },
    {
      title: "Orders",
      icon: <DiffOutlined />,
      stat: data?.totalOrder,
      desc: "Total Orders",
      backColor: "#5383FF",
    },
    {
      title: "Total Sales",
      icon: <CreditCardOutlined />,
      stat:  Number(data?.totalSales).toFixed(0),
      desc: "Total Sales ",
      backColor: "#F83245",
    },
    {
      title: "Customers Onboard in %",
      icon: <RiPercentFill />,
      stat: data?.apartmentInAgency + '%',
      desc: "",
      backColor: "#bedf04",
    },
    {
      title: "Agent Commision",
      icon: <RiPercentFill />,
      stat: data?.totalSales,
      desc: "",
      backColor: "#1304df",
    },
  ];

  

  
  
  const onAgencySelect = (value: any) => selectAgency(value)
  const onDateChange = (value: any, date:any) => {
    
    
    setFromDate(date[0])
    setTo_date(date[1])

    refetch()
    
      } 
    
      console.log({data});
      
      const reset = () => {
        setTo_date(null)
        setFromDate(null)
        refetch()
        form.resetFields();

      }


      const handleFinish = (value) =>{
let data = {...value, _id:commition._id }
        console.log(data);
        
        mutate(data)

      }

  return (
    <div className="p-5 rounded-lg bg-white" style={{ border: '1px solid #e5e5e5' }}>
     <div className="flex gap-x-3 ">
      <div>
      <Select
        loading={agencyListLoading}
        // defaultValue={agencyList?.agencies[0]?.apartment}
        // defaultValue={agencyList?.[0].apartment_id }
        style={{ width: "200px" }}
        placeholder="Select"
        optionFilterProp="children"
        onChange={onAgencySelect}
        className="capitalize"
        filterOption={(input:any, option:any) => option.children.toLowerCase().includes(input.toLowerCase())}
        showSearch
      >
        {agencyList?.map((item: any) => (
          <Select.Option key={item._id} value={item._id} style={{textTransform:"capitalize"}}>{item?.apartment_info?.name}</Select.Option>
        ))}
      </Select>
      </div>
   <div>
   <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
      >
          <Form.Item name="dates">
      <RangePicker
          disabledDate={
            (current) =>{         
              // Can not select days after today and before start Date
              const start = moment('2020-01-01','YYYY-MM-DD');        
              return  current< start || current>moment();
       }

          }
          onChange={onDateChange}
      format="YYYY-MM-DD"
    
    />
  </Form.Item>  
    </Form>
    </div>
    <Button type="primary" onClick={reset}>Reset</Button>
    </div>
      <br />

      {
        selectedAgency && <>
          <div className="grid gap-4 md:grid-cols-5 grid-cols-3">
            {dashcard.map((item) => (
              <div
                key={item.title}
                title={item.title}
                className="shadow-md hover:shadow-lg rounded-lg p-2 flex flex-col justify-between items-center cursor-pointer"
                style={{ minHeight: "200px" }}
              >
                <div className="flex items-center w-full gap-x-2">
                  <div
                    className="p-3 rounded-full text-white grid place-items-center text-2xl place-self-start"
                    style={{ backgroundColor: item.backColor }}
                  >
                    {item.icon}
                  </div>
                  <div className="text-base font-medium text-gray-500 md:break-word truncate">
                    {item.title}
                  </div>
                </div>
                <h2 className="text-4xl pt-4 font-normal">{item.stat}</h2>
                <h4 className="font-normal text-gray-400">{item.desc}</h4>
              </div>
            ))}
          </div>
          {/* <div className="grid grid-cols-2 gap-x-2 mt-6">
            <ChartLeft />
            <ChartRight />
          </div> */}
          <br/>
          <br/>
          <div style={{width:'30%'}}>
            <h2>Agency Commition</h2>
            <br/>
            <Form
      name="basic"
      onFinish={handleFinish}
      autoComplete="off"
      initialValues={{C0_50: commition?.C0_50,
         C75_90:commition?.C75_90,
                 C90_100:commition?.C90_100,

        }}
    >
       <Form.Item
        label="0% -> 50%"
        name="C0_50"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>
 <Form.Item
        label="50% -> 75%"
        name="C75_90"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="90% -> 100%"
        name="C90_100"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>
<Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
          </div>
        </>
      }
    </div>
  );
}
