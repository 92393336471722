import React, {useState, useEffect} from 'react'
import { Table, message } from 'antd';
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'
// import { getCurrentTargets} from '../../../api/authSlice'
// import {useAppDispatch} from '../../../api/hooks'

export default function Datatable({data, isLoading}) {

  // const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  // const [selectionKey, setSelectionKey] = useState([]);
  const {mutate} = useDelete('broadcast')

// const dispatch = useAppDispatch()


// useEffect(()=>{ 
//    data.length<=0 && message.error('No apartments found')
// }, [data])


  

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },
   
    {
        title: 'Apartment Name',
        dataIndex: 'apartment_info',
        key: 'apartment_info',
        render: text => <p className="m-0 capitalize">{text.name}</p>,

      },
      {
        title: 'No. of Customers',
        dataIndex: 'count',
        key: 'count',
        render: text => <p className="m-0">{text }</p>
      },

     ]

    //  const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     dispatch(getCurrentTargets(selectedRowKeys))
    //   },
    //   getCheckboxProps: (record) => ({
    //     disabled: record.name === 'Disabled User',
    //     // Column configuration not to be checked
    //     name: record.name,
    //   }),
    // };



      return (
        <DataTableWrap>
    
    { 
      !isLoading &&  <Table 
      
      // rowSelection={{
      //   ...rowSelection,
      // }}   

      pagination={{
        onChange(current) {
          setPage(current)
        }
      }}

    loading={isLoading}
    columns={columns} 
    dataSource={data} 
    rowKey={(record)=>record._id}
    
  
    />
    
  }   
      
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;

`