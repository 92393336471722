import  React,{ useEffect } from 'react'
import {  Tabs  } from 'antd';
import Basic from './baiscs'
import Media from './media'
import Checklist from './checklist'
import Shop from './shop'
import {useDispatch, useSelector} from 'react-redux'
import { useFetchOne} from '../../../../api/services/backend'
import {useParams} from 'react-router-dom'

// import { Pbasic, PMedia, Pchecklist, Pshop, pFinish, getBasicInfo, getMediaInfo, getChecklistInfo, getShopInfo, advertisementSelector } from '../../../../api/services/advertisements'

const { TabPane } = Tabs;


 export default function Ads() {

      const dispatch = useDispatch()
      // const {   activetab, basic, media, checklist, shop } = useSelector(advertisementSelector)
     
      const {id} = useParams()

      const {data} = useFetchOne('advertisement', id)

console.log({ad:data});

      const callback = (key)=> {
        console.log(key);
      }
  

    return (
      
      <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
       <Tabs           
            // activeKey={activetab}             
            onChange={callback}
            > 
              
              <TabPane tab="Basic" key="1">
              <Basic  advertisement={data?.advertisement} />
              </TabPane>

              <TabPane tab="Media"  key="2">
                  <Media  advertisement={data?.advertisement}/>
             </TabPane>
             
            <TabPane tab="Checklist"  key="3">
                  <Checklist  advertisement={data?.advertisement} />
            </TabPane>

            <TabPane tab="Shop"  key="4">
                  <Shop  advertisement={data?.advertisement} />
            </TabPane>
        
    </Tabs>
     </div>

    )
}

