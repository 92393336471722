/* eslint-disable @typescript-eslint/no-unused-vars */
import  React,{ useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useFetchList} from '../../../api/services/backend'
import {locationTab, locationSelector} from '../../../api/services/location'
import {Button,Tabs,Input} from 'antd'
import {Link} from 'react-router-dom'
import {PlusOutlined,SearchOutlined} from '@ant-design/icons'
import PincodeTable from './pincodeTable'
import LocalityTable from './localityTable'
import ZoneTable from './zoneTable'
import styled from 'styled-components'
import CityTable from './cityTable'
import { useDebounce } from "use-debounce";
import { selectAuth,fethFilter} from '../../../api/authSlice'
import { useLocation} from 'react-router-dom'

interface Props {

    title? :string
}

const { TabPane } = Tabs;


 const Region: React.FC<Props> = ({title}) => {

  const dispatch = useDispatch()

   const {data, isLoading, error} = useFetchList('location')
   const [search, setSearch] = useState('')
   const [loading, setLoading] = useState(false)
   const {filter} = useSelector(selectAuth)
   const [debouncedText] = useDebounce(search, 2000);
   const {activetab} = useSelector(locationSelector)

   const {pathname} = useLocation()


  const callback = (key) => {
    console.log({k:key});
    dispatch(locationTab(key))
  }


  const ontab = (k, e) =>{
   // e.preventDefault();
      // console.log({k:k});
//    dispatch(locationTab(k))
}

  
useEffect(()=>{
   dispatch(fethFilter('location', debouncedText ))
 setLoading(false)
  }, [dispatch, debouncedText])


useEffect(()=>{     
 if(filter.length < 1) {
   setSearch('')
 }
  }, [filter])


const onSearch = (e) => {
 setLoading(true)
 setSearch(e.target.value)

}


    return (
      
      <div className="p-5 rounded-lg bg-white  shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
          <div className="flex mb-5 items-center justify-between">    
           <div>
           
            <Link to="/dashboard/create-location">
            <Button  type="primary" icon={<PlusOutlined />}> Add {activetab === '1' ? 'City' : activetab === '2'? 'Zone' : activetab === '3'? 'Pincode' : 'Locality'}</Button>
            </Link>

           </div>

           <div>
          <SearchWrap className="mx-4 " style={{borderRadius:"4px"}}>

          <Input value={search}  className="px-4 py-2 focus:outline-none"
          prefix ={  <SearchOutlined  style={{color:'#3e79f7', fontWeight:'bold'}} />
          }
          placeholder="Search" onChange={onSearch}  />
          </SearchWrap>
          </div>

        </div>

        <Tabs  activeKey={activetab} onChange={callback}   onTabClick={(k, e)=>ontab(k, e)}>

    <TabPane tab="City" key="1">
       <CityTable  loading={loading} 
         data={(filter.length> 0) ? filter :data?.city}/>
    </TabPane>

    <TabPane tab="Zone" key="2">
       <ZoneTable  isdataLoading={isLoading} data={data} />
    </TabPane>

    <TabPane tab="Pincode" key="3">
       <PincodeTable isLoading={isLoading} data={data} />
    </TabPane>

    <TabPane tab="Locality" key="4">
       <LocalityTable isLoading={isLoading} data={data} />
    </TabPane>
  </Tabs>
       
      
     </div>
    )
}

export default Region



const SearchWrap = styled.div`
width:100%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}
`