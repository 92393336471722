import React from 'react'
import NotAgency from './notAgency';
import Agency from "./agency";
import { selectAuth } from '../../../api/authSlice'
import { useAppSelector } from '../../../api/hooks'

export default function Index() {
    const { userType } = useAppSelector(selectAuth)
    return (
        <div className="p-5 rounded-lg bg-white" style={{ border: '1px solid #e5e5e5', height: "100%" }}>
            {
                userType === "admin" ? <NotAgency /> : <Agency />
            }
        </div>
    )
}
