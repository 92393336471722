import React, {useState} from 'react'
import { Table, Tag, Space, Button } from 'antd';
import {useDispatch,useSelector} from 'react-redux'
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'
import { DownloadOutlined  } from '@ant-design/icons';
import { createSpecialityOrderPdf} from '../../../api/order'


export default function Datatable({data, loading}) {

  const dispatch = useDispatch()

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const {mutate} = useDelete('product')
  const [visibleEdit, setEditModal] = useState(false);
  const [curr_product, setProduct] = useState(null);
  const [item,setItem] =useState(null)
  const [downloadLoading,setDownloadLoading] =useState(false)


    const createPdf= (value)=> {
            
      setDownloadLoading(true)
      setItem(value._id)
      dispatch(createSpecialityOrderPdf(value))
      
      setTimeout(()=>{
        setDownloadLoading(false)
          setItem(null)
      },3000)

}

      
  const columns = [

    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },
    
    {
      title: 'orderId',
      dataIndex: 'orderId',
      key: 'orderId',
      render: text => <p className="m-0 capitalize font-medium">{text}</p>,
     
    },

    // {
    //   title: 'Customer Name',
    //   dataIndex:'user',
    //   key:'user',
    //   render: text => <p className="m-0 capitalize font-medium">{text?.customer_info?.customer_name}</p>,
    // },

    {
      title: 'Customer Name',
      key:'name',
      render: text => <p className="m-0 capitalize font-medium">{text?.customer_name ? text?.customer_name : text?.user?.customer_info?.customer_name }</p>,
    },


    {
      title: 'Apartment Name',
      dataIndex: 'address',
      key: 'address',
      render:(text)  => <p className=" capitalize m-0">{text?.apartment}</p>,
    },
  
      // {
      //   title: 'City',
      //   dataIndex: 'user',
      //   key: 'user',
      //   render:(text)  => <p className=" capitalize m-0">{text?.apartment_id?.city_id?.city}</p>,
      // },

      // {
      //   title: 'City',
      //   key: 'city',
      //   render:(text)  => <p className=" capitalize m-0">{text?.address?.city ? text?.address?.city : text?.user?.apartment_id?.city_id?.city}</p>,
      // },

      {
        title: 'Block No.',
        dataIndex: 'address',
        key: 'address',
        render: text => <p className="m-0">{text?.block_no}</p>
      },
      {
        title: 'House No.',
        dataIndex: 'address',
        key: 'address',
        render: text => <p className="m-0">{text?.house_no}</p>
      },

      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: text => <p className="m-0">{text}</p>
      },

    //   {
    //     title: ' invoiceNumber',
    //     dataIndex: 'invoiceNumber',
    //     key: 'invoiceNumber',
    //     render: text => {
    //       return <p className="m-0">{text}</p>
    //   },
    // },

  {
    title: 'Payment',
    dataIndex: 'paymentStatus',
    key: 'paymentStatus',
    render: text => {
 
    return <p className="m-0 capitalize" style={{color: text === 'success' ?'green' : text === 'pending' ? '#dc2626' : '#eab308 '}}>
      {text === 'success' ? 'Online' : text === 'pending' ? 'Pending':'C.O.D'}</p>
  },
},
  
{
  title: 'Order Status',
  dataIndex: 'orderStatus',
  key: 'orderStatus',
  render: text => {
    return <p className="m-0" style={{color: text === 'Delivered' ?'green' : '#3498db '}}>{text === 'Delivered' ? 'Delivered' : 'Ordered'  }  </p>
},
},


    // {
    //   title:'Invoice',
    //   render: (value) => { 
    //       return <Button disabled={(downloadLoading && item === value._id) ? true : false } 
    //               style={{backgroundColor:"#3498db24", color:'var(--brandColor)',boxShadow:'none'}} type="primary" onClick={()=>createPdf(value)}>
    //       {(downloadLoading && item === value._id) ? 'Loading': 'Download'}
    //         </Button>
    //   }
    // }
]

 

      return (
        <DataTableWrap>    

    { 
        <Table 
      
      loading={loading}
      columns={columns} 
      dataSource={data} 
      rowKey={(record)=>record._id}
      pagination={{
        onChange(current) {
          setPage(current)
        }
      }}
      
      expandable={{
     
      expandedRowRender: record => {
       console.log(record);
       
       return <div className="px-5 py-2 text-sm " style={{width:'100%', color:'grey'}}>
       
       <div className="text-left" style={{ letterSpacing:"0.03rem"}}>
       <div className="grid grid-cols-3 gap-2">  
       <div>
       {/* <p><b>Product type : </b> {record.products[0] && record.products[0].product_type || "null"}</p> */}
       
       <p><b>Product Name : </b> </p>

       {record.sampleproducts.map((item)=>{
         console.log(item);  
         
       return <p>{item?.name} </p>
         
       })}
       </div>
       <div>
       <p><b>Address : </b> {record.address && record.address.city || "null"}</p>
       {/* <p><b>Total : </b>₹ {record.total && record.total || "null"}</p> */}
       </div>

         </div>                
      </div>
     </div>
    }, 
  //   rowExpandable: record => record.name  !== null,
  } }
   
    />

    }       
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;


`