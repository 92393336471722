import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import axios from 'axios'
export interface CounterState {
  loading: boolean;
  blogs: string[]
}

const initialState: CounterState = {
  loading: false,
  blogs:[]
};


export const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    getBlogSuccess: (state, {payload}) => {
     
      state.blogs = payload
      state.loading = false

    },
    getLoading: state =>{

        state.loading = true
      
    }
      
    
   
  },

});

export const { getBlogSuccess, getLoading } = blogSlice.actions;


export const selectBlog = (state: RootState) => state.blogs
export const fetchPost =  (): AppThunk =>async (
  dispatch,
  getState
) => {

dispatch(getLoading())
const {data} = await axios.get('https://jsonplaceholder.typicode.com/posts?_limit=10')
    dispatch(getBlogSuccess(data))

};

export default blogSlice.reducer;
