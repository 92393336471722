import React from 'react'
import { Layout, Menu } from 'antd'
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom'


import {MdDashboard,MdShoppingBag,MdLocationOn,MdApartment,MdCampaign,MdLocalOffer,MdCategory, MdSettings} from 'react-icons/md';
import {RiAdvertisementFill} from 'react-icons/ri';
import {SiTarget} from "react-icons/si";
import {GiFarmer} from "react-icons/gi";
import {FaUsers,FaUser} from "react-icons/fa";
import {BiBroadcast} from "react-icons/bi";
import {ImBook} from "react-icons/im";
import {RiLuggageCartLine} from "react-icons/ri";
import {AiTwotoneShop} from "react-icons/ai";
import {FcStatistics} from "react-icons/fc";


import styled from 'styled-components'
import { useAppSelector } from '../api/hooks';
import { selectAuth } from '../api/authSlice'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {FaShoppingCart} from "react-icons/fa";



const { SubMenu } = Menu;



const { Sider } = Layout;

const Sidebar: React.FC<any> = ({ collapsed, click }) => {

  const { t } = useTranslation();
  const { user, userType } = useAppSelector(selectAuth)
  console.log(userType)
  const { pathname } = useLocation()
  const history = useHistory();
  console.log(pathname);

  const { name } = useAppSelector(selectAuth)

  const [items, setitems] = useState([
    {
      label: t('dashboard'),
      key: "/dashboard/home",
      icon: <MdDashboard />
    }
  ]) as any;

  useEffect(() => {
    if (userType === "admin" && user?.role) {
      setitems(prev => {
        let x = {
          advertisement: {},
          target: {},
          seasonal: {},
          shop: {},
          orders: {},
          subscribe_orders:{},
          sample: {},
          farmers: {},
          customer: {},
          users: {},
          broadcast: {},
          location: {},
          apartment: {},
          campaign: {},
          offers: {},
          lookbok: {},
          stats:{},
          setting:{}
        }
        if (user?.role === "admin") {
          x.stats = {
            label:t("Statistic"),
            key:"/dashboard/agency-statistics",
            icon:<FcStatistics style={{filter:"saturate(0)"}}/>
          }
          x.farmers = {
            label: t('Farmers'),
            key: "/dashboard/farmer",
            icon: <GiFarmer />,
          };
          x.customer = {
            label: t('Customer'),
            key: "/dashboard/customer",
            icon: <FaUser />,
          };
          x.users = {
            label: t('users'),
            key: "/dashboard/user",
            icon: <FaUsers />,
          };
          x.location = {
            label: t('Location'),
            key: "/dashboard/location",
            icon: <MdLocationOn />,
          };
          x.lookbok = {
            label: t('Logbook'),
            key: "/dashboard/logbook",
            icon: <ImBook />,
          };
          
          x.setting = {
            label: t('Setting'),
            key: "/dashboard/setting",
            icon: <MdSettings />,
                children: [
              {
                label: "Banner",
                key: "/dashboard/banner",
              },
              {
                label: "Testimonial",
                key: "/dashboard/testimonials",
              },

            ]
          }
        }

        if (user?.role === 'Sub Admin'  || user?.role === 'admin') {
          x.broadcast = {
            label: t('Broadcast'),
            key: "/dashboard/broadcast",
            icon: <BiBroadcast />,
          };
          x.apartment = {
            label: t('Apartment'),
            key: "/dashboard/apartment",
            icon: <MdApartment />,
          };

          x.offers = {
            label: t('Offers'),
            key: "/dashboard/offers",
            icon: <MdLocalOffer />,
          }

          x.setting = {
            label: t('Setting'),
            key: "/dashboard/setting",
            icon: <MdSettings />,
                children: [
              {
                label: "Banner",
                key: "/dashboard/banner",
              },
              {
                label: "Testimonial",
                key: "/dashboard/testimonials",
              },

            ]
          }
        }

        if (user?.role === 'Seasonal Admin' || user?.role === 'admin' || user?.role === 'Sub Admin') {
          x.advertisement = {
            label: t('Advertisement'),
            key: "/dashboard/advertisement",
            icon: <RiAdvertisementFill />
          };
          x.target = {
            label: t('Target'),
            key: "/dashboard/target",
            icon: <SiTarget />
          }
          x.seasonal = {
            label: t('Seasonal Category'),
            key: "/dashboard/seasonal-category",
            icon: <MdCategory />
          };
          x.setting = {
            label: t('Setting'),
            key: "/dashboard/setting",
            icon: <MdSettings />,
                children: [
              {
                label: "Banner",
                key: "/dashboard/banner",
              },
              {
                label: "Testimonial",
                key: "/dashboard/testimonial",
              },
            {
                label: "Toaster",
                key: "/dashboard/toaster",
              }
            ]    
          }
        }

        if (user?.role === 'Campaign Group' || user?.role === 'admin' || user?.role === 'Sub Admin') {
          x.campaign = {
            label: t('Campaign'),
            key: "/dashboard/campaign",
            icon: <MdCampaign />,
          }
        }

        if (user?.role === 'Specialities Admin' || user?.role === 'admin' || user?.role === 'Sub Admin') {
          x.shop = {
            label: t('Shop'),
            key: "/dashboard/shop",
            icon: <AiTwotoneShop />,
            children: [
              {
                label: "Category",
                key: "/dashboard/category",
              },
              {
                label: "Products",
                key: "/dashboard/product",
              },
              {
                label: "Sample Products",
                key: "/dashboard/sample-product",
              },
            ]
          }

          x.orders = {
            label: t('Orders'),
            key: "/dashboard/orders",
            icon: <FaShoppingCart />,
          }
  x.subscribe_orders = {
            label: 'Subscribe Orders',
            key: "/dashboard/subscribe-orders",
            icon: <FaShoppingCart />,
          }
          x.sample = {
            label: t('Sample Orders'),
            key: "/dashboard/sample-orders",
            icon: <RiLuggageCartLine />,
          }
        }




        return [...prev, ...Object.values(x).filter((val: any) => 'key' in val)];
      })
    }
  }, [userType, user?.role])


  const clickHandler = ({ key }: any) => {
    history.push(key);
  }


  return (
    <SideWrap>
      <Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        width="250"
        style={{
          height: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          position: 'fixed',
          left: 0,
          boxShadow: '0 1px 4px -1px rgb(0 0 0 / 15%)',
          backgroundColor: "white"
        }}
      >
        <div className="logo" />
        <br />
        <br />
        <br />
        <br />

        <Menu style={{ border: "none" }} theme="light"
          mode="inline"
          defaultSelectedKeys={[pathname]}
          className="menu"
          items={items}
          onClick={clickHandler}
        />
      </Sider>
    </SideWrap>
  )
}

const SideWrap = styled.div`
.ant-menu-item {
  /* font-family: 'P', sans-serif; */
display:flex;
align-items: center;
/* letter-spacing:0.5px; */

svg {

  transform: translateY(-1px);
}
  span {
    font-size:1rem;
  
} 
}
`
export default Sidebar