import React, { useEffect, useState } from 'react'
import { useCreate, useEdit, useFetchList, useFetchOne } from '../../../api/services/backend'
import { Form, InputNumber, Button, Select } from 'antd';
import storage from '../../shared/storage'
import { MdClose, MdDelete } from 'react-icons/md'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Loader from '../../shared/loader';
import styled from 'styled-components'
const { Option } = Select;


const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};



export default function DeliveryCharges() {


   const onSuccess = ({data}) =>{
console.log({data});


    }

  const { mutate } = useCreate('delivery', onSuccess)
  const [form] = Form.useForm();

  const { data } = useFetchList('delivery')




  useEffect(() => {

      console.log({delivery:data?.data});

    if (data?.data) {
      form.setFieldsValue({
        delivery_charges: data?.data?.delivery_charges,
        delivery_amount: data?.data?.delivery_amount,
  
      })
    }
  }, [data])



  const onFinish = (values: any) => {

  
    mutate(values)
    // setTimeout(()=>{
    //       window.location.reload()

    // }, 2000)
  };



  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };






  return (
    <div className='relative'>
      <Form
        {...layout}
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="grid grid-cols-2 gap-10 mt-6 mx-4 ">
          <div >

            <Form.Item
              label={<p className="text-left m-0 ">Delivery Charges</p>}
              name="delivery_charges"
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>


            {/* Referral */}
            <Form.Item
              label={<p className="text-left m-0 ">Delivery Amount</p>}
              name="delivery_amount"
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

          

            <div className="mt-14">
              <Form.Item className="text-right">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}




















