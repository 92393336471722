import  React,{ useEffect } from 'react'
import { Form, Input, Button,Checkbox, Select } from 'antd';
import {useFetchList, useEdit, useFetchOne} from '../../../../api/services/backend'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {selectAuth} from '../../../../api/authSlice'
import { useAppSelector } from '../../../../api/hooks';

  export default function Edituser() {
    const dispatch = useDispatch()

  const {mutate} = useEdit('user')

  const {id} = useParams()
  const [form] = Form.useForm();

  const {data, isLoading, error} = useFetchList('user')
  const { user } = useAppSelector(selectAuth)
   console.log({admin:user});
  const {data:res} = useFetchOne('user', id)

  console.log(res);
  console.log(data);


  const layout = {
    labelCol: { span: 24},
    wrapperCol: { span: 8 },
  }

  
//   useEffect(()=>{
   
//     res && form.setFieldsValue({
//       password:res?.admin?.password,
//       confirm_password:res?.admin?.confirm_password
//       });
// }, [res])



  const onFinish = (values: any) => {
        console.log(values);

    const data = {
       new_password:values.new_password,
       confirm_password:values.confirm_password,
       admin:values.admin_password,
       adminid:user._id,
       id:res?.admin?._id
  }
  mutate(data)
  
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeSelect = (value) =>{
    console.log(value);
}


  return (

   <div className="mt-5 mx-10">
    <Form
      name="basic"
      {...layout}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
 
        <div>    
<Form.Item
            label={<p className="text-left m-0   ">New Password</p>}
            name="new_password"
            rules={[
              {
                  min: 8,
                  // required: true,
                  message: 'password must be at list 8 character!',
              },
            
              ]}
              hasFeedback
          >
            <Input.Password />
          </Form.Item>
      
          <Form.Item
            label={<p className="text-left m-0   ">Confirm Password</p>}
            name="confirm_password"
            rules={[
              {
                  min: 8,
                  // required: true,
                  message: 'password must be at list 8 character!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
    
                  return Promise.reject('The two Passwords that you Entered Do Not Match!');
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password/>
          </Form.Item>

          <Form.Item
            label={<p className="text-left m-0   ">Admin Password</p>}
            name="admin_password"
            rules={[
              {
                  min: 8,
                  // required: true,
                  message: 'password must be at list 8 character!',
              },
              ]}
              hasFeedback
          >
            <Input.Password/>
          </Form.Item>

      <div className="mt-10">
      <Form.Item className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button >
      </Form.Item>
      </div>
      
      </div>
    </Form>

    </div>  
  );

  }