import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import blogReducer from './blogs/blogSlice';
import profileReducer from './authSlice';
import farmerReducer from './services/farmer';
import locationReducer from './services/location';
import advertisementReducer from './services/advertisements';
import orderReducer from './order';
import  deletManyReducer from './services/logic'

export const store = configureStore({
  reducer: {
    blogs: blogReducer,
    profile: profileReducer,
    farmer: farmerReducer,
    location: locationReducer,
    advertisement: advertisementReducer,
    order: orderReducer,
    delete_many:deletManyReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
