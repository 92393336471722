import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {useDispatch,useSelector} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import {Link} from 'react-router-dom'
import { motion } from "framer-motion";
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'
import { DownloadOutlined  } from '@ant-design/icons';
import ModalForm from '../../shared/modal'
import moment from 'moment'
import EditCategory from './editSample'

export default function Datatable({data, loading}) {

  const [page, setPage] = useState(1);
  const {mutate} = useDelete('sample-product')
  const [visibleEdit, setEditModal] = useState(false);
  const [curr_sample, setCategory] = useState(null);

    const confirm = (e, id) => {
      mutate(id._id)
    }

    const cancel = (e) =>{
      return null
    }

    const handleEditClick = (e, isvisible, id) =>{
      e.preventDefault()
      setCategory(id)
      setEditModal(isvisible)
      }

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },

    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <p className="m-0 capitalize  font-medium">{text}</p>,
    },
    
    {
      title: ' Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render:(text)  => <p className="m-0">{moment(text).format('DD/MM/YYYY')}</p>,
    },
  
  
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
               <Link to={`/dashboard/edit-sample-product/${id._id}`}>
             <h5 style={{color: 'var(--brandColor' }} className="text-secondary  m-0 " >
              <FaRegEdit />                           
              </h5>
              </Link>

        <h5 className="text-danger mt-2">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
            <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },

     ]


      return (
        <DataTableWrap>
     
    { 
        <Table 
      
    loading={loading}
    columns={columns} 
    dataSource={data} 
    rowKey={(record)=>record._id}

    />
    }       

         {/* <ModalForm 
            isVisible={visibleEdit} 
            title="EDIT CATEGORY"
            footer={false}
            className=""
            width="32%"
            cancel={()=>setEditModal(!visibleEdit)}>
                  <EditCategory current_category={curr_category} cancel={()=>setEditModal(!visibleEdit)}/>
            </ModalForm> */}
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;




`