import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {useDispatch,useSelector} from 'react-redux'
import DeleteConfirm from '../../../shared/deleteConfirm'
import {Link} from 'react-router-dom'
import { motion } from "framer-motion";
import styled from 'styled-components'
import { useDelete} from '../../../../api/services/backend'
import { DownloadOutlined  } from '@ant-design/icons';
import ModalForm from '../../../shared/modal'
import moment from 'moment'
import EditProduct from './editProduct'

export default function Datatable({data, loading}) {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const {mutate} = useDelete('product')
  const [visibleEdit, setEditModal] = useState(false);
  const [curr_product, setProduct] = useState(null);

    const confirm = (e, id) => {
      console.log(id._id);
      mutate(id._id)
    }

    const cancel = (e) =>{
      return null
    }

    const handleEditClick = (e, isvisible, id) =>{
      e.preventDefault()
      setProduct(id)
      setEditModal(isvisible)
      }

     console.log(data);
      
  const columns = [

    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },
    
    {
      title: 'Product Id',
      dataIndex: 'product_id',
      key: 'product_id',
      render: text => <p className="m-0 capitalize font-medium">{text}</p>,
     
    },
    {
      title: 'Product Name',
      dataIndex:'product_info',
      key:'product_info',
      render: text => <p className="m-0 capitalize font-medium">{text?.product_name}</p>,
    },

    {
      title: 'Procurement Date',
      dataIndex: 'product_info',
      key: 'product_info',
      render:(text)  => <p className="m-0">{moment(text?.date_of_procurement).format('DD/MM/YYYY')}</p>,
    },
  
      {
        title: 'Qty ',
        dataIndex: 'product_info',
        key: 'product_info',
        render: text => <p className="m-0">{text?.qty}</p>
      },

      {
        title: 'Cost price ',
        dataIndex: 'product_info',
        key: 'product_info',
        render: text => <p className="m-0">{text?.cost}</p>
      },
      {
        title: 'Selling price',
        dataIndex: 'product_info',
        key: 'product_info',
        render: text => <p className="m-0">{text?.selling_price}</p>
      },
      {
        title: 'Ratings',
        dataIndex: 'rating',
        key: 'rating',
        render: text =>{
          console.log({rating:text});
          
          let ratings=  text?.reduce((total, current)=>{
            return total+current
        }, 0)
 
        let rate = (ratings/(text?.length )).toFixed(1)
        console.log({text});

     return   <p className="m-0">{isNaN(rate as any)? 'No ratings': `${rate} (${text?.length} ratings)` } </p>

        }
       
        

      },
    
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        render: text => <p className="m-0">{text===20 ? 'No Priority': text}</p>
      },

      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
              <Link to={`/dashboard/edit-product/${id._id}`}>
             <h5 style={{color: 'var(--brandColor' }} className="text-secondary  m-0 " >
              <FaRegEdit />                           
              </h5>
              </Link>
        <h5 className="text-danger mt-2">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },

     ]

      return (
        <DataTableWrap>
      

    { 
        <Table 
      
    loading={loading}
    columns={columns} 
    dataSource={data} 
    rowKey={(record)=>record._id}
    pagination={{
      onChange(current) {
        setPage(current)
      }
    }}
         
    expandable={{
        expandedRowRender: record => {
  
         return <div className="px-5 py-2 text-sm " style={{width:'100%', color:'grey'}}>
         
         <div className="text-left" style={{lineHeight:"1.7", letterSpacing:"0.03rem"}}>
         <div className="grid grid-cols-5 gap-2">  
       
         <div> <p><b>Category Type : </b> {record && record.category_id.category_info.category_name|| "null"}</p></div>
      <div> <p><b>Units : </b> {record && record.product_info.units || "null"}</p></div>
    {/* <div> <p><b>Expiry Date : </b> {record && moment(record.product_info.expiry_date)  || "null"}</p></div> */}


            </div>                
        </div>
       </div>
      }, 
      rowExpandable: record => record.name  !== null,
    } }
    />

    }       

          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;

`