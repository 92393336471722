import  React,{ useEffect,useState } from 'react'
import { Table, Tag, Space, Button } from 'antd';
import {useDispatch,useSelector} from 'react-redux'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'
import { DownloadOutlined  } from '@ant-design/icons';
import { createSpecialityOrderPdf} from '../../../api/order'
import key from '../../../key'
import axios from 'axios'
import DeleteConfirm from '../../shared/deleteConfirm'
import {getAll_delete_Ids} from '../../../api/services/logic'
import moment from 'moment'





 const DownloadButton = ({ orderId }) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(key.BACK_ENDURL + `/generate-pdf/${orderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf'
        }
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download PDF:', error);
    }
  };

  return (
    <Button type='primary' onClick={handleDownload}>
      Download
    </Button>
  );
};



export default function Datatable({data, refetch, loading}) {

  const dispatch = useDispatch()
  const [selectionType, setSelectionType] = useState<any>('checkbox');
  const [selectionKey, setSelectionKey] = useState<any>([]);
  const [page, setPage] = useState(1);
  const {mutate} = useDelete('order')
  const [visibleEdit, setEditModal] = useState(false);
  const [curr_product, setProduct] = useState(null);
  const [item,setItem] =useState(null)
  const [downloadLoading,setDownloadLoading] =useState(false)



  useEffect(()=>{

      dispatch(getAll_delete_Ids(selectionKey))
    
    }, [selectionKey])



    const createPdf= (value)=> {
            
      setDownloadLoading(true)
      setItem(value._id)

      const handleDownload = async () => {
        const response = await fetch(`/generate-pdf/${value._id}`);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a') as any;
        link.href = url;
        link.setAttribute('download', `invoice_${value._id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      };

      // dispatch(createSpecialityOrderPdf(value?._id))
      
      setTimeout(()=>{
        setDownloadLoading(false)
          setItem(null)
      },3000)
}


const confirm = (e, id) => {
  mutate(id._id)
  setTimeout(()=>{
      window.location.reload()

  }, 1000)
}

const cancel = (e) =>{
  return null
}

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      fixed:'left',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },
    
    {
      title: 'orderId',
      dataIndex: 'orderId',
      key: 'orderId',
      fixed:'left',

      render: text => <p className="m-0 capitalize font-medium">{text}</p>,
     
    },
    {
      title: 'Subscribe',
      dataIndex: 'is_subscribed',
      key: 'is_subscribed',
      fixed:'left',

      render: text =>{

        
        return <Tag 
        color={text? "green":"blue"}
        style={{border:"none"}}
        >
          {text? "Subcribed" : "Ordered"}
          </Tag>
      } 

    },

    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed:'left',

      render: text => <p className="m-0 capitalize font-medium">{moment(text).format('DD/MM/YYYY')}</p>,
     
    },
 
    {
      title: 'Customer Name',
      key:'name',
      render: text => <p className="m-0 capitalize font-medium">{text?.customer_name ? text?.customer_name : text?.user?.customer_info?.customer_name }</p>,
    },{
      title: 'Phone Number',
      key:'user',
      render: (user) =>{        

        return <p className="m-0 capitalize font-medium">{user?.user?.ph_no}</p>
      } ,
    },
    {
      title: 'Apartment Name',
      dataIndex: 'user',
      key: 'user',
      render:(text)  => <p className=" capitalize m-0">{text?.apartment_id?.apartment_info?.name}</p>,
    },
  
      {
        title: 'Block No.',
        dataIndex: 'address',
        key: 'address',
        render: text => <p className="m-0">{text?.block_no}</p>
      },
      {
        title: 'House No.',
        dataIndex: 'address',
        key: 'address',
        render: text => <p className="m-0">{text?.house_no}</p>
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: text => <p className="m-0">{text}</p>
      },
      {
        title: ' invoiceNumber',
        dataIndex: 'invoiceNumber',
        key: 'invoiceNumber',
        render: text => {
          return <p className="m-0">{text}</p>
      },
    },
    {
      title: ' Payment Method',
      dataIndex: 'payment_method',
      key: 'payment_method',
      render: text => {
        return <p className="m-0 capitalize"  style={{color:text === 'online' ? 'green' : text === 'COD' ? '#3498db': '' }}>
            {text === 'ONLINE' ? 'Online' : text === 'COD' ? 'C.O.D': 'null'}
        </p>
    },
  },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: text => {
  
        return <p className="m-0 capitalize" style={{color: text === 'success' ?'green' : text === 'Pending' ? '#eab308' : text === 'failed' ? '#dc2626'  : text === 'unpaid' ? '#3498db ' : `#000000`}}>
         {text}
         </p> 
    },
  },
  
{
  title: 'Order Status',
  dataIndex: 'orderStatus',
  key: 'orderStatus',
  render: text => {
    return <p className="m-0" style={{color: text === 'Delivered' ?'green' : '#3498db '}}>{text === 'Delivered' ? 'Delivered' : 'Ordered'  }  </p>
},
},
    {
      title:'Invoice',
      render: (value) => { 
          return       <DownloadButton orderId={value?._id} />

      }
    },
   
    {
      title: 'Action',
      key: 'action',
      render: (id) => {

        

      return   <Space size="middle">
    
      <h5 className="m-0">
          {/* {(id?.paymentStatus === 'pending' || id?.paymentStatus === 'failed') && id?.payment_method === 'online' ? */}
              <DeleteConfirm disabled={(id?.paymentStatus === "success" || id?.paymentStatus === "unpaid") ? true : false} confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                  <FaRegTrashAlt style={{cursor:"pointer", color: (id?.paymentStatus === "success"|| id?.paymentStatus === "unpaid")? "#e5e5e5" : "#e43d3d"}} />
              </DeleteConfirm> 
   
      </h5>

      </Space>
      },
    },
] as any

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {

console.log(selectedRowKeys);

    setSelectionKey(selectedRowKeys)
  },

  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};

 console.log({data});
 
      return (
        <DataTableWrap>    
    { 
        <Table 
      
      loading={loading}
      columns={columns} 
      // rowSelection={{
      //   type: selectionType,
      //   ...rowSelection,
      // }}
      scroll={{x:"max-content"}}
      dataSource={data} 
      rowKey={(record)=>record._id}
      pagination={{
        onChange(current) {
          setPage(current)
        }
      }}
      
      expandable={{
     
      expandedRowRender: record => {
       
       return <div className="px-5 py-2 text-sm " style={{width:'100%', color:'grey'}}>
       
       <div className="text-left" style={{ letterSpacing:"0.03rem"}}>
       <div className="grid grid-cols-3 gap-2">  
       <div>
       <p><b>Product type : </b> {record.order_type|| "null"}</p>
       
       <p><b>Product Name : </b> </p>
       {record.products.map((item)=>{
         console.log({item});
         
       return <p>{item.product_name} - ( <b>{item?.qty}</b> &nbsp; quantity &nbsp;{item?.qty_per_box}
        {item.measure_unit})</p>
         
       })}
       

       </div>
       <div>
          <p><b>Address : </b> {record?.address?.block_no },
          {record?.address?.house_no} <br/>
          {record?.user?.apartment_id?.apartment_info?.name} Appartment, <br/>
          {record?.user?.apartment_id?.apartment_info?.address}
          </p>
          <p><b>Total : </b>₹ { record?.is_subscribed ? (record?.total/record?.total_month_sub ).toFixed(0): record?.total}</p>
        </div>
        <div>
          <p><b>Payment method : </b> {record.payment_method && record.payment_method|| "null"} </p>
          <p><b>Payment Status : </b> {record.paymentStatus && record.paymentStatus || "null"} </p>
        </div>
         </div>                
      </div>
     </div>
    }, 
  } }
   
    />
    }       
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`
min-height: 70vh;
`