import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useCreate, useFetchList} from '../../../../api/services/backend'
import { Form, Input, Button, Upload ,message, Row,Image, Col} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {MdClose, MdDelete} from 'react-icons/md'
import DataTable from './farmTable'
import { nanoid } from '@reduxjs/toolkit'
import { useEdit} from '../../../../api/services/backend'
import storage from '../../../shared/storage'
import styled from 'styled-components'
import Loader from '../../../shared/loader';


  export default function Createfarm({ farmer}) {
    const dispatch = useDispatch()
    const {mutate} = useEdit('farmer')

  const [form] = Form.useForm();
  const [imgurl, setImgurl] = useState<any>([])
  const [loading1, setLoading1] = useState(false)
  const [fileList, setFileList] = useState([])

  const [button, setButton] = useState('save')
  const [id, setId] = useState(null)

  const [farm, setFarm] = useState<any>([])
  
  console.log(imgurl);


  useEffect(()=>{
    setFarm(farmer && farmer.farm_info)
}, [farmer])


  const handleClickEdit = (e, id) => {
      e.preventDefault() 
        setImgurl(id.image)
      id && form.setFieldsValue({
         farm_name: id.farm_name,
         ph_no: id.ph_no,
         address: id.address,
         village: id.village,
         district: id.district,
         taluk: id.taluk,
         state: id.state,
         pincode: id.pincode,
         // image: id.image,
         video: id.video,
         about: id.about,
       });
       setButton('update')
       setId(id.id)
    }


    const handleClickDelete = (id) => {
      const newFarm = farm.filter(item=> {
          return item.id !== id.id
      })      
      setFarm(newFarm)
      message.info('Farm Deleted')

    }

    
  const onFinish = (values: any) => {
    
    console.log(values);
    var numbers = /^[0-9]+$/;
    if(!values.ph_no.match(numbers) )
    {
    return message.error("Phone number must be only numbers") 
    }
    if(values.ph_no.length !== 10 ){
     return message.error("Phone number must be 10 numbers")
    }
    if(!values.pincode.match(numbers) )
    {
      return message.error("Pincode must be only numbers") 
    }
    if(values.pincode.length !== 6 ){
      return message.error("pincode must be 6 digit")
     }
else{
 
     const data = {
          farm_name: values.farm_name,
          ph_no:values.ph_no,
          address:values.address,        
          village :values.village,
          district :values.district ,
          taluk:values.taluk ,
          state: values.state, 
          pincode: values.pincode,  
          image:imgurl,
          video: values.video,
          about: values.about,
          id: button === 'update' ? id : nanoid()
        }

    if(button === 'update'){
      const newFarm = farm.map(item=> {
        if(item.id === id){
          return data
        } 
        return item
      })
      setFarm(newFarm)
      setButton('save')
      message.info('Farm Updated')
    }

    else {
      setFarm([...farm, data ])
      setButton('save')
      message.info('Farm Added')
  }
  setId(null)
  setImgurl([])
  form.resetFields()
  
  }
  }
  
  const onSubmit = () => {
    const data = {
      farm_info:farm,
      id:farmer._id
    }
       mutate(data)
    }
    
    const onFinishFailed = (errorInfo: any) => {
      console.log('Failed:', errorInfo);
    };



  const handleChange = info => {
setLoading1(true)
   
    storage
    .ref("images/" + info.file.name)
    .put(info.file.originFileObj)
    .then(snapshot => {
      return snapshot.ref.getDownloadURL();
    })
    .then(url => {
      console.log(url);
      setImgurl([...imgurl, url])
      setLoading1(false)

    })
    .catch(error => {
      console.log(error);
    });

  };


const remove = (e, url) =>{

setImgurl(prev => prev.filter(item => item !== url))

}


  const uploadButton = (
    <div>
      { loading1 ? <LoadingOutlined  /> : <PlusOutlined />}
      <div style={{ marginTop: 8, fontSize:"14px" }}>{loading1 ? "uploading" :""}</div>
    </div>
  );

  return (
   
    <EditFormWrap className="mt-4 mx-5">

{
  farm && farm.length>0 ? <DataTable  isLoading={false} edit={handleClickEdit} deleteFarm={handleClickDelete} data={farm} /> : '' 
}


    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<div className="grid grid-cols-2 gap-10 ">

<div>

              
      <Form.Item
        label={<p className="text-left m-0 ">Farm Name</p>}
        name="farm_name"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>

      <Form.Item
        label={<p className="text-left m-0 ">Phone No.</p>}
        name="ph_no"
        rules={[{ required: true, message: 'required!' },
]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>


                
    <Form.Item
            label={<p className="text-left m-0 ml-2 ">Images</p>}
            name="image"
            // rules={[{ required: true, message: 'required' }]}
          >
<div 
    className=" grid grid-cols-4 gap-5">
{
    imgurl.map((img, i)=>{

      return <div className=" imglist  bg-gray-50 text-center" style={{height:"100px"}}>
      {/* <img key={i} className="  rounded col-span-1  block  object-cover" style={{height:"100%", width:"100%"}} src={img} alt="hj"   /> */}
      
      <Image    
      preview={false}               
      key={i}
      className="  rounded col-span-1  block  object-cover"
      style={{height:"100%", width:"100%"}}
      src={img}
      placeholder={<Loader/> }              
    />   
      <h2 onClick={(e)=>remove(e, img)} className="p-1 text-white  text-xl "> <MdDelete/></h2> 

        </div>
    })
}

<Upload
        
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          multiple={true}
          className="ml-2"
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
  </div> 


        </Form.Item>


        <Form.Item
        label={<p className="text-left m-0 ">Video Link</p>}
        name="video"
        // rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>    
      
      <Form.Item
            label={<p className="text-left m-0 ">About Farm</p>}
            name="about"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input.TextArea  style={{width:'100%'}} rows={2}/>
          </Form.Item>
             
 </div>
  <div>
           
          <Form.Item
        label={<p className="text-left m-0 ">Address</p>}
        name="address"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input.TextArea  style={{width:'100%'}} rows={2}/>
      </Form.Item>


      <Row gutter={20}>
        <Col span={8}> 
            <Form.Item
              label={<p className="text-left m-0 ">Village</p>}
              name="village"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item>
            </Col>

            <Col span={8}> 
            <Form.Item
              label={<p className="text-left m-0">Taluk</p>}
              name="taluk"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

                 <Col span={8}>    
            <Form.Item
              label={<p className="text-left m-0 ">District</p>}
              name="district"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item>
            </Col>

            <Col span={8}> 
            <Form.Item
              label={<p className="text-left m-0">State</p>}
              name="state"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item>
            </Col>
      

            <Col span={8}> 
            <Form.Item
              label={<p className="text-left m-0 ">Pincode</p>}
              name="pincode"
              rules={[{ required: true, message: 'required!' }]}
            >
              <Input />
            </Form.Item> 
            </Col>
      </Row>

     

    </div>
{/* 
    <div className="text-center">
          <Button htmlType="submit" icon={<PlusOutlined />} >
          Add Farm
        </Button>

    </div> */}

 
</div>


<div className="mt-6">

      <Form.Item className="text-right">

             <Button className="mx-5" type="primary" htmlType="submit"> {button === 'save' ? 'Save' : 'Update' } </Button>

        <Button type="primary" disabled={farm && farm.length<1 ? true : false} onClick={onSubmit}>
          Submit
        </Button>
      </Form.Item>
</div>
   

    </Form>

    </EditFormWrap>
  );

  }


  const EditFormWrap = styled.div`
        position: relative;

  .imglist {
overflow: hidden;
    h2 { opacity : 0;
       display:none;
      position: absolute;
      margin: 0;
      bottom: 11%;
      background-color: rgba(0,0,0,0.5);
   cursor: pointer;
   transition: 0.1s;

   &:hover {

    font-size: 1.5rem;
   }
      
      }

    &:hover h2 {

      opacity : 1;
      display:block
    }
  }
  
  `