import React from 'react'
import { createSlice } from '@reduxjs/toolkit';



export const initialState = {

    loading: false,
    activetab:"1",
}

export const locationSlice = createSlice({

    name:"location",
    initialState,
    reducers:{
          getTab: (state, {payload}) =>{             
            state.loading = true
            state.activetab = payload
        },
      
    }
})

export const {getTab} = locationSlice.actions

export const locationSelector = state => state.location;


export const locationTab = (info) => async dispatch =>{
    try {
        dispatch(getTab(info))       
    } catch (error) {       
        console.log(error);
    }
}


export default locationSlice.reducer
