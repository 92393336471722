import  React,{ useEffect,useState } from 'react'
import {motion} from 'framer-motion'
import {   useQuery } from 'react-query'
import key from '../../../../key'
import { Table,} from 'antd'
import {useFetchList} from '../../../../api/services/backend'
import {Button,Input} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import ModalForm from '../../../shared/modal'
import DataTable from './datatable'
import CreateProduct from './createProduct'
import ExcelBtn from './exportexcel'
import styled from 'styled-components'
import { useDebounce } from "use-debounce";
import {PlusOutlined,SearchOutlined} from '@ant-design/icons'
import { selectAuth,fethFilter} from '../../../../api/authSlice'
interface Props {

    title? :string
}

 const Product: React.FC<Props> = ({title}) => {



   const {data, error, isLoading} = useFetchList('product')

   const [search, setSearch] = useState('')
   const [loading, setLoading] = useState(false)
   const dispatch = useDispatch()
   const {filter} = useSelector(selectAuth)
   const [debouncedText] = useDebounce(search, 2000);
   
   const [visible, setVisible] = useState(false)
  console.log(data);
  


  useEffect(()=>{
    dispatch(fethFilter('product', debouncedText ))
     setLoading(false)
   }, [dispatch, debouncedText])


 useEffect(()=>{     
  if(filter.length < 1) {
    setSearch('')
  }
   }, [filter])


const onSearch = (e) => {
  setLoading(true)
  setSearch(e.target.value)

}



    return (
      
      <div className="p-5 rounded-lg bg-white " style={{border:'1px solid #e5e5e5'}}> 
          <div className="flex mb-5 items-center justify-between">    
           <div>
           <Link to="/dashboard/create-Product">
            <Button onClick={()=>setVisible(true)}  type="primary" icon={<PlusOutlined />}>
         Add New Product</Button>
        </Link>
           </div>
           
      <div className="flex  items-center justify-end">
          <SearchWrap className="mx-4 " style={{borderRadius:"4px"}}>

          <Input value={search}  className="px-4 py-2 focus:outline-none"
          prefix ={  <SearchOutlined  style={{color:'#3e79f7', fontWeight:'bold',}} />
          }
          placeholder="Search" onChange={onSearch}  />
          </SearchWrap>
    
           <ExcelBtn  data={ data} />

           </div>
           
        </div>
    
        <DataTable  loading={loading || isLoading}
         data={(filter.length> 0) ? filter :data?.product}/>
      
        {/* <ModalForm 
          isVisible={visible} 
          title="NEW PRODUCT"
          footer={false}
          className=""
          width="40%"
          cancel={()=>setVisible(!visible)}>
            
            <CreateProduct/>
            
             </ModalForm>  */}

     </div>



    )
}

export default Product



const SearchWrap = styled.div`
width:100%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}

`
