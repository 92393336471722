import { Button } from 'antd';
import React from 'react'
import ReactExport from "react-export-excel";
import { DownloadOutlined  } from '@ant-design/icons';
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function ExportExcel({data}) {
    console.log(data);

    return (
       
        <ExcelFile filename="Seasonal Order" element={<Button icon={<DownloadOutlined  style={{transform:"translateY(0px)" }}/>} className="mx-2" type="primary">Download</Button>}>
               
        <ExcelSheet data={data} name="order" >
      
          
            <ExcelColumn label="orderId" value={"orderId" || null}/> 

            {/* <ExcelColumn label="Customer Name" value={col => col?.user?.customer_info ? col?.user?.customer_info?.customer_name: "null"}/>  */}
            {/* <ExcelColumn label="Customer Number" value={col => col?.user ? col?.user?.ph_no: "null"}/>   */}
            {/* <ExcelColumn label="Apartment Name" value={col =>col?.user?.apartment_id?.apartment_info ? col?.user?.apartment_id?.apartment_info?.name: "null"}/>  */}
                     
            <ExcelColumn label="Customer Name" value={col => col?.customer_name ? col?.customer_name : col?.user?.customer_info ? col.user?.customer_info?.customer_name: "null"}/> 
            <ExcelColumn label="Phone No." value={col => col?.customer_phone ? col?.customer_phone : col?.user?.ph_no ? col?.user?.ph_no : null }/> 
            <ExcelColumn label="Apartment Name" value={col => col?.address?.apartment ? col?.address?.apartment  : "null"}/>  
          
            <ExcelColumn label="Product Name" value={col => col?.products[0] ? col?.products[0]?.product_name: "null"}/>
            <ExcelColumn label="Quantity" value={col => col?.products[0] ? col?.products[0]?.units: "null"}/>
            <ExcelColumn label="Payment Method" value={ "payment_method" || null}/>
            <ExcelColumn label="paymentStatus" value={"paymentStatus" || null}/>
             <ExcelColumn label="Total" value={col => col?.total ? col?.total: "null"}/> 
            <ExcelColumn label="orderStatus" value={"orderStatus"}/>  
            <ExcelColumn label="deliveryStatus" value={col => col?.orderStatus==='Delivered' ? 'Yes' : "No"}/> 

        </ExcelSheet>
   
    </ExcelFile>
    )
}


