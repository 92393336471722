import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, DatePicker , Select, InputNumber } from 'antd';
import moment from 'moment'
import { useFetchList } from '../../../../api/services/backend'
import axios from 'axios'
import { useFetchOne} from '../../../../api/services/backend'
import key from '../../../../key'
const { Option } = Select;

const { RangePicker } = DatePicker;

    export default function Basics({ actionMethod, info }) {

        const dispatch = useDispatch()
        const [form] = Form.useForm();
        const {data, isLoading, error} = useFetchList('farmer')   
        const {data:category} = useFetchList('seasonal-category')

        const [farmerInfo, setFarmerInfo] =  useState<any>(null)
        const [crops, setCrops] =  useState<any>([])

        console.log(crops);
        // console.log(category);
        

        useEffect(()=>{
        crops[0] && form.setFieldsValue({
           
            qty:crops[0].quantity,
            // harvesting_time:crops[0].harvesting_time
            harvesting_time:crops[0].harvesting_time.map((item)=>{
                return moment(item)
              })
           
          })
        },[crops])

        
        const onFinish = (values: any) => {
            console.log(values);

            const data = {
                   basic_info:{ 
                    farm: values.farm,
                    crop: values.crop,
                    asking_price: values.price,
                    units:values.units == 'whole fruit'? 'whole': values.units,  
				    quantity: values.quantity,   
                    sale_date: values.sale_date,
                },
                category:values.category,
                farmer: values.farmer,
        }

            dispatch(actionMethod())
            dispatch(info(data))
        }

        
        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
          };


          const handleFarmerSelect = (value) =>{
            form.resetFields(['farm'])
            form.resetFields(['crop'])
              const farmerFound = data && data.farmer.find((item, i)=> item._id === value )        
      
              setFarmerInfo(farmerFound)
              setCrops([])
        }


        const handleFarmSelect = (value) =>{
            form.resetFields(['crop'])
            const crops = farmerInfo.crop_info.filter((item, i)=> item.farm === value )

           setCrops(crops)
        }


        const handleCropSelect = async (value) =>{
            console.log(value);
        }
        

        const handleCategorySelect = async(value) =>{
            console.log(value);
            const data  = await axios.get(key.BACK_ENDURL + `/seasonal-category/${value}`)
            data && console.log(data?.data?.category?.units);
            
            form.setFieldsValue({
                units: data?.data?.category?.units =='whole'? `whole fruit`: data?.data?.category?.units,
                })
        }


        const onChangeDate = ((date, dateString) => {
            console.log(date, dateString);
          })

        const handleUnitSelect = (value) =>{
        console.log(value);
        }

    return (
      
     <div className="mt-5 mx-5">
            
      <Form
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
    >
          <div className="grid grid-cols-2">
            <div className="col-span-1">
                <Form.Item
                    label={<p className=" text-left m-0 w-32">Select Farmer</p>}
                    name="farmer"
                    rules={[{ required: true, message: 'required!' }]}
                    >
                        <Select 
                           showSearch
                           placeholder="Farmer Name"  
                           filterOption={(input, option:any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                         } 
                           onChange={handleFarmerSelect}>
                    {
                        data && data.farmer.map((item, i)=>{     
                                                
                        return <option key={i} value={item._id} >{item.farmer_info.farmer_name}</option>

                    })
                    }
                            
                    </Select>
                </Form.Item>

                <Form.Item
                    label={<p className=" text-left m-0 w-32">Select Farm</p>}
                    name="farm"
                    rules={[{ required: true, message: 'required!' }]}
                    >
                        <Select
                         showSearch
                         placeholder="Farm Name" 
                         disabled={farmerInfo && farmerInfo.farm_info.length >=1 ? false : true}
                         filterOption={(input, option:any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                         } 
                        onChange={handleFarmSelect}>
                    {
                        farmerInfo && farmerInfo.farm_info.map((item, i)=>{     
                                                
                        return <option key={i} value={item.id} >{item.farm_name}</option>

                    })
                    }                   
                </Select>
                </Form.Item>


                <Form.Item
                    label={<p className=" text-left m-0 w-32">Select Crop</p>}
                    name="crop"
                    rules={[{ required: true, message: 'required!' }]}
                    >
                        <Select 
                        showSearch
                        placeholder="Crop Name"  
                        disabled={crops.length <1 ? true : false}
                        filterOption={(input, option:any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                         } 
                        onChange={handleCropSelect}>
                    {
                        crops && crops.map((item, i)=>{     
                                                
                        return <option key={i} value={item.id} >{item.crop_name}</option>
                    })
                    }                  
                </Select>
                </Form.Item>

                <div className="grid grid-cols-2 gap-x-10">
                <Form.Item
                    label={<p className="text-left m-0"> Quantity</p>}
                    name="qty"
                    rules={[{ required: true, message: 'required!' }
                 ]}
                >
                    <InputNumber disabled={true}  />
                </Form.Item>   
                <Form.Item
            label={<p className="text-left m-0">Harvesting Time</p>}
            name="harvesting_time"
            rules={[{ required: true, message: 'required!' }]}
          >
            <RangePicker disabled={true} />
          </Form.Item>     
</div>
                <Form.Item
                    label={<p className=" text-left m-0 w-34">Select Category</p>}
                    name="category"
                    rules={[{ required: true, message: 'required!' }]}
                    >
                        <Select 
                        placeholder="Category Name" 
                        filterOption={(input, option:any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                         } 
                        onChange={handleCategorySelect}>
                    {
                     
                     category && category.category.map((item, i)=>{     
                                                
                        return <option key={i} value={item._id} >{item.category}</option>
                    })
                    }                  
                </Select>
                </Form.Item>
               

                <div className="grid grid-cols-2 gap-x-10">

                <Form.Item
                    label={<p className="text-left m-0">Available Quantity</p>}
                    name="quantity"
                    rules={[{ required: true, message: 'required!' }
                 ]}
                >
                    <InputNumber />
                </Form.Item>                     
                
                <Form.Item
                label={<p className=" text-left m-0">Units</p>}
                  name="units"
                //   rules={[{ required: true, message: 'required!' }]}
                >
                      {/* <Select  onChange={handleUnitSelect}>

                      <Option value="kilogram">kilogram</Option>
                      <Option value="grams">grams</Option>

                </Select> */}
                          <Input  style={{width:'100%'}} disabled={true} />

              </Form.Item>

                <Form.Item
                    label={<p className="text-left m-0">Farmer Price per unit</p>}
                    name="price"
                    rules={[{ required: true, message: 'required!' }]}
                >
                    <InputNumber />
                </Form.Item>

                {/* <Form.Item
                    label={<p className="text-left m-0">Submit Date</p>}
                    name="submit_date"
                    rules={[{ required: true, message: 'required!' }]}
                >
                    <DatePicker  onChange={onChangeDate} />
                </Form.Item> */}

                <Form.Item
                    label={<p className="text-left m-0">Sale Date</p>}
                    name="sale_date"
                    rules={[{ required: true, message: 'required!' }]}
                >
                    <RangePicker  />
                </Form.Item>

                </div>

                <div className="mt-10">
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="submit">
                          Next
                        </Button>
                    </Form.Item>
                </div>
            </div>
            
          </div>

    </Form>
     </div>

    )
}

