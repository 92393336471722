import React, {useEffect, useState} from 'react'
import {useCreate, useFetchList} from '../../../api/services/backend'
import { Form, Input, Button, InputNumber ,Checkbox, Select, Row, Col} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {MdClose} from 'react-icons/md'
const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };



  export default function Createapartment({cancel}) {

  const {mutate} = useCreate('campaign')
  
  const [cityFound, setCityInfo] =  useState<any>(null)
  const [zoneInfo, setZoneInfo] =  useState<any>([])
  const [pincodeInfo, setPincodeInfo] =  useState<any>([])
  const [localityInfo, setLocalityInfo] =  useState<any>([])

  const [form] = Form.useForm();

  const {data, isLoading, error} = useFetchList('location')


  const onFinish = (values: any) => {
        console.log(values);

    const data = {

    
       apartment_info: {
      
          name: values.name,
          address: values.address,
          phone_no:values.phone_no,
          status:values.status
         
        },
        zone_id:values.zone,
        pincode_id:values.pincode,
        locality_id:values.locality,
        city_id:values.city
  }
  mutate(data)
  form.resetFields()

  cancel()

  };

  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };



const onchangeCity = (value) =>{
   form.resetFields(['zone'])
   form.resetFields(['pincode'])
   form.resetFields(['locality'])
    const cityFound = data && data.city.find((item, i)=> item._id === value )
      
      setZoneInfo(cityFound.zone_info ? cityFound.zone_info : [])
      setCityInfo(cityFound)
      setPincodeInfo([])
      setLocalityInfo([])
    }


    const onChangeZone=(value)=> {
      form.resetFields(['pincode'])
      form.resetFields(['locality'])
      const pinsFound = cityFound.pincode_info.filter((item, i)=>{   
                   return item.zone === value 
      })
      setPincodeInfo(pinsFound)
      setLocalityInfo([])
    }
  

  const onChangePincode=(value)=>{
    form.resetFields(['locality'])
    const localityFound = cityFound.locality_info.filter((item, i)=> item.pincode === value )
    setLocalityInfo(localityFound)
  }

  const onChangeLocality=(value)=>{
    console.log(`selected ${value}`);
  
  }
  function onChange(value) {
    console.log(`selected ${value}`);
  }
  
  return (
   
    <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>

    <Form
    {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
       
       <div className="grid grid-cols-3  mt-6 mx-4 "> 
       <div  className="col-span-2">
       <Form.Item
        label={<p className="text-left m-0 ">Apartment Name</p>}
        name="name"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>

  
      <Form.Item
        label={<p className="text-left m-0  ">Address</p>}
        name="address"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input.TextArea rows={2}  style={{width:'100%'}}/>
      </Form.Item> 

<Row gutter={20}>



      <Col span={12}>
      <Form.Item
            label={<p className="text-left m-0   ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
          >
             <Select 
             showSearch
             placeholder="Select City"    
             optionFilterProp="children"
             filterOption={(input, option:any) =>
               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
             onChange={onchangeCity}>

                  {
                  data && data.city.map((item, i)=>{     
                                        
                  return <option key={i} value={item._id} >{item.city}</option>

              })
            }
   
   
             </Select>
          </Form.Item>
         </Col>


           <Col span={12}>
           <Form.Item
            label={<p className="text-left m-0   ">Zone</p>}
            name="zone"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Select
              showSearch
              placeholder="Select Zone"
              optionFilterProp="children"
              onChange={onChangeZone}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              disabled={zoneInfo.length < 1 ? true : false}
            >
              {
                  zoneInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.zone}</option>
              })
              }
      
      </Select>
          </Form.Item>
   
          </Col> 


          <Col span={12}>
          <Form.Item
          label={<p className="text-left m-0  ">Pincode</p>}
          name="pincode"
           rules={[{ required: true, message: 'required!' }]}
         >
         <Select
             showSearch
              placeholder="Select Pincode"
              optionFilterProp="children"
              onChange={onChangePincode}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              disabled={pincodeInfo.length <1 ? true : false}
            >
              {
                  pincodeInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.pincode}</option>
              })
              }
      
      </Select>
         </Form.Item>
          </Col>

      <Col span={12}>     

          <Form.Item
            label={<p className="text-left m-0   ">Locality</p>}
            name="locality"
            rules={[{ required: true, message: 'required!' }]}
          >
          <Select
                showSearch
                  placeholder="Select Locality"
                  optionFilterProp="children"
                  onChange={onChangeLocality}
                  filterOption={(input, option:any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } 
                  disabled={localityInfo.length <1 ? true : false}
                >
                  {
                      localityInfo.map((item, i)=>{                         
                      return <option key={i} value={item.id} >{item.locality}</option>
                  })
                  }

             </Select>
                </Form.Item>
        </Col>
      

      <Col span={12}>
 
      <Form.Item
        label={<p className="text-left m-0 ">Phone Number</p>}
        name="phone_no"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>
          </Col>
      
      <Col span={12}>
      <Form.Item
            label={<p className="text-left m-0 ">Select Status</p>}
            name="status"
            rules={[{ required: true, message: 'required!' }]}
            >
            <Select
              placeholder="Select audiance"
              optionFilterProp="children"
              onChange={onChange}
            >
            <Option value="new">New</Option>
            <Option value="progress">In Progress</Option>
            <Option value="registered">Registered</Option>
            <Option value="rejected">Rejected</Option>
      
      </Select>
    </Form.Item>
        </Col>
      </Row>

<div className="mt-5">
      <Form.Item {...tailLayout} className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
</div>
</div>
</div>
    </Form>

    </div>
  );

  }

