import  React,{ useEffect,useState } from 'react'
import {motion} from 'framer-motion'
import {   useQuery } from 'react-query'
import key from '../../../key'
import { Table } from 'antd'
import {useFetchList} from '../../../api/services/backend'
import {Button,Input} from 'antd'
import {Link} from 'react-router-dom'
import ModalForm from '../../shared/modal'
import {PlusOutlined} from '@ant-design/icons'
import DataTable from './datatable'
import CreateUser from './createUser'
import ExcelBtn from './exportexcel'

interface Props {

    title? :string
}

 const User: React.FC<Props> = ({title}) => {



   const {data, isLoading, error} = useFetchList('user')
   console.log(data);
   const [visible, setVisible] = useState(false)
 
  

    return (
      
      <div className="p-5 rounded-lg bg-white" style={{border:'1px solid #e5e5e5'}}> 
          <div className="flex mb-5 items-center justify-between">    
           <div>
           <Link to="/dashboard/create-user">
            <Button onClick={()=>setVisible(true)}  type="primary" icon={<PlusOutlined />}>
         Add User</Button>
        </Link>
           </div>
           <ExcelBtn  data={ data && data.rows} />

        </div>
       
        <DataTable  isLoading={isLoading || isLoading} data={data} />
      
        {/* <ModalForm 
          isVisible={visible} 
          title={<h2 className=" font-semibold m-0" style={{color:"grey"}}>CREATE USER</h2>}
          footer={false}
          className=""
          width="40%"
          cancel={()=>setVisible(!visible)}>
            
            <CreateUser/>
            
             </ModalForm>  */}

     </div>



    )
}

export default User


