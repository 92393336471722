import  React,{ useEffect,useState } from 'react'
import {motion} from 'framer-motion'
import {   useQuery } from 'react-query'
import key from '../../../key'
import { Table } from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {useFetchList} from '../../../api/services/backend'
import {Button,Input,Select,Tooltip} from 'antd'
import {Link} from 'react-router-dom'
import {PlusOutlined,SearchOutlined,SyncOutlined} from '@ant-design/icons'
import DataTable from './datatable'
import ExcelBtn from './exportexcel'
import styled from 'styled-components'
import { useDebounce } from "use-debounce";
import axios from 'axios'

import { selectAuth,fethFilter} from '../../../api/authSlice'

interface Props {

    title? :string
}

 const Former: React.FC<Props> = ({title}) => {
 
   const {data, isLoading} = useFetchList('farmer')   
   const [search, setSearch] = useState('')
   const [loading, setLoading] = useState(false)
   const dispatch = useDispatch()
  //  const {filter} = useSelector(selectAuth)
   const [debouncedText] = useDebounce(search, 2000);
   const [district,setDistrict] = useState<any>(null)
   const [pincode,setPincode] = useState()
   const [filterfarmer,setFilterfarmer] = useState(null)
   console.log({data});
  //  console.log({filter});
   const [filter,setFilter]=useState('')
  
   useEffect(()=>{
      axios.get(key.BACK_ENDURL + `/farmer?search=${debouncedText}`).then(({data})=>{
      console.log({data})
      setFilter(data)
       })
  setLoading(false)
   }, [dispatch, debouncedText])

 
 
  useEffect(()=>{
    dispatch(fethFilter('farmer', debouncedText ))
  setLoading(false)
   }, [dispatch, debouncedText])



 useEffect(()=>{     
  if(filter.length < 1) {
    setSearch('')
  }
   }, [filter])


const onSearch = (e) => {
  setLoading(true)
  setSearch(e.target.value)

}



const onChangDistrict = (value) =>{
  setDistrict(value)
  setLoading(true)
  axios.get(key.BACK_ENDURL + `/farmer?district=${value}`).then(({data})=>{

   setFilter(data)
   data && setLoading(false)
    })

}


// const onChangPincode = (value) =>{
//   setPincode(value)
//   axios.get(key.BACK_ENDURL + `/farmer?pincode=${value}`).then(({data})=>{

//    console.log({data})
//     })
    
// }

                      
  
let far =  data && data.farmer.map((item, i)=>{      

  return item.farmer_info.address
   
})

    console.log({far});

    let unique = far && far.filter((thing, index, self) =>
    index === self.findIndex((t) => (
      t.district === thing.district
    )
    )
    )
    console.log({unique});



const reset=()=>{
  setFilter('')
  setDistrict(null)
  setSearch('')
//  dispatch(fetchContent( user_id,null, null))
} 

    return (
      
      <div className="p-5 rounded-lg bg-white" style={{border:'1px solid #e5e5e5'}}> 

          <div className="flex mb-5 items-center justify-between">    
           <div>
            <Link to="/dashboard/create-farmer">
          <Button type="primary" >Add Farmer</Button>
          </Link>

          <Select
          
                placeholder="district"
                onChange={onChangDistrict}
                style={{ width: 150 , marginLeft:'10px'}}
                value={district}
                
              >
                {         
                    unique && unique.map((item, i)=>{      
                      // console.log(item.farmer_info.address.district);
                      
                      return <option key={i} value={item.district}>{item.district}</option>
                       
                  })
                }
                              
           </Select>
           <Tooltip title="Clear Filter">
         <SyncOutlined
              className="text-xl shadow"
              style={{  marginLeft: '10px',
              padding:"0.45rem", borderRadius:"4px",
              backgroundColor:"#3498db24",
              color:'var(--brandColor)'}} onClick={reset} />
        </Tooltip>

           
          {/* <Select
          
          placeholder="pincode    "
          onChange={onChangPincode}
          style={{ width: 150 , marginLeft:'10px'}}
          value={pincode}
          
        >
          {         
              data && data.farmer.map((item, i)=>{      
                // console.log(item.farmer_info.address.pincode);
                
                return <option key={i} value={item.farmer_info.address.pincode}>{item.farmer_info.address.pincode}</option>
                 
            })
          }
                        
     </Select>  */}
           </div>
   
      
                     
      <div className="flex  items-center justify-end">
          <SearchWrap className="mx-4 " style={{borderRadius:"4px"}}>

          <Input value={search}  className="px-4 py-2 focus:outline-none"
          prefix ={  <SearchOutlined  style={{color:'#3e79f7', fontWeight:'bold',}} />
          }
          placeholder="Search" onChange={onSearch}  />
          </SearchWrap>
    

           </div>
           {/* <ExcelBtn  data={ data?.farmer} /> */}
        </div>
             

  <DataTable  loading={loading || isLoading} 
    data={(filter.length>0) ? filter:data?.farmer} />
     </div>


    )
}

export default Former

const SearchWrap = styled.div`
width:100%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}

`
