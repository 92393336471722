import { Tabs } from 'antd'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useFetchList, useFetchOne } from '../../../api/services/backend';
import AgencyForm from './AgencyForm';
import ApartmentForm from './ApartmentForm';


export default function Tab() {
    const [apartment, setApartment] = useState() as any;
    const [agency, setAgency] = useState() as any;
    const { id } = useParams() as any;

    //   const {data, isLoading, error} = useFetchList('location')
    const { data, isLoading } = useFetchOne('apartment', id, {
        enabled: !!id
    })

    useEffect(() => {
        setApartment({
            name: data?.apartment?.apartment_info?.name,
            address: data?.apartment?.apartment_info?.address,
            // latitude: data?.apartment?.apartment_info?.latitude,
            // longitude: data?.apartment?.apartment_info?.longitude,
            city: data?.apartment?.city_id,
            zone: data?.apartment?.zone_id,
            pincode: data?.apartment?.pincode_id,
            locality: data?.apartment?.locality_id,
            total_houses: data?.apartment?.total_houses,
            block: data?.apartment?.apartment_info.block,
            sample: data?.apartment?.apartment_info.sample,
            // apartment_info: data?.apartment?.apartment_info
        })
        setAgency(data?.apartment?.agency)
    }, [data])



    const [activeTab, setActiveTab] = useState("1");
    return (
        <div className=" rounded-lg bg-white p-5 shadow-sm flex" style={{ height: '100%', border: '1px solid #e5e5e5' }}>
            {
                (id && isLoading)
                    ? <h1>Loading...</h1>
                    : <Tabs
                        activeKey={activeTab}
                        onChange={(key: any) => setActiveTab(key)}
                        items={[
                            {
                                label: "Apartment",
                                key: "1",
                                children: <ApartmentForm
                                    isEdit={data?.apartment}
                                    fdata={apartment}
                                    setData={setApartment}
                                    goNext={() => setActiveTab("2")}
                                />
                            },
                            {
                                label: "Agency",
                                key: "2",
                                // disabled:!apartment,
                                children: <AgencyForm
                                    isEdit={data?.apartment}
                                    fdata={agency}
                                    apartment_info={apartment}
                                />
                            }
                        ]}
                    />
            }

        </div>
    )
}
