import React, {useState} from 'react'
import { Table, Tag, Space, Button} from 'antd';
import {Link} from 'react-router-dom'
import { motion } from "framer-motion";
import styled from 'styled-components'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import DeleteConfirm from '../../shared/deleteConfirm'
import { useDelete} from '../../../api/services/backend'
import ModalForm from '../../shared/modal'
import {EyeFilled, IdcardFilled} from  '@ant-design/icons'
import ViewFarm from './viewFarm'
import ExcelBtn from './singleExcel'
import { idText } from 'typescript';
import { DownloadOutlined  } from '@ant-design/icons';
// import { useFetchOne} from '../../../../api/services/backend'


export default function Datatable({data, loading}) {

    const {mutate} = useDelete('farmer')
    const [visible, setVisible] = useState(false)
    const [farm, setFarm] = useState(null)
    const [farmer, setFarmer] = useState(null)
    const [farmerid ,setFartmerId] = useState(null)

    // console.log(data);

    
    const [page, setPage] = useState(1);

    const confirm = (e, id) => {
        mutate(id._id)
      }
      
      const cancel = (e) =>{
        return null
      }
      

      const handleClickView = (e, isvisible, item, farmer) =>{
        e.preventDefault()
        setFarm(item)
        setFarmer(farmer)
        setVisible(isvisible)
        }


const onClickdownload =(e,id) =>{
 console.log(id);
 
  return id

}


    const columns = [
      {
        title: 'Sl. No.',
        key: 'Sl.No.',
        render:(t, k, i)=>{
          return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
        }
      },
        {
          title: 'Name',
          dataIndex: 'farmer_info',
          key: 'farmer_info',
          render: text => <p className="m-0 capitalize  font-medium" style={{color:"#1A3353"}}>{text?.farmer_name}</p>,
        },
        {
          title: 'email',
          dataIndex: 'farmer_info',
          key: 'farmer_info.email',
          render: text => <p className="m-0">{text?.email}</p>
        },
        {
          title: 'Phone No.',
          dataIndex: 'ph_no',
          key: 'ph_no',
          render: text => <p className="m-0">{text}</p>,
        },
        {
          title: 'Pin No.',
          dataIndex: 'farmer_info',
          key: 'farmer_info',
          render: text => <p className="m-0">{text?.address?.pincode}</p>,
        },
        {
          title: 'District',
          dataIndex: 'farmer_info',
          key: 'farmer_info',
          render: text => <p className="m-0 capitalize">{text?.address?.district}</p>,
        },
        {
          title: 'Relationship Manager',
          dataIndex: 'rm',
          key: 'rm',
          render: text => <p className="m-0 capitalize">{text && text?.admin_name}</p>,
        },
        
        {
            title: 'Action',
            key: 'action',

            render: (id) => {
              
            return  <Space size="middle">

                        <Link to={`/dashboard/edit-farmer/${id._id}`}>         
                     <h5 style={{color: 'var(--brandColor' }} className="text-secondary  m-0 ">
                      <FaRegEdit />   
                      </h5> 
                      </Link>

                      <h5 className=" m-0 "  >                         
                        <DeleteConfirm  confirm={(e)=>confirm(e, id)} title="college" cancel={cancel} >
                            <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
                        </DeleteConfirm>                    
                    </h5>

                 
                    <h5 style={{color: 'var(--brandColor'  }} className="text-secondary  m-0 text-xl" onClick={(e)=>onClickdownload(e,id)}>
                    {/* <DownloadOutlined /> */}
                      {/* <ExcelBtn  data={farmerid} /> */}
                      </h5> 

                      {/* <ExcelBtn  onClick={(e)=>onClickdownload(e,id)} /> */}

              </Space>
            },
        },
     
      ];





      return (
        <DataTableWrap>

    <Table 
    loading={loading}
    columns={columns} 
    dataSource={data} 
    rowKey={(record)=>record._id}
    expandable={{
      expandedRowRender: record => {
     
        
       return <>{ record && <div className="grid grid-cols-2 text-left mt-3 text-sm" style={{width:'100%', lineHeight:"1.7",}}>
              <div className="mx-4 ">
                <div className="p-2 px-4 mb-4 rounded-md shadow-sm card" > 
                  <h6 className="underline"><b> Personal Details</b></h6>
                    <div className="ml-5 ">
                        <h6><b>Name : </b>&nbsp; {record.farmer_info.farmer_name || "null"}</h6> 
                        <h6><b>Phone No. : </b>&nbsp; {record.ph_no || "null"}</h6> 
                        <h6><b>Email : </b>&nbsp; {record.farmer_info.email || "null"}</h6> 
                        <h6><b>Address : </b>&nbsp; <span className=""> {record.farmer_info.address.address || "null"} </span></h6> 
                    </div>
                </div>


                <div className="p-2 px-4 mb-4 rounded-md shadow-sm card" > 
                    <h6 className=" underline"><b> Bank Details </b></h6>
                    <div className="ml-5 ">
                        <h6><b>Bank Name : </b>&nbsp; {record.bank_detail?.bank_name || "null"}</h6> 
                        <h6><b>Branch Name : </b>&nbsp;&nbsp; {record.bank_detail?.branch_name || "null"}</h6> 
                        <h6><b>Account No. : </b> {record.bank_detail?.account_no || "null"}</h6> 
                        <h6><b>IFSC No. : </b>&nbsp; {record.bank_detail?.IFSC_code || "null"}</h6> 
                    </div>
                  </div>

            
              </div> 
              <div className="mx-4 ">
                
                 {/* <div className="p-2 px-4 mb-4 rounded-md shadow-sm card" > 
                    <h6 className=" underline"><b> Bank Details </b></h6>
                    <div className="ml-5 ">
                        <h6><b>Bank Name : </b>&nbsp; {record.bank_detail.bank_name || "null"}</h6> 
                        <h6><b>Branch Name : </b>&nbsp;&nbsp; {record.bank_detail.branch_name || "null"}</h6> 
                        <h6><b>Account No. : </b> {record.bank_detail.account_no || "null"}</h6> 
                        <h6><b>IFSC No. : </b>&nbsp; {record.bank_detail.IFSC_code || "null"}</h6> 
                    </div>
                  </div> */}

                  <div className="p-2 px-4 mb-4 rounded-md shadow-sm card" > 
                    <h6 className="underline"><b>About Farms</b></h6>
                    <div className="ml-4 ">
                       {
                         record.farm_info.map((item)=>{
                           
                           return <> 
                           <h6> <b> Farm Name :</b>&nbsp; {item.farm_name}   
                           <span className="ml-3 text-gray-500 px-2 rounded cursor-pointer " style={{backgroundColor:"var(--brandColor)", color:'white'}} onClick={(e)=>handleClickView(e, true, item, record)} >view</span> 
                           </h6>  
                           <div className="text-sm ml-5">
                           {
                             record.crop_info.map((crop)=>{
                               return crop.farm === item.id ? <h6 > {crop.crop_name}</h6> :''
                              })
                            }
                            </div>
                           </>
                         })
                       }
                    </div>
                  </div>

                  <div className="p-2 px-4 mb-4 rounded-md shadow-sm card" > 
                    <h6 className=" underline"><b> Relationship Manager </b></h6>
                    <div className="ml-5 mb-5">
                        <h6><b>RM Name : </b>&nbsp; {record?.rm?.admin_name || "null"}</h6> 
                        <h6><b>RM Phone : </b>&nbsp; {record?.rm?.phone_no || "null"}</h6> 
                    </div>
                 </div>
              </div>
      </div>}
 </>
    }, 
      rowExpandable: record => record.address  !== null,
    }}
    />


<ModalForm 
          isVisible={visible} 
          title={<h2 className=" font-semibold m-0" style={{color:"grey"}}>ABOUT FARM</h2>}
          footer={false}
          className=""
          width="40%"
          cancel={()=>setVisible(!visible)}> 
          <ViewFarm current_farm={farm} current_farmer={farmer} cancel={()=>setVisible(!visible)}/>           
          
           </ModalForm> 
              
       </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;

.card{
  background-color: #ffffff;
  border: 1px solid #e5e5e5 ;
}


`