import  React,{ useEffect } from 'react'
import {  Tabs  } from 'antd';
import Userdetails from './userdetail'
import Password from './password'
// import Log from './log'
import {useDispatch, useSelector} from 'react-redux'


const { TabPane } = Tabs;

  export default function EditIndex() {

    const dispatch = useDispatch()
    
    function callback(key) {
        console.log(key);
      }

  return (

      <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
          <Tabs  onChange={callback}>
    <TabPane tab="Userdetails" key="1">
     <Userdetails/>
    </TabPane>
    <TabPane tab="Password" key="2">
     <Password/>
    </TabPane>
   
  
  </Tabs>
   </div>  
  );

  }