import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Geocode from 'react-geocode'
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import {getLatlang} from '../../api/authSlice'
import {useAppDispatch} from '../../api/hooks'

export default function Goolmap() {

    const [value, setValue] = useState(null) as any;
    const [location, setLocation] = useState(null) as any;

    const dispatch = useAppDispatch()

    Geocode.setApiKey("AIzaSyCOfT9SKL86PatALT1-Zi_3f6NXGkKZhHw");
    Geocode.setLanguage("en");
    useEffect(()=>{
        value && Geocode.fromAddress(value?.label).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;

              setLocation({lat:lat, lng:lng})

            },
            (error) => {
              console.error(error);
            }
          );
 
    }, [ value])

    useEffect(()=>{

        dispatch(getLatlang(location))

    }, [location])

    const MyMapComponent = withGoogleMap((props:any) =>
    <GoogleMap
      defaultZoom={16}
      defaultCenter={location}
    >
      {props.isMarkerShown && <Marker draggable onDragEnd={(e)=>setLocation({lat:e?.latLng?.lat(), lng:e?.latLng?.lng()})} position={location} />}
    </GoogleMap>
  )
  

  
    return (
        <div>
         
             <GooglePlacesAutocomplete
             apiKey="AIzaSyCOfT9SKL86PatALT1-Zi_3f6NXGkKZhHw"
        selectProps={{
          value,
          onChange: setValue,
        }}
      />
   <br/>
{location && <MyMapComponent
  isMarkerShown
  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
  loadingElement={<div style={{ height: `100%` }} />}
  containerElement={<div style={{ height: `300px` }} />}
  mapElement={<div style={{ height: `100%` }} />}
/>}
        </div>
    )
}