import React from 'react'
import { createSlice } from '@reduxjs/toolkit';



export const initialState = {

    loading: false,
    basic:false,
    farm:false,
    crop:false,
    bank:false,
    manager:false,
    activetab:"1",
    basic_data:null,
    farm_data:null,
    crop_data : null,
    bank_data : null,
    manager_data : null,

}

export const farmerSlice = createSlice({

    name:"farmer",
    initialState,
    reducers:{

        getBasic: state =>{

            state.loading = true
            state.basic = true
            state.activetab = "1"
        },

        getFarm: state =>{
            state.loading = true
            state.farm = true
            state.activetab = "2"

        },

        getCrop: state =>{

            state.loading = true
            state.basic = true
            state.farm = true
            state.crop = true
            state.activetab = "3"

        },

        getBank: state =>{

            state.loading = true
            state.basic = true
            state.farm = true
            state.crop = true
            state.bank = true
            state.activetab = "4"

        },

        getManager: state =>{

            state.loading = true
            state.basic = true
            state.farm = true
            state.crop = true
            state.bank = true
            state.manager = true
            state.activetab = "5"

        },

        getFinish: state =>{

            state.loading = true
            state.basic = true
            state.farm = false
            state.crop = false
            state.bank = false
            state.manager = false
            state.activetab = "1"

        },
     
        basicInfo: (state, {payload}) =>{ 
            console.log(payload);                    
            state.loading = true
            state.basic_data = payload
        },

        farmInfo: (state, {payload}) =>{
            console.log(payload);                    
            state.loading = true
            state.farm_data = payload
    
        },
        cropInfo: (state, {payload}) =>{
            console.log(payload);                    
            state.loading = true
            state.crop_data = payload
        },

        bankInfo: (state, {payload}) =>{
            state.loading = true
            state.bank_data = payload
    
        },
        managerInfo: (state, {payload}) =>{

            state.loading = true
            state.manager_data = payload
    
        },
    }
})

export const {
    getFarm, getBasic, getCrop,getBank, getManager, basicInfo, farmInfo,getFinish, cropInfo, bankInfo, managerInfo} = farmerSlice.actions

export const farmerSelector = state => state.farmer;



export const Pbasic = () => async dispatch =>{
try {
    dispatch(getBasic())  
} catch (error) {
   console.log(error);
}
}


export const Pfarm = () => async dispatch =>{
    try {
        dispatch(getFarm())       
    } catch (error) {
        
        console.log(error);
    }
}

export const Pcrop = () => async dispatch =>{
    try {
        dispatch(getCrop())       
    } catch (error) {       
        console.log(error);
    }
}

export const Pbank = () => async dispatch =>{
    try {
        dispatch(getBank())       
    } catch (error) {       
        console.log(error);
    }
}

export const Pmanager = () => async dispatch =>{
    try {
        dispatch(getManager())       
    } catch (error) {       
        console.log(error);
    }
}


export const pFinish = () => async dispatch =>{
    try {
        dispatch(getFinish())       
    } catch (error) {       
        console.log(error);
    }
}


export const getBasicInfo = (info) => async dispatch =>{
    try {
        dispatch(basicInfo(info))       
    } catch (error) {       
        console.log(error);
    }
}


export const getFarmInfo = (info) => async dispatch =>{
    console.log(info);
    try {
        dispatch(farmInfo(info))       
    } catch (error) {       
        console.log(error);
    }
}

export const getCropInfo = (info) => async dispatch =>{
    try {
        dispatch(cropInfo(info))      
    } catch (error) {       
        console.log(error);
    }
}

export const getBankInfo = (info) => async dispatch =>{
    try {
        dispatch(bankInfo(info))      
    } catch (error) {       
        console.log(error);
    }
}

export const getManagerInfo = (info) => async dispatch =>{
    try {
        dispatch(managerInfo(info))      
    } catch (error) {       
        console.log(error);
    }
}

export default farmerSlice.reducer
