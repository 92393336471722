import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button , Checkbox, DatePicker, InputNumber  } from 'antd';
import styled from 'styled-components'
import {useFetchList, useCreate, useFetchOne} from '../../../../api/services/backend'
import { advertisementSelector } from '../../../../api/services/advertisements'
import {  useEdit } from '../../../../api/services/backend'
import moment from 'moment'

const { RangePicker } = DatePicker;


    export default function Shop({ advertisement }) {

        const [form] = Form.useForm();
        const dispatch = useDispatch()
        const {mutate} = useEdit('advertisement')
        const [price, setPrice] = useState(0);
        const [discount, setDiscount] = useState(0);


        useEffect(()=>{
            setPrice(advertisement.basic_info && advertisement.basic_info.asking_price)  
            setDiscount(advertisement.shop_info && advertisement.shop_info.discount)
        
            advertisement && form.setFieldsValue({
              asking_price: advertisement.basic_info && advertisement.basic_info.asking_price,
              price_per_unit: advertisement.shop_info && advertisement.shop_info.price_per_unit,
              selling_price: advertisement.shop_info && advertisement.shop_info.selling_price,  
              discount: advertisement.shop_info && advertisement.shop_info.discount,
              discount_coupon: advertisement.shop_info && advertisement.shop_info.discount_coupon,
              delivey_date: advertisement.shop_info && moment(advertisement.shop_info.delivey_date),

              });
        }, [advertisement])
  


        const onFinish = (values:any) => {
            const data = {               
                shop_info : {
                    price_per_unit: values.price_per_unit ,
                    selling_price: values.selling_price,  
                    discount: values.discount ,
                    discount_coupon: values.discount_coupon,                      
                    delivey_date: values.delivey_date,
                } ,
                id: advertisement._id 
            }
            mutate(data)
        }

        
        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
          };

     
          const onChangeDate = ((date, dateString) => {
            console.log(date, dateString);
          })

          
          const onChangePrice =(e)=>{
            setPrice(e)
            const sp  = Math.floor((e *((100- discount )/(100))))
                form.setFieldsValue({
                selling_price: sp,
                })
          }

          const onChangeDiscount =(e)=>{
            setDiscount(e)
            const sp  = Math.floor((price *((100- e )/(100))))
             form.setFieldsValue({
                selling_price: sp,
                })
          }



    return (
      <FormWrap> 
        <div className="mt-5 mx-5">
            <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
             >
            <div className="grid grid-cols-2 gap-x-20">
            <div className="col-span-1">
      
            <Form.Item
                    label={<p className="text-left m-0">Farmer Price per unit</p>}
                    name="asking_price"
                    // rules={[{ required: true, message: 'required!' }]}
                >
                    <InputNumber disabled={true} />
            </Form.Item>

            <Form.Item
                label={<p className="text-left m-0 ">Price per unit</p>}
                name="price_per_unit"
                rules={[{ required: true, message: 'required!' }]}
            >
                <InputNumber  style={{width:'100%'}} onChange={onChangePrice}/>
            </Form.Item>   
 

            <Form.Item
                label={<p className="text-left m-0 ">Discount %</p>}
                name="discount"
                rules={[{ required: true, message: 'required!' }]}
            >
                <InputNumber style={{width:'100%'}} onChange={onChangeDiscount}/>
            </Form.Item>  

            <Form.Item
                label={<p className="text-left m-0 ">Selling Price</p>}
                name="selling_price"
                rules={[{ required: true, message: 'required!' }]}
            >
                <InputNumber  disabled={true} style={{width:'100%'}}/>
            </Form.Item>   

          
             
            <Form.Item
                label={<p className="text-left m-0 ">Create discount coupon</p>}
                name="discount_coupon"
                rules={[{ required: true, message: 'required!' }]}
            >
                <Input  style={{width:'100%'}}/>
            </Form.Item>            

            <Form.Item
                label={<p className="text-left m-0 ">Delivery Date</p>}
                name="delivey_date"
                rules={[{ required: true, message: 'required!' }]}
            >
                    <DatePicker  onChange={onChangeDate} />
            </Form.Item>   
           
            <div className="mt-10">
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                    </Form.Item>
                </div>
    </div>


                
            </div>
          </Form>
     </div>
     </FormWrap>
    )
}

const FormWrap = styled.div`



`