/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react'
import {useFetchList, useEdit, useFetchOne} from '../../../../api/services/backend'
import { Form, Input, Button, Upload ,Image, Switch,Select, DatePicker,Checkbox, Row, Col, InputNumber, Space} from 'antd';
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {MdClose, MdDelete} from 'react-icons/md'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import Loader from '../../../shared/loader';
import storage from '../../../shared/storage'
import styled from 'styled-components'
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const layout = {
    labelCol: { span: 24},
    wrapperCol: { span: 24 },
  }
  
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    const layoutcheck = {
      labelCol: { span: 20},
      wrapperCol: { span: 4 },
    }

  export default function EditProduct() {


  const {mutate} = useEdit('product')

  const [imgurl, setImgurl] = useState<any>([])
  const [loading1, setLoading1] = useState(false)
  const [fileList, setFileList] = useState([])
  const [unit, setUnit] = useState(null)

  const {id} = useParams()

  const [form] = Form.useForm();

  const {data:products} = useFetchList('product')
  const {data:category} = useFetchList('category')
  const {data} = useFetchOne('product', id)

  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [sellingPrice, srtSellingPrice] = useState(0);
  const [subscribedMonth, setSubscribedMonth] = useState() as any;


  useEffect(()=>{
    data && setImgurl([...data?.product.product_info?.image])
    data && setUnit(data?.product.product_info?.units)
  data && console.log({edite:data});
 data && setSubscribedMonth(data?.product?.subscriptionDetails?.subscriptionMonths
 )
    setPrice(data?.product?.product_info?.cost)  
    setDiscount(data?.product?.product_info?.discount)

   form.setFieldsValue({
       
        category:  data?.product?.category_id?._id,
        product_name:data?.product?.product_info?.product_name,
        desc:data?.product?.product_info?.desc,
        // product_id: data?.product?.product_info?.product_id,
        qty: data?.product?.product_info?.qty,
        units: data?.product?.product_info?.units,
        qty_per_box: data?.product?.product_info?.qty_per_box,
        units_per_box: data?.product?.product_info?.units_per_box,
        expiry_date:  moment(data?.product?.product_info?.expiry_date),
        discount: data?.product?.product_info?.discount,
        gst: data?.product?.product_info?.gst,
                sgst: data?.product?.product_info?.sgst,
        hsn_code: data?.product?.product_info?.hsn_code,

        cost: data?.product?.product_info?.cost,
        selling_price: data?.product?.product_info?.selling_price,
        date_of_procurement: moment(data?.product?.product_info?.date_of_procurement),
        todays_deal:data?.product?.todays_deal || false,
        forever_spacial:data?.product?.forever_spacial || false,

        origin:data?.product?.product_info?.origin || '',
        priority: data?.product?.priority === 20 ? 'No Priority' :  data?.product?.priority,
        
         isSubscribed:data?.product?.subscriptionDetails?.isSubscribed || false,

        subscriptionMonths: data?.product?.subscriptionDetails?.subscriptionMonths

      })
}, [data])




  const onFinish = (values: any) => {

        const productdata = {
            product_info: {
                category:values.category,
                product_name: values.product_name,
                desc:values.desc,
                qty:values.qty,
                units:values.units,
                qty_per_box: values.qty_per_box ? values.qty_per_box : null,
                units_per_box: values.units_per_box ? values.units_per_box : null,
                expiry_date: values.expiry_date,
                gst: values.gst,
                sgst: values.sgst,
                hsn_code: values.hsn_code,
                cost:values.cost,
                discount: values.discount ,
                selling_price:values.selling_price,
                date_of_procurement:values.date_of_procurement,
                image: imgurl,
                origin:values.origin

             },
             category_id:values.category,
             todays_deal:values.todays_deal,
             forever_spacial:values.forever_spacial,

             subscriptionDetails: {

              isSubscribed: values.isSubscribed,
              subscriptionMonths: values.subscriptionMonths
             }, 
            //  hasPriority:values.hasPriority,
             priority:values.priority === 'No Priority' ? 20 : values.priority,
             id:data.product._id
       }    
        console.log(imgurl);
      mutate(productdata)

  };


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  function onChangeUnit(value) {
    console.log(`selected ${value}`);
    setUnit(value)
  }

    function onChange(value) {
      console.log(`selected ${value}`);
    }

  const handleFarmSelect = (value) =>{
    console.log(value);
  }
  
  const onChangePriority = (value) =>{
    console.log(value);
  }


  const onChangePrice =(e)=>{
    setPrice(e)
    const sp  = Math.floor((e *((100- discount )/(100))))
        form.setFieldsValue({
        selling_price: sp,
        })
  }
  
  
  const onChangeDiscount =(e)=>{
    console.log(e);
    
    setDiscount(e)
    const sp  = Math.floor((price *((100- e )/(100))))

    console.log(sp);
    
     form.setFieldsValue({
        selling_price: sp,
        })
  }
  

  const handleChange = info => {
    setLoading1(true)
       
        storage
        .ref("images/" + info.file.name)
        .put(info.file.originFileObj)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL();
        })
        .then(url => {
          console.log(url);
          setImgurl([...imgurl, url])
          setLoading1(false)
    
        })
        .catch(error => {
          console.log(error);
        });
    
      };
    

      const remove = (e, url) =>{

        setImgurl(prev => prev.filter(item => item !== url))
        
        }

        const uploadButton = (
            <div>
              { loading1 ? <LoadingOutlined  /> : <PlusOutlined />}
              <div style={{ marginTop: 8, fontSize:"14px" }}>{loading1 ? "uploading" :""}</div>
            </div>
          );
        

          const onChangedeal=(value)=>{
            console.log(`selected ${value}`)
            // setChecked(value)
          }

   const handleSubscribeChange=(value)=>{

    

            console.log(`selected ${value}`)
            // setChecked(value)
          }

          

  return (
    <FormWrap>
   
  <div  className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>

   
<Form
    {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="grid grid-cols-3  mt-6 mx-4 "> 

       <div  className="col-span-2">
      <Form.Item
        label={<p className="text-left m-0 ">Category Type</p>}
        name="category"
        // rules={[{ required: true, message: 'required' }]}
        >
      <Select 
      showSearch
      placeholder="Category Name" 
      filterOption={(input, option:any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }  
      onChange={handleFarmSelect}>
          {
                  category && category.category.map((item, i)=>{     
                                        
                  return <option key={i} value={item._id} >{item.category_info.category_name}</option>

              })
            }
   
   
  </Select>
</Form.Item>

<Form.Item
        label={<p className="text-left m-0 ">Product Name</p>}
        name="product_name"
        rules={[{ required: true, message: 'required' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>
      <Form.Item
        label={<p className="text-left m-0 ">Product Description</p>}
        name="desc"
        
        rules={[{ required: true, message: 'required' }]}
      >
      <TextArea style={{width:'100%'}} rows={6} maxLength={960} />

      </Form.Item>
      <Form.Item
            label={<p className="text-left m-0 ml-2 ">Image</p>}
            name="image"
            // rules={[{ required: true, message: 'required!' }]}
          >
                <div 
                    className=" grid grid-cols-4 gap-5">
                {

                imgurl.map((img, i)=>{

                    return <div className=" imglist  bg-gray-50 text-center" style={{height:"100px"}}>
                    {/* <img key={i} className="  rounded col-span-1  block  object-cover" style={{height:"100%", width:"100%"}} src={img} alt="hj"   /> */}
                                         
                    <Image    
                     preview={false}               
                     key={i}
                     className="  rounded col-span-1  block  object-cover"
                     style={{height:"100%", width:"100%"}}
                     src={img}
                     placeholder={<Loader/> }              
                   />   
                    <h2 onClick={(e)=>remove(e, img)} className="p-1 text-white  text-xl "> <MdDelete/></h2>

                    </div>
                })
                }

                <Upload       
                        listType="picture-card"
                        fileList={fileList}
                        maxCount={3}

                        onChange={handleChange}
                        multiple={true}
                        className="ml-2"
                        >
                        {imgurl.length >= 3 ? null : uploadButton}
                        </Upload>
                </div> 


        </Form.Item>

        <Form.Item
        label={<p className="text-left m-0 ">Origin</p>}
        name="origin"
        
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>
        <Row gutter={20}>

<Col span={12}>
      <Form.Item
        label={<p className="text-left m-0  ">Quantity</p>}
        name="qty"
        rules={[{ required: true, message: 'required' }]}
      >
        <InputNumber  style={{width:'100%'}}/>
      </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
        label={<p className="text-left m-0  ">Units</p>}
        name="units"
        rules={[{ required: true, message: 'required' }]}
      >
        <Select
              placeholder="Select Units"
              optionFilterProp="children"
              onChange={onChangeUnit}
            >
                <Option value="kg">Kilograms</Option>
                <Option value="box">Box</Option>
                <Option value="bag">Bag</Option>
                <Option value="pack">Pack</Option>
                <Option value="bottle">Bottle</Option>
                <Option value="li">Liters</Option>
       </Select>
      </Form.Item>
           </Col>

{ (unit==='box' || unit==='bag' || unit==='pack' || unit==='bottle') && <>
      <Col span={12}>
      <Form.Item
        label={<p className="text-left m-0  ">Quantity per {unit}</p>}
        name="qty_per_box"
        rules={[{ required: true, message: 'required' }]}
      >
        <InputNumber  style={{width:'100%'}}/>
      </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
        label={<p className="text-left m-0">Units per {unit}</p>}
        name="units_per_box"
        rules={[{ required: true, message: 'required' }]}
      >
      <Select
              placeholder="Select Units"
              optionFilterProp="children"
              onChange={onChange}
            >
             <Option value="g">Grams</Option>
             <Option value="kg">Kilograms</Option>
             <Option value="li">Liters</Option>
             <Option value="ml">Millilitre</Option>
                </Select>
      </Form.Item>
          </Col>
 </>
}

       <Col span={12}>
          <Form.Item
            label={<p className="text-left m-0 ">Cost Price per <span className="lowercase" >{unit}</span></p>}
            name="cost"
            rules={[{ required: true, message: 'required' }]}
          >
            <InputNumber style={{width:'100%'}} onChange={onChangePrice}/>
          </Form.Item>

          <Form.Item
                label={<p className="text-left m-0 ">HSN Code</p>}
                name="hsn_code"
                rules={[{ required: true, message: 'required!' }]}
            >
                <Input style={{width:'100%'}} />
           </Form.Item> 
   
          </Col> 


          <Col span={12}>
           <Form.Item
                label={<p className="text-left m-0 ">GST %</p>}
                name="gst"
                rules={[{ required: true, message: 'required!' }]}
            >
                <InputNumber style={{width:'100%'}} />
           </Form.Item> 
           <Form.Item
                label={<p className="text-left m-0 ">SGST %</p>}
                name="sgst"
                rules={[{ required: true, message: 'required!' }]}
            >
                <InputNumber style={{width:'100%'}} />
           </Form.Item> 

         
            </Col> 

          <Col span={12}>
           <Form.Item
                label={<p className="text-left m-0 ">Discount %</p>}
                name="discount"
                rules={[{ required: true, message: 'required!' }]}
            >
                <InputNumber style={{width:'100%'}} onChange={onChangeDiscount}/>
           </Form.Item> 
            </Col> 


      <Col span={12}>     
        <Form.Item
            label={<p className="text-left m-0   ">Selling Price</p>}
            name="selling_price"
            rules={[{ required: true, message: 'required' }]}
          >
            <InputNumber disabled={true} style={{width:'100%'}} />
          </Form.Item>
        </Col>
      
      <Col span={12}>
 
      <Form.Item
            label={<p className="text-left m-0   ">Expiry date</p>}
            name="expiry_date"
            rules={[{ required: true, message: 'required' }]}
         >
          <DatePicker style={{width:'100%'}}  />
        </Form.Item>
          </Col>
      
      <Col span={12}>
          <Form.Item 
         label={<p className="text-left m-0   ">Date of Procurement</p>}
         name="date_of_procurement"
         rules={[{ required: true, message: 'required' }]}
         >
          <DatePicker style={{width:'100%'}}  />
        </Form.Item>
        </Col>
  

        <Col span={12}>
              <Form.Item
              label={<p className="text-left m-0">Priority</p>}
              name="priority"
              rules={[{ required: true, message: 'required' }]}
            >
            <Select
                    placeholder="Select Priority"
                    optionFilterProp="children"
                    onChange={onChangePriority}
                  >
                    {                      
                      ['No Priority',1,2,3,4,5,6,7,8].map((item, i)=>{   

                      const  isthere =  products?.product.findIndex(prd => prd?.priority === item)
                      
                      return <>{ isthere === -1 && <Option value={item}  key={i}>{item}</Option> }</> 
                    })
                  }
        
              </Select>
            </Form.Item>
        </Col>


      <Col className='mt-9' span={6}>
        <Form.Item 
          label= {<p className=" text-left m-0  w-full">Todays deal</p>} 
          name="todays_deal" 
          valuePropName="checked"
          {...layoutcheck}
          >
        
   <Checkbox/>
          </Form.Item>
      </Col>
 <Col className='mt-9' span={6}>
        <Form.Item 
          label= {<p className=" text-left m-0  w-full">forever Spacial</p>} 
          name="forever_spacial" 
          valuePropName="checked"
          {...layoutcheck}
          >
        
   <Checkbox/>
          </Form.Item>
      </Col>



      </Row>

      <Row gutter={20}>


      <Col className='mt-9' span={6}>
        <Form.Item 
          label= {<p className=" text-left m-0  ">Subscribe</p>} 
          name="isSubscribed" 
          valuePropName="checked"
          {...layoutcheck}
          >
        
          <Checkbox/>
          </Form.Item>

         

      </Col>

      <Col  span={18}  className='mt-9'>

<Form.List name="subscriptionMonths">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{
                display: 'flex',
                marginBottom: 24,
              }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                label="months"
                name={[name, 'months']}
                rules={[
                  {
                    required: true,
                    message: 'months',
                  },
                ]}
              >
                <InputNumber placeholder="months" />
              </Form.Item>
              <Form.Item
                {...restField}
                label="subscription discount"

                name={[name, 'sub_discount']}
                rules={[
                  {
                    required: true,
                    message: 'subscription discount',
                  },
                ]}
              >
                <InputNumber placeholder="subscription discount" />
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
        
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Subscribe Details
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>


</Col>












      </Row>



<div className="mt-5">
      <Form.Item {...tailLayout} className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
</div>
</div>
</div>

    </Form>

    </div>
 </FormWrap>

 )
}

const FormWrap = styled.div`
/* position: relative; */

.imglist {
overflow: hidden;

h2 { opacity : 0;
display:none;
position: absolute;
margin: 0;
bottom: 0%;
background-color: rgba(0,0,0,0.5);
cursor: pointer;
transition: 0.1s;

&:hover {

font-size: 1.5rem;
}

}

&:hover h2 {

opacity : 1;
display:block
}
}

`