/* eslint-disable no-unreachable */
import  React,{ useEffect,useState } from 'react'
import {motion} from 'framer-motion'
import {   useQuery } from 'react-query'
import key from '../../../key'
import { Table } from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {useFetchList} from '../../../api/services/backend'
import {Button,Input,Select,Tooltip} from 'antd'
import {Link} from 'react-router-dom'
import {PlusOutlined,SearchOutlined,SyncOutlined} from '@ant-design/icons'
import DataTable from './datatable'
import { useDebounce } from "use-debounce";
import { selectAuth,fethFilter} from '../../../api/authSlice'
import axios from 'axios'
import {useParams} from 'react-router-dom'
// import CreateAdvertisement from './createAdvertisement'
import ExcelBtn from './exportexcel'
import styled from 'styled-components'
interface Props {

    title? :string
}


 const Region: React.FC<Props> = ({title}) => {

   const {data, isLoading} = useFetchList('advertisement')
   const [search, setSearch] = useState('')
   const [crop,setCrop] = useState<any>(null)
   const [loading, setLoading] = useState(false)
   const dispatch = useDispatch()
  //  const {filter} = useSelector(selectAuth)
   const [debouncedText] = useDebounce(search, 2000);
   const [filter,setFilter]=useState('')
   
   console.log({filter});
   
  const onChangCrop = (value) =>{
    setCrop(value)
    setLoading(true)
    axios.get(key.BACK_ENDURL + `/advertisement?crop=${value}`).then(({data})=>{

      setFilter(data)
      data && setLoading(false)
      })
  }

  useEffect(()=>{
    // dispatch(fethFilter('seasonal-category', debouncedText ))
    axios.get(key.BACK_ENDURL + `/advertisement?search=${debouncedText}`).then(({data})=>{

      console.log({data})
      setFilter(data)
       })
  setLoading(false)
   }, [dispatch, debouncedText])

   

  useEffect(()=>{
    dispatch(fethFilter('advertisement', debouncedText ))
  setLoading(false)
   }, [dispatch, debouncedText])



 useEffect(()=>{     
  if(filter.length < 1) {
    setSearch('')
  }
 }, [filter])


const onSearch = (e) => {
  setLoading(true)
  setSearch(e.target.value)

}
 
                      
  
let adver =  data && data.advertisement.map((item, i)=>{      

    return item.farmer.crop_info[0]
     
})

let unique = adver && adver.filter((thing, index, self) =>
  index === self.findIndex((t) => (
    t.crop_name === thing.crop_name
)
)
)

console.log({unique});

const reset=()=>{
  setFilter('')
  setCrop(null)
  setSearch('')
//  dispatch(fetchContent( user_id,null, null))
} 

    return (
      
      <div className="p-5 rounded-lg bg-white" style={{border:'1px solid #e5e5e5'}}> 
          <div className="flex mb-5 items-center justify-between">    
        
           <div>
           
           <Link to="/dashboard/create-advertisement">
            <Button  type="primary" icon={<PlusOutlined />}>Add New</Button>
          </Link>
         
          <Select
                placeholder="crop"
                onChange={onChangCrop}
                style={{ width: 150 , marginLeft:'10px'}}
                value={crop}
                
              >
                {   
                      
                 unique && unique.map((item, i)=>{      
                    
                      return <option key={i} value={item.id}>{item.crop_name}</option>
                       
                  })
                }
                              
           </Select>
           <Tooltip title="Clear Filter">
         <SyncOutlined
              className="text-xl shadow"
              style={{  marginLeft: '10px',
              padding:"0.45rem", borderRadius:"4px",
              backgroundColor:"#3498db24",
              color:'var(--brandColor)'}} onClick={reset} />
        </Tooltip>
          
           </div>

           <div >
          <SearchWrap className="mx-4  " style={{borderRadius:"4px"}}>

          <Input value={search}  className="px-4 py-2 focus:outline-none"
          prefix ={  <SearchOutlined  style={{color:'#3e79f7', fontWeight:'bold'}} />
          }
          placeholder="Search" onChange={onSearch}  />
          </SearchWrap>

      
          </div>

           {/* <ExcelBtn data={data && data.result} /> */}

        </div>
       
        <DataTable  isloading={loading || isLoading} 
         data={(filter.length> 0) ? filter :data?.advertisement}/>
      
     </div>

    )
}

export default Region


const SearchWrap = styled.div`
width:100%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}
`
