import  React,{ useEffect } from 'react'
import {  Tabs  } from 'antd';
import Basic from './baiscs'
import Media from './media'
import Checklist from './checklist'
import Shop from './shop'
import {useDispatch, useSelector} from 'react-redux'

import { Pbasic, PMedia, Pchecklist, Pshop, pFinish, getBasicInfo, getMediaInfo, getChecklistInfo, getShopInfo, advertisementSelector } from '../../../../api/services/advertisements'

const { TabPane } = Tabs;

    export default function Ads() {

      const dispatch = useDispatch()
      const {   activetab, basic, media, checklist, shop } = useSelector(advertisementSelector)
     
      const ontab = (k, e) =>{
        e.preventDefault();
          if(k === "2"){
              dispatch(PMedia());      
          } else if(k === "3"){       
            dispatch(Pchecklist());
          }else if(k === "4"){       
            dispatch(Pshop());
          } else {      
            dispatch(Pbasic());
          }   
        }

    return (
      
      <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
       <Tabs           
            activeKey={activetab}             
              onTabClick={(k, e)=>ontab(k, e)}
            >    
              
              <TabPane tab="Basic" key="1">
              <Basic info={getBasicInfo} actionMethod={ PMedia} />
              </TabPane>

              <TabPane tab="Media" disabled = {media ? false : true} key="2">
                  <Media info={getMediaInfo} actionMethod={ Pchecklist}/>
             </TabPane>
             
            <TabPane tab="Checklist" disabled = {checklist ? false : true} key="3">
                  <Checklist info={getChecklistInfo}  actionMethod={Pshop} />
            </TabPane>

            <TabPane tab="Shop" disabled = {shop ? false : true}  key="4">
                  <Shop actionMethod={pFinish} />
            </TabPane>
        
    </Tabs>
     </div>

    )
}

