import React, {useEffect, useState} from 'react'
import {useFetchList, useEdit, useFetchOne} from '../../../api/services/backend'
import { Form, Input, Button, InputNumber, Row, Col, Select, DatePicker} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {MdClose} from 'react-icons/md'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import { current } from '@reduxjs/toolkit';

const { Option } = Select;

const layout = {
    labelCol: { span: 24},
    wrapperCol: { span: 24 },
  }
  
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

  export default function EditProduct() {


  const {mutate} = useEdit('seasonal-category')


  const [loading1, setLoading1] = useState(false)
  const [fileList, setFileList] = useState([])
  const {id} = useParams()

  const [form] = Form.useForm();


  const {data} = useFetchOne('seasonal-category', id)

  const [unit, setUnit] = useState(null)
  

  useEffect(()=>{
    setUnit(data?.category?.units)

    data && form.setFieldsValue({
       
        category: data?.category?.category,
        resale_days:data?.category?.resale_days,
        estimate_qty:data?.category?.estimate_qty,
        converstion_rate:data?.category?.converstion_rate,
        units: data?.category?.units,
        unitOne: data?.category?.unitOne,
        unitTwo: data?.category?.unitTwo,
        unitThree: data?.category?.unitThree,
      })
}, [data])




  const onFinish = (values: any) => {
        console.log(values);

        const categorydata = {
                category:values.category,
                resale_days:values.resale_days,
                estimate_qty:values.estimate_qty,
                converstion_rate:values.converstion_rate,
                units: values.units,
                unitOne:unit==='whole'?`Small`: values.unitOne,
                unitTwo: unit==='whole'?`Medium`:values.unitTwo,
                unitThree: unit==='whole'?`Large`:values.unitThree,

                id:data?.category?._id       
              
       }
       
  mutate(categorydata)
 
  };


  function onChangeUnit(value) {
    setUnit(value)
    if(value === 'whole'){
      form.setFieldsValue({
        unitOne: 'small',
        unitTwo: 'medium',
        unitThree :'large'
        })
    }
    else{
      form.resetFields(['unitOne'])
      form.resetFields(['unitTwo'])
      form.resetFields(['unitThree'])
    }
  }
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeSelect = (value) =>{
    console.log(value);
}


const handleEstimateSelect = (value) =>{
}


const handleconverstionSelect = (value) =>{
}

  
return (
   
    <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>

   
    <Form
    {...layout}
      name="basic"
      // labelCol={{ span: 4 }}
      // wrapperCol={{ span: 10 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
   <div className="grid grid-cols-2 gap-10 mt-6 mx-4 ">   
      <div >
      <Form.Item
        label={<p className="text-left m-0 ">Category Name</p>}
        name="category"
        rules={[{ required: true, message: 'required' }]}
      >
        <Input  style={{width:'100%'}} />
      </Form.Item>
  
  
      <Form.Item
        label={<p className="text-left m-0 ">Resale Days</p>}
        name="resale_days"
        rules={[{ required: true, message: 'required' }]}
      >
        <InputNumber  style={{width:'100%'}} />
      </Form.Item>

      <Form.Item
        label={<p className="text-left m-0 ">Estimate Quantity</p>}
        name="estimate_qty"
        rules={[{ required: true, message: 'required' }]} >

        
        <Select placeholder="Select Estimate Quantity"  style={{ width: "100%" }} onChange={handleconverstionSelect}>


        <option  value="1" >1</option>
        <option  value="2" >2</option>
        <option  value="3" >3</option>
        <option  value="4" >4</option>
        <option  value="5" >5</option>
        <option  value="6" >6</option>

   </Select>
      
      </Form.Item>

      <Form.Item
        label={<p className="text-left m-0 ">Converstion Rate</p>}
        name="converstion_rate"
        rules={[{ required: true, message: 'required' }]}
      >
       <Select placeholder="Select Converstion Rate"  style={{ width: "100%" }} onChange={handleEstimateSelect}>
            <option  value="10" >10</option>
            <option  value="20" >20</option>
            <option  value="30" >30</option>
            <option  value="40" >40</option>
            <option  value="50" >50</option>
            <option  value="60" >60</option>

      </Select>
      </Form.Item>
  
  
      <Form.Item
        label={<p className="text-left m-0  ">Units</p>}
        name="units"
        rules={[{ required: true, message: 'required' }]}
      >
        <Select
              placeholder="Select Units"
              optionFilterProp="children"
              onChange={onChangeUnit}
            >
                <Option value="kg">Kilograms</Option>
                <Option value="box">Box</Option>
                <Option value="piece">Pieces</Option>
                {/* <Option value="whole">Whole fruit</Option> */}
       </Select>
      </Form.Item>
  
      <Row gutter={20}>

      <Col span={8}>
        <Form.Item
          label={<p className="text-left m-0  ">Option 1</p>}
          name="unitOne"
          rules={[{ required: true, message: 'required' }]}
        >
          <Input  style={{width:'100%'}} disabled={unit==='whole'} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          label={<p className="text-left m-0  ">Option 2</p>}
          name="unitTwo"
          rules={[{ required: true, message: 'required' }]}
        >
          <Input  style={{width:'100%'}} disabled={unit==='whole'} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          label={<p className="text-left m-0  ">Option 3</p>}
          name="unitThree"
          rules={[{ required: true, message: 'required' }]}
        >
          <Input  style={{width:'100%'}} disabled={unit==='whole'} />
        </Form.Item>
      </Col>

</Row>
  
      <div className="mt-14">
      <Form.Item className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      </div> 
      </div>
   </div>

    </Form>

    </div>
  );

  }