import React, {useEffect, useState} from 'react'
import {useCreate, useFetchList} from '../../../../api/services/backend'
import { Form, Input, Button,message, InputNumber ,Checkbox, Select, Row, Col} from 'antd';
import {useParams} from 'react-router-dom'
import { useEdit, useFetchOne} from '../../../../api/services/backend'


const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}


  export default function EditLocality() {
    const {id} = useParams()

    const {mutate} = useEdit('location/city', 'location')

  const [form] = Form.useForm();
  const {data, isLoading, error} = useFetchList('location')
  const [cityInfo, setCityInfo] =  useState<any>(null)
  const [zoneInfo, setZoneInfo] =  useState<any>([])
  const [pincodeInfo, setPincodeInfo] =  useState<any>([])


  const localityOne = data && data.locality.find((item, i)=>{   
    return item.id === id 
    }) 

    

useEffect(()=>{
    const city = data && data.city.find((item, i)=>{   
        return item._id === localityOne.city 
        })  
    const zone = data && data.zones.find((item, i)=>{   
        return item.id === localityOne.zone 
        }) 

    const pincode = data && data.pins.find((item, i)=>{   
      return item.id === localityOne.pincode 
      }) 

      const pincodeArray = city?.pincode_info.filter((item, i)=> item.zone === localityOne.zone)

      setCityInfo(city)
      setPincodeInfo(pincodeArray)
      setZoneInfo(city?.zone_info ? city.zone_info : [])

      localityOne && form.setFieldsValue({  
      city:city?.city,
      zone:zone?.id,
      pincode:pincode?.id,
      locality:localityOne && localityOne.locality
      })
}, [localityOne])



  const onFinish = (values: any) => {
   
    const pincodeData = {
      city:values.city,
      element_id:id,
      zone:values.zone,
      pincode:values.pincode,
      info_type:"locality_info",
      info:"locality",
      value:values.locality,
      id:id
  }
  console.log(pincodeData);
  
  mutate(pincodeData)

}

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };



  const onChangeCity=(value)=> {
        form.resetFields(['zone'])
        form.resetFields(['pincode'])
        const cityFound = data && data.city.find((item, i)=>{   
       return item.city === value 
      
      })      
      setCityInfo(cityFound)
      setZoneInfo(cityFound.zone_info ? cityFound.zone_info : [])
      setPincodeInfo([])
  }


  const onChangeZone=(value)=> {
    form.resetFields(['pincode'])
    const pinsFound = cityInfo.pincode_info.filter((item, i)=>{   
      return item.zone === value 
  })
  setPincodeInfo(pinsFound)
}


    const onChangePincode=(value)=>{
     console.log(`selected ${value}`);
}


  return (
   
    <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
    <h1 className="mx-3 text-xl">Edit Pincode</h1>
  <div className=" mx-5 grid grid-cols-2 mt-12">

    <Form
    {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >   

<Form.Item
            label={<p className="text-left m-0 ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
            >
            <Select
                   showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    onChange={onChangeCity}
                    disabled={true}
                    filterOption={(input, option:any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   } 
                  >
             {
                  data && data.city.map((item, i)=>{     
                                        
                  return <option key={i} value={item.city} >{item.city}</option>

              })
              }
            </Select>
    </Form.Item>

    <Form.Item
            label={<p className="text-left m-0 ">Zone</p>}
            name="zone"
            rules={[{ required: true, message: 'required!' }]}
            >
            <Select
              showSearch
              placeholder="Select Zone"
              optionFilterProp="children"
              onChange={onChangeZone}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              // disabled={true}
            >
              {
                  zoneInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id}>{item.zone}</option>
              })
              }
      
      </Select>
    </Form.Item>
         
    <Form.Item
          label={<p className="text-left m-0  ">Pincode</p>}
          name="pincode"
           rules={[{ required: true, message: 'required!' }]}
         >
         <Select
             showSearch
              placeholder="Select Pincode"
              optionFilterProp="children"
              onChange={onChangePincode}
              filterOption={(input, option:any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
             } 
              // disabled={pincodeInfo.length <1 ? true : false}
            >
              {
                  pincodeInfo && pincodeInfo.map((item, i)=>{                         
                  return <option key={i} value={item.id} >{item.pincode}</option>
              })
              }
      
      </Select>
         </Form.Item>
      
          <Form.Item
            label={<p className="text-left m-0   ">Locality</p>}
            name="locality"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{width:'100%'}} />
          </Form.Item>

          <div className="mt-10">

            <Form.Item  className="text-right">
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
        </div>
   

    </Form>

    </div>
    </div>

  );

  }