import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {useDispatch,useSelector} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import {Link} from 'react-router-dom'
import { motion } from "framer-motion";
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'

export default function Datatable({data, loading}) {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const {mutate} = useDelete('customer')

  const handleClickEdit = (e, isvisible, id) =>{
    e.preventDefault()
   
    }
  
    const confirm = (e, id) => {
      mutate(id._id)
     
    }

    const cancel = (e) =>{
      return null
    }


  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },


    {
      title: 'Customer Name',
      dataIndex: 'customer_info',
      key: 'customer_info',
      render: text => <p className="m-0 capitalize  font-medium">{text?.customer_name}</p>,
    },

    {
      title: 'Phone No.',
      dataIndex: 'ph_no',
      key: 'ph_no',
      // render: text => <p className="m-0">{text.ph_no}</p>,
    },

    {
      title: 'Apartment Name',
      dataIndex: 'apartment_id',
      key: 'apartment_id',
      render: text => <p className="m-0">{text?.apartment_info?.name}</p>,
    },

    // {
    //   title: 'Pincode',
    //   dataIndex: 'apartment',
    //   key: 'apartment',
    //   render: text => {
    //           const pin = text.city_id?.pincode_info.find(item => item.id === text.pincode_id)
    //           console.log(pin);
              
    //      return <p className="m-0">{pin.pincode}</p>
    //     }
    // },
    
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
             {/* <Link to={`/dashboard/edit-user/${id._id}`}>
             <h5  style={{color: 'var(--brandColor' }}  className="text-secondary  m-0 ">
           <FaRegEdit />  
            </h5>
            </Link> */}

        <h5 className="m-0">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="customer" cancel={cancel} >
                <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },

     ]

     const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {


        setSelectionKey(selectedRowKeys)
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };


      return (
        <DataTableWrap>
    
    { 
        <Table 
      
      // rowSelection={{
      //   // type: selectionType,
      //   ...rowSelection,
      // }}   
  
      pagination={{
        onChange(current) {
          setPage(current)
        }
      }}

    loading={loading}
    columns={columns} 
    dataSource={data} 
    rowKey={(record)=>record._id}
    expandable={{
      expandedRowRender: record => {
console.log(record);

       return <div className="px-5 py-2 text-sm " style={{width:'100%', color:'grey'}}>
       
       <div className="text-left" style={{lineHeight:"1.7", letterSpacing:"0.03rem"}}>
       <div className="grid grid-cols-5 gap-2">  
     
       <div>
       <p><b>Email : </b> {record && record?.email || record?.Email}</p>
       <p><b>Phone Number : </b> {record && record.ph_no || "null"}</p>
       <p><b>Block  : </b> {record && record.customer_info?.address?.block_no || "null"}</p>
          </div>

         <div><p><b>House Number : </b> {record && record.customer_info?.address?.house_no || "null"}</p>
         <p><b>Apartment Address : </b> {record && record?.apartment_id?.apartment_info?.address || "null"}</p>
           </div>

          <div>
        
        <p><b>Longitude : </b> {record && record?.apartment_id?.apartment_info?.longitude || "null"}</p>
        <p><b>Latitude : </b> {record && record?.apartment_id?.apartment_info?.latitude || "null"}</p>
          </div>

          </div>                
      </div>
     </div>
    }, 
    rowExpandable: record => record.name  !== null,
  } }


    />

    }       
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;


`