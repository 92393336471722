import { Button } from 'antd';
import React from 'react'
import ReactExport from "react-export-excel";
import { DownloadOutlined  } from '@ant-design/icons';
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function ExportExcel({data}) {
     
    return (
        
      
        <ExcelFile filename="Apartment" element={<Button icon={<DownloadOutlined  style={{transform:"translateY(2px)" }}/>} className="mr-1" type="primary">Download</Button>}>
               
        <ExcelSheet data={data} name="Apartment" >
            <ExcelColumn label="Apartment Name" value={col => col.apartment_info ? col.apartment_info.name: "null"}/> 
            <ExcelColumn label="Address" value={col => col.apartment_info ? col.apartment_info.address: "null"}/> 

            <ExcelColumn label="State" value={col => col.apartment_info ? col.apartment_info.state: "null"}/> 
            <ExcelColumn label="City" value={col => col.city_id ? col.city_id.city: "null"}/>

            {/* <ExcelColumn label="Zone" value={col => col.zoneOne ? col.zoneOne.zone: "null"}/>  */}
            {/* <ExcelColumn label="Pincode" value={col => col.pincode_info ? col.pincode_info.pincode: "null"}/>  */}
             <ExcelColumn label="Longitude" value={col => col.apartment_info ? col.apartment_info.longitude: "null"}/> 
            <ExcelColumn label="Latitude" value={col => col.apartment_info ? col.apartment_info.latitude: "null"}/> 
        </ExcelSheet>
   
    </ExcelFile>
    )
}


