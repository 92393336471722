import { Button } from 'antd';
import React from 'react'
import ReactExport from "react-export-excel";
import { DownloadOutlined  } from '@ant-design/icons';
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function ExportExcel({data}) {
    console.log(data);
    
     
    return (
        
      
        <ExcelFile filename="Customer" element={<Button icon={<DownloadOutlined  style={{transform:"translateY(2px)" }}/>} className="mr-1" type="primary">Download</Button>}>
               
        <ExcelSheet data={data} name="Customer" >
            {/* <ExcelColumn label="Customer ID" value={col => col?.customer_info ? col?.customer_info?.customer_name: "null"}/>  */}
            <ExcelColumn label="Customer Name" value={col => col?.customer_info ? col?.customer_info?.customer_name: "null"}/> 
            <ExcelColumn label="Phone No." value={col => col?.ph_no ? col?.ph_no: "null"}/> 
                        <ExcelColumn label="Phone No." value={col => col?.email ? col?.email: col?.Email}/> 

            <ExcelColumn label="Apartment Name" value={col => col?.apartment_id ? col?.apartment_id?.apartment_info?.name: "null"}/> 

            {/* const pin = text.city_id?.pincode_info.find(item => item.id === text.pincode_id)
              console.log(pin);

            <ExcelColumn label="Pincode" value={col => col.city_id ? col.city_id.city: "null"}/> */}

        </ExcelSheet>
   
    </ExcelFile>
    )
}


