import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import DatatableSetings from './datatable'
import {Link} from 'react-router-dom'

export default function Testimonial() {



  return (
    <div>
        <div>
           <Link to="/dashboard/create-testimonial">
            <Button   type="primary" icon={<PlusOutlined />}>
         Add Testimonial</Button>
        </Link>
           </div>
           <br/>
        <DatatableSetings/>
    </div>
  )
}
