import { Table, Tag, Space, } from 'antd';
import styled from 'styled-components'
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import DeleteConfirm from '../../../shared/deleteConfirm'
import moment from 'moment'

export default function Datatable({data, isLoading, edit,farmer, deleteFarm }) {

  
  const confirm = (e, id) => {
    console.log(id._id);
    deleteFarm(id)
  }
  
  const cancel = (e) =>{
    return null
  }


  const columns = [
    {
      title: 'Crop Name',
      key: "crop_name",
      dataIndex: 'crop_name',
      render: text => <p className="m-0 capitalize">{text}</p>,
    },

  //   {
  //     farmer && farmer.farm_info.map((item, i)=>{     
                            
  //     return <option key={i} value={item.id} >{item.farm_name}</option>

  // })
  // }
  
    {
      title: 'Farm',
      key: "farm",
      dataIndex: 'farm',
      // render: text => <p className="m-0">{text}</p>,
      render: text =>{
        const newCrop = farmer && farmer.farm_info.filter((item, i)=>{   
          return item.id === text 
          })
          return <p className="m-0">{newCrop[0].farm_name}</p>
      }
    },
    {
      title: 'Quantity',
      key: "quantity",
      dataIndex: 'quantity',
      render: text => <p className="m-0">{text}</p>,
    },
    {
      title: 'Units',
      key: "units",
      dataIndex: 'units',
      render: text => <p className="m-0">{text}</p>,
    },
    {
      title: 'Harvesting Time',
      key: "harvesting_time",
      dataIndex: 'harvesting_time',
      render:(text)=>{
        return <p className="m-0 ">{moment(text[0]).format('DD/MM/YYYY')}  -  {moment(text[1]).format('DD/MM/YYYY')}</p>
    }
    },
    {
      title: 'Action',
      key: 'action',
      render: (id) => (
        <Space size="middle">      
               <h5  onClick={(e)=>edit(e, id)}  style={{color: 'var(--brandColor' }} className="text-secondary  m-0 ">
                <FaRegEdit />   
                </h5> 

                <h5 className="text-danger  m-0 "  >                         
                  <DeleteConfirm  confirm={(e)=>confirm(e, id)} title="college" cancel={cancel} >
                  <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
                  </DeleteConfirm>                    
              </h5>
        </Space>
    ),
  },
];



      return (
        <DataTableWrap>
               <div className=" p-4  rounded-md " style={{backgroundColor:'#ffffff', border:'0px solid #dadada'}}> 
    { 
       <Table 
       pagination={false}
    loading={isLoading}
    columns={columns} 
    rowKey={(record)=>record._id}
    dataSource={data} 
    />
    }            
</div>
       </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

position : relative;
margin-bottom: 30px;
/* min-height: 70vh; */


thead[class*="ant-table-thead"] th {
    background-color: #f0f0f0 !important;
  }

  .ant-table-tbody > tr > td {  
    background-color: #FAFAFA !important;
  }
`