/* eslint-disable @typescript-eslint/no-unused-vars */
import  React,{ useEffect,useState } from 'react'
import { useAppSelector} from '../../../api/hooks'
import { selectAuth } from '../../../api/authSlice'
import {useCreate, usePublish, useFetchList} from '../../../api/services/backend'
import {Button,Input, message} from 'antd'
import {Link} from 'react-router-dom'
import ModalForm from '../../shared/modal'
import {PlusOutlined} from '@ant-design/icons'
import DataTable from './datatable'
// import ExcelBtn from './exportexcel'
import CreateTarget from './createTarget'
import axios from 'axios'
import key from '../../../key'
interface Props {

    title? :string
}


 const User: React.FC<Props> = ({title}) => {

const {mutate} = usePublish('publish')
const {target_ads, current_ads, current_target, loading} = useAppSelector(selectAuth)
const [clear, setClear] = useState(false)
   
   
let current_customers = target_ads.map(item =>{  
 
  return item?.customer_data?.map(cust => cust?.customer)
})


  const handelApproved = ()=>{
    const data={
      apartments: target_ads,
      current_customers: current_customers.flat(),
      current_ads:current_ads,
      current_target:current_target
    }
    console.log(data);  
    mutate(data)
    axios.post(key.BACK_ENDURL + '/subscribe', data).then(({data})=>{
      console.log(data);      
    })
    setClear(true)
    message.success('Approved successfully')
    setTimeout(() => {
      window.location.reload()
    }, 1000);
    
  }


    return (
      
      <div className="p-5 rounded-lg bg-white" style={{border:'1px solid #e5e5e5'}}> 
          <div className="flex mb-5 items-center justify-between">    
           <div>
          
           </div>

        </div>
        <div className="grid grid-cols-7 gap-10" >
          <div className=" col-span-5" >
          <DataTable  isLoading={loading } data={clear ? []:target_ads} />
          {
         ( target_ads.length >=1 && !clear)&&  <div className="flex justify-end">
            <Button  onClick={handelApproved}   type="primary" > Approve </Button>   
          </div>
        }
          </div>
          <div className=" col-span-2 ">
            <CreateTarget  />
            </div>
        </div>
     </div>

    )
}

export default User


