import  React,{ useEffect } from 'react'
import { Table } from 'antd'
import {useFetchList} from '../../../api/services/backend'

interface Props {

    title? :string
}

    export default function view({current_farm,current_farmer, cancel}) {
  

    return (
      <div className=" ">

            <div className="grid grid-cols-2 gap-5 p-2 px-4 rounded-md shadow" style={{backgroundColor:'#F0F7FF'}}> 
              <div >
                  <h6 > <b> Farm Name :</b>&nbsp; {current_farm.farm_name}   </h6>
                    <h6> <b> Phone No. :</b>&nbsp; {current_farm.ph_no}   </h6>
                    <h6> <b> Address :</b>&nbsp; {current_farm.address}   </h6>
                    <h6> <b> Image :</b>&nbsp; {current_farm.image}   </h6>
              </div>
              <div>
                   <h6> <b> About :</b>&nbsp; {current_farm.about}   </h6>
                    <h6> <b> Review :</b>&nbsp; </h6>
              </div>
            </div>
                    
                   
                    <div className=" mt-6">
                              <h6 className=" text-base"><b>Crops:</b></h6> 
                           {
                             current_farmer.crop_info.map((crop)=>{
                               return crop.farm === current_farm.id ? 
                               <div className=" mb-5 p-2 px-4 rounded-md shadow" style={{backgroundColor:'#f5f5f5'}}> 
                                   <h6 ><b> Crop Name :</b>&nbsp;  {crop.crop_name}</h6>
                                   <h6> <b> Image :</b>&nbsp; {current_farm.image}   </h6>
                                   <h6> <b> About :</b>&nbsp; {crop.about_crop}   </h6>
                                   <h6> <b> Review :</b>&nbsp; </h6>

                              </div>:''
                              })
                           }
                    </div>
     </div>

    )
}

