
  import firebase from 'firebase';

  const firebaseConfig = {
    apiKey: "AIzaSyDReBoDY87a3f6G0SUu1bqUJ8AGoG_zHKM",
    authDomain: "seasonal-29d5d.firebaseapp.com",
    projectId: "seasonal-29d5d",
    storageBucket: "seasonal-29d5d.appspot.com",
    messagingSenderId: "39640004526",
    appId: "1:39640004526:web:78e9ed0eaa62d531687a3f",
    measurementId: "G-7T5EYW1877"
  };

firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();

export default storage;