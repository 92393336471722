import { BarChartOutlined, DiffOutlined, TeamOutlined, CreditCardOutlined } from '@ant-design/icons';

import { useFetchList } from '../../../../api/services/backend';
import { useAppSelector } from '../../../../api/hooks';
import { selectAuth } from '../../../../api/authSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { RiPercentFill } from "react-icons/ri";
import ChartLeft from './ChartLeft';
import ChartRight from './ChartRight';
import { Button, DatePicker, Form, Select } from 'antd';
import moment from 'moment';

export default function Index() {
    const [form] = Form.useForm();

    const [from_date, setFromDate] = useState(null) as any;
    const [to_date, setTo_date] = useState(null) as any;

    const { user } = useAppSelector(selectAuth);
    const { data, isLoading } = useFetchList(`agency?apartment_id=${user?.apartment}&from_date=${from_date}&to_date=${to_date}`, { enabled: !!user?.apartment })

    let dashcard = [
        {
            title: "Customers Onboard",
            icon: <TeamOutlined />,
            stat: data?.no_customers,
            desc: 'Total Customers Onboard',
            backColor: "#1BC943",
        },
        {
            title: "Orders",
            icon: <DiffOutlined />,
            stat: data?.totalOrder,
            desc: 'Total Orders',
            backColor: "#5383FF",
        },
        {
            title: "Total Sales",
            icon: <CreditCardOutlined />,
            stat: Number(data?.totalSales).toFixed(0),
            desc: 'Total Sales ',
            backColor: "#F83245",
        },
        {
            title: "Apartment In Agency",
            icon: <RiPercentFill />,
            stat: data?.apartmentInAgency,
            desc: '',
            backColor: "#bedf04",
        },

    ]

    const onDateChange = (value: any, date: any) => {
        setFromDate(date[0])
        setTo_date(date[1])

    }

    const reset = () => {
        setTo_date(null)
        setFromDate(null)
        form.resetFields();
    }

    return (
        <>
            {
                data?.apartment_info && <div className="p-2 inline-block">
                    <div className='flex gap-x-1'>
                        <p className='text-base font-semibold text-gray-600'>Name: </p>
                        <p className='capitalize font-medium'>{data?.apartment_info.name}</p>
                    </div>
                    <div className='flex gap-x-1'>
                        <p className='text-sm font-semibold text-gray-500'>Address: </p>
                        <p className='capitalize font-medium'>{data?.apartment_info.address}</p>
                    </div>
                </div>
            }

            <div className="py-4">
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                >
                    <div className="py-2 grid grid-cols-4 gap-x-2">
                        <Form.Item name="dates">
                            <DatePicker.RangePicker
                                disabledDate={
                                    (current) => {
                                        // Can not select days after today and before start Date
                                        const start = moment('2020-01-01', 'YYYY-MM-DD');
                                        return current < start || current > moment();
                                    }

                                }
                                onChange={onDateChange}
                                format="YYYY-MM-DD HH:mm"

                            />

                        </Form.Item>
                        <div>
                            <Button type="primary" disabled={!from_date && !to_date} onClick={reset}>Reset</Button>
                        </div>
                    </div>
                </Form>
            </div>

            <div className="grid gap-4 md:grid-cols-4 grid-cols-3">
                {
                    dashcard.map(item => <div key={item.title} title={item.title} className="shadow-md hover:shadow-lg rounded-lg p-2 flex flex-col justify-between items-center cursor-pointer" style={{ minHeight: "200px" }}>
                        <div className='flex items-center w-full gap-x-2'>
                            <div className="p-3 rounded-full text-white grid place-items-center text-2xl place-self-start" style={{ backgroundColor: item.backColor }}>{item.icon}</div>
                            <div className='text-base font-medium text-gray-500 md:break-word truncate' >{item.title}</div>
                        </div>
                        <h2 className='text-4xl pt-4 font-normal'>{item.stat}</h2>
                        <h4 className='font-normal text-gray-400'>{item.desc}</h4>
                    </div>)
                }
            </div>
            {/* <div className="grid grid-cols-2 gap-x-2 mt-6">
                <ChartLeft />
                <ChartRight />
            </div> */}
        </>
    )
}
