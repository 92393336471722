import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {useDispatch,useSelector} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'
import { DownloadOutlined  } from '@ant-design/icons';
import ModalForm from '../../shared/modal'
import {Link} from 'react-router-dom'
// import EditRegion from './editRegion'
import moment from 'moment' 

export default function Datatable({data, loading}) {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const {mutate} = useDelete('location')
  const [visibleEdit, setEditModal] = useState(false);
  const [curr_location, setLocation] = useState(null);

    const confirm = (e, id) => {
      console.log(id._id);
      mutate(id._id)
    }

    const cancel = (e) =>{
      return null
    }
    
    // console.log(data);
    

    // const handleEditClick = (e, isvisible, id) =>{
    //   e.preventDefault()
    //   setLocation(id)
    //   setEditModal(isvisible)
    //   }

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },

    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      render: text => {

                return <p className="m-0 capitalize font-medium">{text}</p>
            }
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: text => {
  
                  return <p className="m-0 capitalize ">{text}</p>
              }
        },

    {
      title: 'No. of Zones',
      dataIndex: 'zone_info',
      key: 'zone_info',
      render: text => {
                return <p className="m-0 capitalize ">{text ? text.length : 0}</p>
            }
      },

      {
        title: 'No. of Pincodes',
        dataIndex: 'pincode_info',
        key: 'pincode_info',
        render: text => {
                  return <p className="m-0 capitalize ">{text ? text.length : 0}</p>
              }
        },
        {
          title: 'No. of Locality',
          dataIndex: 'locality_info',
          key: 'locality_info',
          render: text => {
            return <p className="m-0 capitalize ">{text ? text.length : 0}</p>
          }
    },
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
              <Link to={`/dashboard/edit-location/${id._id}`}>
              <h5 style={{color: 'var(--brandColor' }} className="text-secondary  m-0 ">
 
              <FaRegEdit />                           
              </h5>
              </Link>

        <h5 className="m-0">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },

     ]

 

      return (
        <DataTableWrap>
      

    { 
     <Table 
     
    loading={loading}
    columns={columns} 
    dataSource={data}
    className="px-5"
    rowKey={(record)=>record._id}
    expandable={{
        expandedRowRender: record => {
         return <div className="px-5 py-2 text-sm grid grid-cols-3 " style={{width:'100%', color:'grey', letterSpacing:"0.03rem"}}>    

            <div className="text-left" >
               <p><b>Zones : </b></p>
               {                
                record.zone_info && record.zone_info.map((item, i)=>{   
                    return  <p className="mx-4 capitalize">{item.zone} </p> 
                    })                  
                }

            </div>
            <div className="text-left " >
               <p><b>Pincodes : </b></p>
               {                
                record.zone_info && record.pincode_info.map((item, i)=>{   
                    return  <p className="mx-4 capitalize">{item.pincode} </p> 
                    })                  
                }

            </div>
       </div>
      }, 
        // rowExpandable: record => record.zone_info  != null,
    } }
    />

    }       

            
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`
min-height: 70vh;
`