import  React,{ useEffect,useState } from 'react'
import {motion} from 'framer-motion'
import {   useQuery } from 'react-query'
import key from '../../../key'
import {useDispatch, useSelector} from 'react-redux'
import { Table } from 'antd'
import {useFetchList} from '../../../api/services/backend'
import {Button,Input} from 'antd'
import {Link} from 'react-router-dom'
import ModalForm from '../../shared/modal'
import {PlusOutlined,SearchOutlined} from '@ant-design/icons'
import DataTable from './datatable'
import styled from 'styled-components'
import { selectAuth,fethFilter} from '../../../api/authSlice'
import { useDebounce } from "use-debounce";
// import CreateCategory from './createCategory'
// import ExcelBtn from './exportexcel'
import axios from 'axios'
interface Props {

    title? :string
}

 const Seasonalcategory: React.FC<Props> = () => {



   const {data, error, isLoading} = useFetchList('seasonal-category')
   const [search, setSearch] = useState('')
   const [loading, setLoading] = useState(false)
   const dispatch = useDispatch()
  //  const {filter} = useSelector(selectAuth)
   const [debouncedText] = useDebounce(search, 2000);
const [filter,setFilter]=useState('')
  
   const [visible, setVisible] = useState(false)
  
   
   useEffect(()=>{
    // dispatch(fethFilter('seasonal-category', debouncedText ))
    axios.get(key.BACK_ENDURL + `/seasonal-category?search=${debouncedText}`).then(({data})=>{

      console.log({data})
      setFilter(data)
       })
  setLoading(false)
   }, [dispatch, debouncedText])



 useEffect(()=>{     
  if(filter.length < 1) {
    setSearch('')
  }
   }, [filter])


const onSearch = (e) => {
  setLoading(true)
  setSearch(e.target.value)

}


    return (
      
      <div className="p-5 rounded-lg bg-white" style={{border:'1px solid #e5e5e5'}}> 
          <div className="flex mb-5 items-center justify-between">    
           <div>
           <Link to="/dashboard/create-seasonal-category">
            <Button onClick={()=>setVisible(true)}  type="primary" icon={<PlusOutlined />}>
          Add Seasonal Category</Button>
         </Link>
           </div>
           <div>
          <SearchWrap className="mx-4 " style={{borderRadius:"4px"}}>

          <Input value={search}  className="px-4 py-2 focus:outline-none"
          prefix ={  <SearchOutlined  style={{color:'#3e79f7', fontWeight:'bold'}} />
          }
          placeholder="Search" onChange={onSearch}  />
          </SearchWrap>
          </div>
           {/* <ExcelBtn  data={ data && data.rows} /> */}

        </div>
       
        <DataTable   loading={loading || isLoading}
        data= {(filter.length> 0) ? filter :data?.category} />
      

     </div>



    )
}

export default Seasonalcategory


const SearchWrap = styled.div`
width:100%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}

`