import React from 'react'
import { Layout, Menu, Badge, Dropdown, Avatar } from 'antd';
import styled from 'styled-components'
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next';
import { LastWord } from './shared/lastpathword'
import { useLocation, Link } from 'react-router-dom'
import Logo from '../images/logo.png'


import {
  MenuUnfoldOutlined,
  BellOutlined,
  GlobalOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  LogoutOutlined, EditOutlined, MenuFoldOutlined, UserOutlined
} from '@ant-design/icons';
import us from '../images/flag/us.png'
import fr from '../images/flag/fr.png'
import { useLogout } from '../api/services/auth';
import { useAppSelector } from '../api/hooks';
import { selectAuth } from '../api/authSlice'

const { Header } = Layout;

const HeaderSec: React.FC<any> = ({ collapsed, click }) => {
  const { t, i18n } = useTranslation();
  const path = useLocation()
  const { logout } = useLogout()

  const { user, userType } = useAppSelector(selectAuth)

  const menu = (
    <div className="transform translate-y-5 ">
      <Menu defaultSelectedKeys={['en']}>
        <Menu.Item key="en"
        //  onClick={()=>i18n.changeLanguage('en')}
        >
          <span
            className="flex justify-around items-center "
          ><img className="mr-1" src={us} alt="eng" width="20px" />&nbsp;English</span>
        </Menu.Item>
        <Menu.Item key="fr"
        //  onClick={()=>i18n.changeLanguage('fr')}
        >
          <span
            className="flex justify-around items-center "
          ><img className="mr-1" src={fr} alt="fr" width="20px" />&nbsp;French</span>
        </Menu.Item>

      </Menu></div>
  );


  const profilemenu = (
    <div className="transform translate-y-4 shadow dmenu">
      <Menu>
        {/* <Menu.Item key="1">
             
             <div className="flex  justify-around ">
                 <div>
               <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined className=" cursor-pointer" style={{transform:'translateY(-0.3rem)'}} />} />

                 </div>
            <div className="ml-3">
              <h1 style={{fontSize:"1rem"}} className=" font-semibold text-gray-600" >{name}</h1>
             
            </div>
            
             </div>

          </Menu.Item> */}
        <Menu.Divider />
        <Menu.Item key="2" icon={<EditOutlined />}>
          <Link to={`/dashboard/${userType === "agency" ? "edit-agency-profile" : "editprofile"}/${user && user._id}`}>
            {t('editProfile')}
          </Link>
        </Menu.Item>
        <Menu.Item onClick={() => logout()} key="3" icon={<QuestionCircleOutlined />}>
          {t('logout')}
        </Menu.Item>
        {/* <Menu.Item key="4" icon={<LogoutOutlined/>}>
            {t('Help')}
          </Menu.Item> */}
      </Menu></div>
  );



  return (
    <HeaderWrap>
      <Header className="header flex items-center" style={{ position: 'fixed', zIndex: 1, width: '100%', height: "55px" }}>

        <div style={{ minWidth: collapsed ? 80 : 250 }} className="  flex-shrink-0">

          {
            collapsed ? <img src={Logo} className="d-block mx-auto" width="35px" alt="logo" /> :

              <img src={Logo} className="d-block mx-auto" width="40px" alt="logo" />
          }

        </div>
        <div className="w-full ">
          {collapsed ?
            <motion.button
              style={{ outline: 'none' }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            > <MenuUnfoldOutlined className="trigger " onClick={() => click()} /></motion.button>
            : <motion.button
              style={{ outline: 'none' }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            ><MenuFoldOutlined className="trigger " onClick={() => click()} /></motion.button>}

          <h2 style={{ color: "var(--brandColor)" }} className="          
              inline-block  text
               translate-y-1
              transform translate-x-5  text-xl  m-0 
              capitalize font-Poppins font-medium">
            {LastWord(path.pathname.split('-').join(' '))}
          </h2>
        </div>

        <div style={{ minWidth: "100px" }} className="flex-shrink-0">
          <div className="flex justify-around items-center text-xl  ">

            {/* <Badge count={0} >
             <BellOutlined style={{fontSize:"1.35rem"}}  className=" transform -translate-y-0 cursor-pointer"/>
             </Badge> */}

            {/* <Dropdown placement="bottomCenter"  overlay={menu}>
                             <GlobalOutlined className=" cursor-pointer" />
 
             </Dropdown> */}

            {/* <SettingOutlined className=" cursor-pointer"/> */}

            <Dropdown placement="bottomRight" overlay={profilemenu}>

              <Avatar className=" cursor-pointer" src="https://i1.wp.com/researchictafrica.net/wp/wp-content/uploads/2016/10/default-profile-pic.jpg?fit=300%2C300&ssl=1" />
            </Dropdown>

          </div>
        </div>

      </Header>
    </HeaderWrap>
  )
}


const HeaderWrap = styled.div`

.header {

    box-shadow: 0 1px 4px -1px rgb(0 0 0 / 15%);
    border-bottom: 1px solid #d4d4d4;

    background-color:white;
    padding:0 !important;
}
.trigger{
    font-size:1.2rem;
    transform:translateX(0.3rem);
}




`


export default HeaderSec