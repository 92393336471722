import key from '../../key'
import { QueryCache, useQueryClient, useQuery, useMutation } from 'react-query'
import axios from 'axios'
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch } from '../../api/hooks'
import { getTargetAdsList } from '../../api/authSlice'
import { Button, notification, Space, message } from 'antd';

const token = localStorage.getItem('sftoken')

const config = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    type: 'admins'

  }
};

const api = axios.create({
  baseURL: key.BACK_ENDURL,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    type: 'admins'
  }
})

//  GET ALL*************
const useFetchList = (resource, options = {}) => {
  // const getPosts = async () => {
  //   const { data } = await axios.get(key.BACK_ENDURL + `/${resource}`, config);

  //   return data;
  // };
  // const getPosts = async () => (await axios.get(key.BACK_ENDURL + `/${resource}`, config)).data
  const getPosts = async () => (await api.get(`/${resource}`))

  return useQuery(`${resource}`, getPosts, {
    ...options,
    select: ({ data }) => data
  })
}


//  GET ONE*************
const useFetchOne = (resource, id, options = {}) => {

  const getPosts = async (id) => (await api.get(`/${resource}/${id}`))

  // const getPosts = async (id) => {
  //   const { data } = await axios.get(key.BACK_ENDURL + `/${resource}/${id}`, config);
  //   return data;
  // };
  return useQuery([`${resource}`, id], () => getPosts(id), {
    ...options,
    select: ({ data }) => data
  })
}



//  CREATE*************
const useCreate = (resource,options={}) => {
  let history = useHistory();

  return useMutation((newTodo: any) =>
    axios.post(key.BACK_ENDURL + `/${resource}`, newTodo, config),
    {
      onSuccess: ({ data }: any) => {


        message.success(data?.msg).then(()=>{

      if(resource === 'toaster'){

          window.location.reload()
          return

        }

        if(resource === 'delivery'){

          window.location.reload()
          return

        }

        if(resource === 'coupon'){

          return

        }
        // queryClient.clear()
        history.push(`/dashboard/${resource =='broadcast/whatsapp' ? 'broadcast' : resource }`);



        })

  
      },
      onError: ({ response }: any) => {
        console.log(response);
        message.error(response?.data?.msg)
        // openNotificationWithIcon('warning', response?.data?.msg)
      },
      ...options
    }
  )
}



//  DELETE*************
const useDelete = (resource) => {
  console.log(resource);
  let history = useHistory();
  const queryClient = useQueryClient()

  return useMutation((id) =>
    axios.delete(key.BACK_ENDURL + `/${resource}/${id}`, config),
    {
      onSuccess: ({ data }: any) => {
        queryClient.invalidateQueries(resource)
        // queryClient.clear()
        message.success(data?.msg)

      },
      onError: ({ response }: any) => {
        console.log(response);
        message.error(response?.data?.msg)
      }
    }

  )
}




//  UPDATE*************
const useEdit = (resource, page = resource, cacheName = resource) => {

  let history = useHistory();
  const queryClient = useQueryClient()


  return useMutation((newTodo: any) =>
    axios.put(key.BACK_ENDURL + `/${resource}/${newTodo.id}`, newTodo, config),
    {
      onSuccess: ({ data }: any) => {
        message.success(data?.msg)

        queryClient.invalidateQueries(cacheName)
        // queryClient.setQueryData(['city', { id: data?._id }], data)
        resource === 'editprofile' ? history.push(`/dashboard/home`) : history.push(`/dashboard/${page}`)

      },
      onError: ({ response }: any) => {
        console.log(response);
        message.error(response?.data?.msg)
      }
    }
  )
}

//  UPDATE*************
const useUpdateCommition = (resource, page = resource, cacheName = resource) => {

  let history = useHistory();
  const queryClient = useQueryClient()


  return useMutation((newTodo: any) =>
    axios.put(key.BACK_ENDURL +`/${resource}`, newTodo, config),
    {
      onSuccess: ({ data }: any) => {
        message.success(data?.msg)

        queryClient.invalidateQueries(cacheName)
        // queryClient.setQueryData(['city', { id: data?._id }], data)
        // resource === 'editprofile' ? history.push(`/dashboard/home`) : history.push(`/dashboard/${page}`)

      },
      onError: ({ response }: any) => {
        console.log(response);
        message.error(response?.data?.msg)
      }
    }
  )
}

//* target */

const useTarget = (resource) => {

  const openNotificationWithIcon = (type, error) => {

    notification[type]({
      message: 'Warning message',
      description: error,
    });
  };

  let history = useHistory();
  const queryClient = useQueryClient()

  const dispatch = useAppDispatch()

  return useMutation((newTodo: any) =>
    axios.post(key.BACK_ENDURL + `/${resource}`, newTodo, config),
    {
      onSuccess: ({ data }) => {
        console.log(data);

        dispatch(getTargetAdsList(data))

      },
      onError: ({ response }: any) => {
        console.log(response);

        openNotificationWithIcon('warning', response?.data?.msg)
        dispatch(getTargetAdsList(response.data))

      },
      onSettled: () => {
        // history.push(`/dashboard/target`);

      }
    }
  )
}


//* publish */

const usePublish = (resource) => {

  let history = useHistory();
  const queryClient = useQueryClient()

  const dispatch = useAppDispatch()

  return useMutation((newTodo: any) =>
    axios.put(key.BACK_ENDURL + `/${resource}`, newTodo, config),
    {
      onSuccess: ({ data }) => {
        // dispatch(getTargetAdsList(data))

      },
      onError: (error) => {
        console.log(error);
        alert(error)
      },
      onSettled: () => {
        history.push(`/dashboard/target`);

      }
    }
  )
}



export { useFetchList, useTarget, useCreate, useFetchOne, useDelete, useEdit, useUpdateCommition, usePublish };