import { Button } from 'antd';
import React from 'react'
import ReactExport from "react-export-excel";
import { DownloadOutlined  } from '@ant-design/icons';
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function ExportExcel({data}) {
    return (
        <ExcelFile filename="user" element={<Button icon={<DownloadOutlined  style={{transform:"translateY(2px)" }}/>} className="mr-1" type="primary">Download</Button>}>
               
        <ExcelSheet data={data} name="user" >

        <ExcelColumn label="Username" value="username"/> 
            <ExcelColumn label="Name" value="name"/> 
            <ExcelColumn label="Role" value="role"/> 
            {/* <ExcelColumn label="Department" value={col => col.department ? col.department.department_name: "null"}/> */}
            <ExcelColumn label="Phone No." value="ph_no"/>     
            <ExcelColumn label="Email" value="email"/>     
            <ExcelColumn label="Address" value="address"/>    
            <ExcelColumn label="Pincode" value="pincode"/>    
            <ExcelColumn label="District" value="district"/>  
            <ExcelColumn label="State" value="state"/> 
        </ExcelSheet>
   
    </ExcelFile>
    )
}


