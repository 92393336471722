import { Button, Form, Input, Space, Table } from 'antd'
import layout from 'antd/lib/layout'
import { useCreate, useDelete, useFetchList } from '../../../../api/services/backend';
import Link from 'antd/lib/typography/Link';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import DeleteConfirm from '../../../shared/deleteConfirm';

export default function Toaster() {


  const {mutate} = useCreate('toaster')
 const {mutate:deleteToaste} =  useDelete('toaster')

    const { data, isLoading, refetch }  =       useFetchList('toaster')

const onFinish = (value)=>{

    mutate(value)



}

const confirm = (e, id) => {
  deleteToaste(id._id)
}

const cancel = (e) =>{
  return null
}


const columns  = [
  {
    title: 'Sl. No.',
    key: 'Sl.No.',
    render:(t, k, i)=>{
      return <p className="m-0 ">{(i+1)}</p>
    }
  },
  {
    title: 'Toaster Text',
    dataIndex: 'toaster_text',
    key: 'toaster_text',
    render: (text) => <p>{text}</p>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (id) => (
      <Space size="middle">
         
      
      
    <h5 className="text-danger mt-1">
        <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
            <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
        </DeleteConfirm>
    </h5>

    </Space>
    ),
  },
]


  return (
    <div className="p-5 rounded-lg  " style={{border:'1px solid #e5e5e5'}}> 

      
    <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >

<Form.Item
                                        label={<p className="text-left m-0 ">Toaster Text</p>}
                                        name="toaster_text"
                                        rules={[{ required: true, message: 'required' }]}
                                    >

           <Input  style={{ width: '100%' }} />
                                  
                                    </Form.Item>


                                    <Form.Item className="text-right">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>

            <br/>
            <br/>

            <Table columns={columns} dataSource={data} pagination={false} size='small' loading={isLoading} />

    </div>
  )
}
