import { Button } from 'antd';
import React from 'react'
import ReactExport from "react-export-excel";
import { DownloadOutlined  } from '@ant-design/icons';
import moment from 'moment'
import { render } from '@testing-library/react';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const onproduct = [22,22,22222,33445,55]


export default function ExportExcel({data}) {
    console.log({excel:data});
    // { col?.products?.map((item) => { 
    //     // return console.log(item?.product_name);   
    //     return <p>{item?.product_name}</p>
    //      })}
    
    return (
       
        <ExcelFile filename="order" element={<Button icon={<DownloadOutlined  style={{transform:"translateY(2px)" }}/>} className="mx-2" type="primary">Download</Button>}>
                
        <ExcelSheet data={data} name="order" >
    
            <ExcelColumn label="orderId" value={col => col?.orderId ? col.orderId: "null"}/> 

            <ExcelColumn label="Date" value={col => col?.createdAt ? moment(col?.createdAt).format('DD/MM/YYYY') : "null"}/> 
          
            <ExcelColumn label="Customer Name" value={col => col?.customer_name ? col?.customer_name : col?.user?.customer_info ? col.user?.customer_info?.customer_name: "null"}/> 
            <ExcelColumn label="Phone No." value={col => col?.customer_phone ? col?.customer_phone : col?.user?.ph_no ? col?.user?.ph_no : null }/> 
            <ExcelColumn label="Apartment Name" value={col => col?.user ? col?.user?.apartment_id?.apartment_info?.name  : "null"}/>  
        
            <ExcelColumn label="Quantity" value={col => col?.quantity ? col?.quantity: "null"}/> 
            <ExcelColumn label="Product" value={col => col?.prd ? col?.prd: "null"} />
            <ExcelColumn label="Address" value={col => col?.user ?  col?.user?.apartment_id?.apartment_info?.address : "null"}/>              
            <ExcelColumn label="Blok No." value={col => col?.address ? col?.address?.block_no: "null"}/> 
            <ExcelColumn label="House No." value={col => col?.address ? col?.address?.house_no: "null"}/> 
            <ExcelColumn label="Total" value={col => col?.total ? col?.total: "null"}/> 
            <ExcelColumn label="Payment method" value={col => col?.payment_method ? col?.payment_method: "null"}/> 
            <ExcelColumn label="paymentStatus" value={col => col?.paymentStatus ? col?.paymentStatus: "null"}/> 
            <ExcelColumn label="deliveryStatus" value={col => col?.orderStatus==='Delivered' ? 'Yes' : "No"}/> 
        </ExcelSheet>
    </ExcelFile>
    )
}
