import React, {useEffect,useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { Table,Input,Upload, Button,Tag, Space,DatePicker } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import DeleteConfirm from '../../shared/deleteConfirm'
import styled from 'styled-components'
import key from '../../../key'
import { useDelete,useFetchList} from '../../../api/services/backend'
import {Link} from 'react-router-dom'
import { AiFillEye } from 'react-icons/ai';
import {  GoPrimitiveDot } from 'react-icons/go';
import {UploadOutlined,SearchOutlined} from '@ant-design/icons'
import {useParams} from 'react-router-dom'
import { useDebounce } from "use-debounce";
import ImgUri from '../../../key'
import { selectAuth,fethFilter} from '../../../api/authSlice'
import ExcelBtn from './orderexcel'
import axios from 'axios'
import { DownloadOutlined  } from '@ant-design/icons';
import { createSeasonalOrderPdf} from '../../../api/order'
import moment from 'moment';


const { RangePicker } = DatePicker;


export default function Ordertable({data}) {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const [currentorder,setCurrentorder] = useState<any>([])
  const {id} = useParams()
  const dispatch = useDispatch()
  // const {mutate} = useDelete('advertisement')
  const {mutate} = useDelete('order')

  const {data:order , isLoading, refetch} = useFetchList(`seasonalorder/${id}`)

  const [exceldata, setExcelData] = useState<any>(null)

     
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const {filter} = useSelector(selectAuth)
  const [debouncedText] = useDebounce(search, 2000)
  const [item,setItem] =useState(null)
  const [downloadLoading,setDownloadLoading] =useState(false)

  
  const createPdf= (value)=> {      
    setDownloadLoading(true)
    setItem(value._id)
    dispatch(createSeasonalOrderPdf(value))
    
    setTimeout(()=>{
      setDownloadLoading(false)
        setItem(null)
    },3000)

}
    
    useEffect(()=>{
      dispatch(fethFilter(`seasonalorder/${id}`, debouncedText ))
    setLoading(false)
     }, [dispatch, debouncedText])
  
  
  
   useEffect(()=>{     
    if(filter.length < 1) {
      setSearch('')
    }
     }, [filter])
  
  
  const onSearch = (e) => {
    setLoading(true)
    setSearch(e.target.value)
  
  }
   
  const onChangeDate = (date, dateString) =>{
    console.log(date);
    console.log( dateString);
    axios.post(key.BACK_ENDURL + `/seasonalorder-date/${id}`, date).then(({data})=>{
  
      setExcelData(data?.order)
    })
  }


  const confirm = (e, id) => {
    console.log(id._id);
    mutate(id._id)
    setTimeout(()=>{
      refetch()
    }, 1000)
    // window.location.reload()
  }
  

  const cancel = (e) =>{
    return null
  }
  

  const columns = [
    {
      title: 'Sl. No.',
      fixed: 'left',
      key: 'Sl.No.',
      width:'60px',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)} </p>
      }
      
    },
    {
      title: 'Date',
      width:'110px',
      fixed: 'left',

      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => <p className="m-0 capitalize font-medium">{moment(text).format('DD/MM/YYYY')}</p>,
     
    },

    {
      title: 'orderId',
      key: "orderId",
      fixed: 'left',

      dataIndex: 'orderId',
      render:(text)=>{
        return <p className="m-0 capitalize font-medium">{text}</p>
    }
    },

    {
      title: 'Customer Name',
      key:'name',
      render: text => <p className="m-0 capitalize font-medium">{text?.customer_name ? text?.customer_name : text?.user?.customer_info?.customer_name }</p>,
    },

    {
      title: 'Apartment Name',
      dataIndex: 'address',
      key: 'address',
      render:(text)  => <p className=" capitalize m-0">{text?.apartment}</p>,
    },

    // {
    //   title: 'City',
    //   key: 'city',
    //   render:(text)  => <p className=" capitalize m-0">{text?.address?.city ? text?.address?.city : text?.user?.apartment_id?.city_id?.city}</p>,
    // },

    {
      title: 'Product Name',
      key: "products",
      dataIndex: 'products',
      render:(text)=>{
        return <p className="m-0 capitalize ">{text[0]?.product_name}</p>
    }
    },

    {
      title: 'Units',
      width:'80px',

      key: "products",
      dataIndex: 'products',
      render:(text)=>{
        return <p className="m-0 capitalize ">{text[0]?.units}</p>
    
    }
    },

    {
      title: ' invoiceNumber',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      render: text => {
        return <p className="m-0">{text}</p>
    },
  },

  {
    title: ' Payment Method',
    dataIndex: 'payment_method',
    key: 'payment_method',
    render: text => {
      return <p className="m-0 capitalize"  style={{color:text === 'online' ? 'green' : text === 'COD' ? '#3498db': '' }}>
          {text === 'online' ? 'Online' : text === 'COD' ? 'C.O.D': 'null'}
      </p>
  },
},

    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    render: text => {
      return <p className="m-0 capitalize" style={{color: text === 'success' ?'green' : text === 'pending' ? '#eab308' : text === 'failed' ? '#dc2626'  : text === 'unpaid' ? '#3498db ' : `#000000`}}>
      {text}
      </p> 
      // return <p className="m-0 capitalize" style={{color: text === 'success' ?'green' : text === 'pending' ? '#eab308' : text === 'failed' ? '#dc2626'  : text === 'unpaid' ? '#3498db ' : `#000000`}}>
      //  {text === 'success' ? 'Online' : text === 'pending' ? 'Pending': text === 'failed' ? 'Failed' : text === 'unpaid' ? 'C.O.D' : 'null'}</p> 
  },

    },

    {
    title: 'Order Status',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    render: text => {
      return <p className="m-0" style={{color: text === 'Delivered' ?'green' : '#3498db '}}>{text === 'Delivered' ? 'Delivered' : 'Ordered'  }  </p>
    },
    },
    
    {
      title:'Invoice',
      render: (value) => { 
          return <Button icon={<DownloadOutlined/>} disabled={(downloadLoading && item === value._id) ? true : false } 
                  style={{backgroundColor:"#3498db24", color:'var(--brandColor)',boxShadow:'none'}} type="primary" onClick={()=>createPdf(value)}>
          {(downloadLoading && item === value._id) ? 'Loading': 'Download'}
            </Button>
      }
    },
    {
      title: 'Action',
      width:'80px',
      key: 'action',
      render: (id) => (
        <Space size="middle">
    
      <h5 className="m-0">
          {(id?.paymentStatus === 'pending' || id?.paymentStatus === 'failed') && id?.payment_method === 'online' ?
              <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                  <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
              </DeleteConfirm> 
              : 
            <FaRegTrashAlt style={{cursor:"pointer", color:"#929292"}} />
      }
      </h5>

      </Space>
      ),
    },
     
     ] as any

     
     const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {


        setSelectionKey(selectedRowKeys)
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    


      return (

        <DataTableWrap>
           <div className=" p-1 rounded-lg bg-white" style={{border:'1px solid #e5e5e5 '}}> 
          <div className="flex mb-4 items-center justify-between pt-3">    

<div>
           <SearchWrap className="mx-4 " style={{borderRadius:"4px"}}>

          <Input value={search}  className="px-4 py-2 focus:outline-none"
          prefix ={  <SearchOutlined  style={{color:'#3e79f7', fontWeight:'bold',}} />
          }
          placeholder="Search" onChange={onSearch}  />

          </SearchWrap>

</div>

           <div  className="flex  items-center justify-end ">

          <RangePicker onChange={onChangeDate} />

          <ExcelBtn data={(exceldata && exceldata?.length > 0  ) ? exceldata : order?.order} />

          
<Upload
      action={ImgUri.BACK_ENDURL + `/orderByExcel`}
      // listType="file"
      name="file"
      className="mr-3"
    >
      <Button icon={<UploadOutlined /> } type="primary">Upload</Button>
    </Upload> 

           </div>
            
             </div>
    
    { 
   <Table 
    //   rowSelection={{
    //     // type: selectionType,
    //     ...rowSelection,
    //   }}  

    // pagination={{
    //   onChange(current) {
    //     setPage(current)
    //   }
    // }}

    loading={loading || isLoading}
    columns={columns} 
    dataSource={(filter.length> 0) ? filter : order?.order} 
    rowKey={(record)=>record._id}  
    scroll={{ x: 1900}}
        expandable={{
     
        expandedRowRender: record => {
         
         return   <div className="text-left" style={{ letterSpacing:"0.03rem"}}>
         <div className="grid grid-cols-3 gap-2">  
         <div>
         <p><b>House number : </b> {record.address && record.address.house_no || "null"}</p>
         <p><b>Address : </b> {record.address && record.address.address || "null"}</p>
         </div>
         <div>
         <p><b>Block number : </b> {record.address && record.address.block_no || "null"}</p>
         <p><b>Customer number : </b> {record.user && record.user.ph_no || "null"}</p>
         </div>
        <div>  
          <p><b>Payment Status : </b> {record.paymentStatus && record.paymentStatus || "null"}</p>
         <p><b>Payment Method: </b> {record.payment_method && record.payment_method || "null"}</p>
         </div>
  
           </div>                
        </div>
       {/* </div> */}
      }, 
    //   rowExpandable: record => record.name  !== null,
    } }
    />

    }       
 </div>
 
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;


`


const SearchWrap = styled.div`
width:100%;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}

`