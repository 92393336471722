import { Tabs } from 'antd'
import React from 'react'
import { useState } from 'react';
import Createcategory from './createOffer';
import Offer2 from './Offer2';
import Coupon from './coupon';
import DeliveryCharges from './delivery';

export default function Index() {
    const [activeTab, setActiveTab] = useState("1");

    return (
        <div className=" rounded-lg bg-white p-5 shadow-sm flex" style={{ height: '100%', border: '1px solid #e5e5e5' }}>
            {
                (!true)
                    ? <h1>Loading...</h1>
                    : <Tabs
                    className='w-full'
                        activeKey={activeTab}
                        onChange={(key: any) => setActiveTab(key)}
                        items={[
                            {
                                label: "Offer",
                                key: "1",
                                children: <Createcategory />
                            },
                            {
                                label: "Offer 2",
                                key: "2",
                                // disabled:!apartment,
                                children: <Offer2 />
                            },
                            {
                                label: "Coupons",
                                key: "3",
                                // disabled:!apartment,
                                children: <Coupon />
                            },
                            {
                                label: "Delivery Charges",
                                key: "4",
                                // disabled:!apartment,
                                children: <DeliveryCharges />
                            }
                        ]}
                    />
            }

        </div>
    )
}
