import React, {useEffect, useState} from 'react'
import {useCreate, useFetchList} from '../../../../api/services/backend'
import { Form, Input, Button, InputNumber ,Checkbox, Select, Row, Col} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {MdClose} from 'react-icons/md'
const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };



  export default function CreateCity({cancel}) {

  const {mutate} = useCreate('location')

  const [form] = Form.useForm();
   

  const onFinish = (values: any) => {
    const cityData = {
    city:values.city, 
    state:values.state

  }
  mutate(cityData)
  form.resetFields()
  };

  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };



  return (
   
    <div className=" mx-5 grid grid-cols-2 mt-6">

    <Form
    {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >   
         
      <Form.Item
            label={<p className="text-left m-0 ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{width:'100%'}} />
          </Form.Item>

          <Form.Item
            label={<p className="text-left m-0 ">State</p>}
            name="state"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{width:'100%'}} />
          </Form.Item>
      

        <div className="mt-10">
            <Form.Item  className="text-right">
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
        </div>
   

    </Form>

    </div>
  );

  }