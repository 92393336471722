import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './api/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider} from 'antd'
import frFR from 'antd/lib/locale/fr_FR';
import './i18next';


ReactDOM.render(
  <React.StrictMode>
    {/* <ConfigProvider locale={frFR}> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* </ConfigProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
