import React, { useState, useEffect } from 'react'
import { Layout, Affix } from 'antd';
import { Route, Switch, useRouteMatch, useLocation, useHistory ,Redirect} from 'react-router-dom';
import SideBar from './sidebar'
import Header from './header'
import Loader from './shared/loader'
import { useAppSelector, useAppDispatch } from '../api/hooks'
import { selectAuth } from '../api/authSlice'
import styled from 'styled-components'
import Farmer from './pages/farmer/index'
import CreateFarmer from './pages/farmer/createFarmers'
import EditFarmer from './pages/farmer/editFramer'
import { useCheckAuth } from '../api/services/auth'
import Users from './pages/users'
import CreateUser from './pages/users/createUser'
import EditUser from './pages/users/editusers'

import Apartment from './pages/apartment'
import EditApartment from './pages/apartment/editApartment'


import Product from './pages/shop/products'
import CreateProduct from './pages/shop/products/createProduct'
import EditProduct from './pages/shop/products/editProduct'

import Category from './pages/shop/category'
import Createcategory from './pages/shop/category/createCategory'
import EditCategory from './pages/shop/category/editCategory'

import Customer from './pages/customer'


import Advertisement from './pages/advertisements'
import CreateAdvertisement from './pages/advertisements/createAdvertisements'
import EditAdvertisement from './pages/advertisements/editAdvertisements'

import Target from './pages/target'
import CreateTarget from './pages/target/createTarget'

import Seasonalcategory from './pages/seasonalCategory'
import CreateSeasonalcategory from './pages/seasonalCategory/createSeasonalCategory'
import EditSeasonalcategory from './pages/seasonalCategory/editSeasonalcategory'
import Seasonalorder from './pages/advertisements/ordetable'

import Broadcast from './pages/broadcast'
import CreateBroadcast from './pages/broadcast/createBroadcast'
import EditBroadcast from './pages/broadcast/editBroadcast'

import Location from './pages/location'
import CreateLocation from './pages/location/createLocation'
import EditCity from './pages/location/edit/editCity'
import EditZone from './pages/location/edit/editZone'
import EditPincode from './pages/location/edit/editPincode'
import EditLocality from './pages/location/edit/editLocality'

import Launchapartment from './pages/launchcampaign'
import CreateLaunchApartment from './pages/launchcampaign/createLaunchcampaign'
import EditLaunchApartment from './pages/launchcampaign/editLaunchcampaign'
import Orders from './pages/orders'
import SubscribeOrders from './pages/subscribe'

import Sample from './pages/samples'

import SampleProduct from './pages/sampleproduct'
import CreateSample from './pages/sampleproduct/createSample'
import EditSample from './pages/sampleproduct/editSample';
import AgencyStatistic from "./pages/AgencyStatistic";
import AgencyProfile from "./pages/myprofie/agenyProfile";

import Logbook from './pages/logbook'
import Myprofile from './pages/myprofie'
// import Offer from './pages/offer/createOffer'
import Offer from './pages/offer'

import { AnimatePresence } from 'framer-motion'




import Home from './pages/dashboard'
import Tab from './pages/apartment/Tab';
import Banner from './pages/settings/banner';
import CreateBanner from './pages/settings/banner/createBanner';
import EditBanner from './pages/settings/banner/editBanner';
import Testimonial from './pages/settings/testimonial';
import CreateTestimonial from './pages/settings/testimonial/createTestimonial';
import EditTestimonial from './pages/settings/testimonial/editTestimonial';
import Toaster from './pages/offer/toaster/toaster';


const { Content, Footer } = Layout;


export default function Admin() {

  let { path } = useRouteMatch();

  const [collapsed, setCollapsed] = useState(false)
  const [theme, setTheme] = useState(false)
  const { checkAuth } = useCheckAuth()
  const st = useAppSelector(selectAuth)
  const { replace } = useHistory()

  st && console.log(st.loading);


  useEffect(() => {

    !st.loading && checkAuth()

  }, [st.loading])


  const location = useLocation()


  const toggle = () => {
    setCollapsed(!collapsed)

  };

  const changeTheme = () => {

    return false

  }


  let loading = false

  // return <div className='h-screen w-screen bg0gray-500 flex flex-col'>
  //   <div className="h-14 bg-gray-600">head</div>
  //   <div className="flex-1 bg-red-400 flex gap-x-8">
  //     <SideBar
  //      collapsed={collapsed}

  //     //  color={false} click={changeTheme} 
  //      />

  //     {/* <div className="bg-blue-400 w-fit">
  //     </div> */}
  //     <div className="bg-blue-600 flex-1">alsjdfkljakljdfljasldjdf;laksjfl;kasdjfdl;aksjdfl;akskjfl;kaskdjfl;kasjfl;kasdjfkl;asdjflk;asdfklasdfklalsdkfjlasdjflj</div>
  //   </div>

  // </div>

  console.log(st)

  return (
    <> {

      !!!st.userType ? <Loader /> : <AdminWrap >
        <Layout>

          <Header click={toggle} collapsed={collapsed} />

          <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 250 }}>

            <SideBar collapsed={collapsed} color={false} click={changeTheme} />

            <Content
              className=""
              style={{
                margin: '70px 16px 0px 16px',
                padding: 10,
                minHeight: '85vh',
                backgroundColor: "#FAFAFB"
              }}
            >


              <AnimatePresence exitBeforeEnter initial={false} >

                <Switch location={location} key={location.pathname}>
                  <Route exact path={`${path}/home`} component={Home} />
                  <Route exact path={`${path}/edit-agency-profile/:id/`} component={AgencyProfile} />

                  {
                    st.userType === "admin" && <>
                      <Route exact path={`${path}/farmer`} component={Farmer} />
                      <Route exact path={`${path}/create-farmer`} component={CreateFarmer} />
                      <Route exact path={`${path}/edit-farmer/:id`} component={EditFarmer} />
                      <Route exact path={`${path}/editprofile/:id`} component={Myprofile} />
                      <Route exact path={`${path}/user`} component={Users} />
                      <Route exact path={`${path}/create-user`} component={CreateUser} />
                      <Route exact path={`${path}/edit-user/:id`} component={EditUser} />

                      <Route exact path={`${path}/apartment`} component={Apartment} />
                      <Route exact path={`${path}/create-apartment`} component={Tab} />
                      <Route exact path={`${path}/edit-apartment/:id/`} component={Tab} />
                      {/* <Route exact path={`${path}/edit-apartment/:id/`} component={EditApartment} /> */}

                      <Route exact path={`${path}/target`} component={Target} />
                      <Route exact path={`${path}/create-target`} component={CreateTarget} />

                      <Route exact path={`${path}/seasonal-category`} component={Seasonalcategory} />
                      <Route exact path={`${path}/create-seasonal-category`} component={CreateSeasonalcategory} />
                      <Route exact path={`${path}/edit-seasonal-category/:id`} component={EditSeasonalcategory} />

                      <Route exact path={`${path}/product`} component={Product} />
                      <Route exact path={`${path}/create-product`} component={CreateProduct} />
                      <Route exact path={`${path}/edit-product/:id`} component={EditProduct} />

                      <Route exact path={`${path}/category`} component={Category} />
                      <Route exact path={`${path}/create-category`} component={Createcategory} />
                      <Route exact path={`${path}/edit-category/:id`} component={EditCategory} />

                      <Route exact path={`${path}/advertisement`} component={Advertisement} />
                      <Route exact path={`${path}/create-advertisement`} component={CreateAdvertisement} />
                      <Route exact path={`${path}/edit-advertisement/:id`} component={EditAdvertisement} />
                      <Route exact path={`${path}/seasonalorder/:id`} component={Seasonalorder} />

                      <Route exact path={`${path}/location`} component={Location} />
                      <Route exact path={`${path}/create-location`} component={CreateLocation} />
                      <Route exact path={`${path}/edit-location/:id`} component={EditCity} />
                      <Route exact path={`${path}/edit-zone/:id`} component={EditZone} />
                      <Route exact path={`${path}/edit-pincode/:id`} component={EditPincode} />
                      <Route exact path={`${path}/edit-locality/:id`} component={EditLocality} />

                      <Route exact path={`${path}/campaign`} component={Launchapartment} />
                      <Route exact path={`${path}/create-launchapartment`} component={CreateLaunchApartment} />
                      <Route exact path={`${path}/edit-launchapartment/:id`} component={EditLaunchApartment} />

                      <Route exact path={`${path}/logbook`} component={Logbook} />

                      <Route exact path={`${path}/broadcast`} component={Broadcast} />
                      <Route exact path={`${path}/create-broadcast`} component={CreateBroadcast} />
                      <Route exact path={`${path}/edit-broadcast/:id`} component={EditBroadcast} />

                      <Route exact path={`${path}/customer`} component={Customer} />

                      <Route exact path={`${path}/orders`} component={Orders} />
                      <Route exact path={`${path}/subscribe-orders`} component={SubscribeOrders} />


                      <Route exact path={`${path}/sample-orders`} component={Sample} />

                      <Route exact path={`${path}/sample-product`} component={SampleProduct} />
                      <Route exact path={`${path}/create-sample-product`} component={CreateSample} />
                      <Route exact path={`${path}/edit-sample-product/:id`} component={EditSample} />

                      <Route exact path={`${path}/offers`} component={Offer} />
                      <Route exact path={`${path}/agency-statistics`} component={AgencyStatistic} />

                      <Route exact path={`${path}/banner`} component={Banner} />
                      <Route exact path={`${path}/create-banner`} component={CreateBanner} />
                      <Route exact path={`${path}/edit-banner/:id`} component={EditBanner} />

                       <Route exact path={`${path}/testimonial`} component={Testimonial} />
                      <Route exact path={`${path}/create-testimonial`} component={CreateTestimonial} />
                      <Route exact path={`${path}/edit-testimonial/:id`} component={EditTestimonial} />

                      <Route exact path={`${path}/toaster`} component={Toaster} />

                    </>
                  }
                </Switch>
              </AnimatePresence>

            </Content>
          </Layout>
          <Footer style={{ textAlign: 'center' }}> Copyright © 2021 Seasonal forever. All rights reserved.</Footer>

        </Layout>
      </AdminWrap>

    }
    </>
  )
}


const AdminWrap = styled.div`

`