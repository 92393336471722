import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {useDispatch,useSelector} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import {Link} from 'react-router-dom'
import { motion } from "framer-motion";
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'

export default function Datatable({data, isLoading}) {

  const [selectionType, setSelectionType] = useState('checkbox');
  const [page, setPage] = useState(1);
  const [selectionKey, setSelectionKey] = useState([]);
  const {mutate} = useDelete('user')

  const handleClickEdit = (e, isvisible, id) =>{
    e.preventDefault()
   
    }
  
    const confirm = (e, id) => {
      console.log(id._id);
      mutate(id._id)
    }

    const cancel = (e) =>{
      return null
    }

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },
    {
      title: 'Username',
      dataIndex: 'admin_name',
      key: 'admin_name',
      render: text => <p className="m-0">{text}</p>,
    },

    {
      title: 'Mobile No.',
      dataIndex: 'phone_no',
      key: 'phone_no',
      render: text => <p className="m-0">{text}</p>,
    },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: text => <p className="m-0">{text}</p>
      },

      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: text => <p className="m-0">{text}</p>
      },
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
             <Link to={`/dashboard/edit-user/${id._id}`}>
             <h5  style={{color: 'var(--brandColor' }}  className="text-secondary  m-0 ">
           <FaRegEdit />  
            </h5>
            </Link>

        <h5 className="m-0">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="user" cancel={cancel} >
                <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },

     ]

     const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {


        setSelectionKey(selectedRowKeys)
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };


      return (
        <DataTableWrap>
    
    { 
      !isLoading &&  <Table 
      
      // rowSelection={{
      //   // type: selectionType,
      //   ...rowSelection,
      // }}   
  
      pagination={{
        onChange(current) {
          setPage(current)
        }
      }}

    loading={isLoading}
    columns={columns} 
    dataSource={data.admin} 
    />

    }       
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;

`