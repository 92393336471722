import  React,{ useEffect } from 'react'
import {  Tabs  } from 'antd';
import Basic from './basic'
import Farm from './farm'
import Crop from './crop'
import Bank from './bank'
import RManager from './relationshipManager'
import {useDispatch, useSelector} from 'react-redux'
import { useFetchOne} from '../../../../api/services/backend'
import {useParams} from 'react-router-dom'

// import { Pbasic, Pfarm, Pcrop, Pbank, Pmanager, getBasicInfo, getFarmInfo, getCropInfo, getBankInfo, getManagerInfo, farmerSelector } from '../../../../api/services/farmer'

const { TabPane } = Tabs;


  export default function CreateFarmerIndex() {

    const dispatch = useDispatch()
//     const {   activetab, basic, farm, crop, bank, manager } = useSelector(farmerSelector)
  
    const {id} = useParams()

    const {data} = useFetchOne('farmer', id)

    

    const callback = (key)=> {
      console.log(key);
    }

  return (

      <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
      <Tabs           
              // activeKey={activetab}             
              onChange={callback}>                
              
              <TabPane tab="Basic" key="1">
              < Basic  farmer={data?.farmer}/>
              </TabPane>

            <TabPane tab="Farm"  key="2">
                  <Farm  farmer={data?.farmer}/>
            </TabPane>
            <TabPane tab="Crop" key="3">
                  <Crop farmer={data?.farmer}/>
            </TabPane>
            <TabPane tab="Bank" key="4">
                  <Bank  farmer={data?.farmer}/>
            </TabPane>
            <TabPane tab="Relationship Manager" key="5">
                  <RManager farmer={data?.farmer}/>
            </TabPane>
    </Tabs>
   </div>  
  );

  }