import React, {useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {useDispatch,useSelector} from 'react-redux'
import DeleteConfirm from '../../shared/deleteConfirm'
import {Link} from 'react-router-dom'
import { motion } from "framer-motion";
import styled from 'styled-components'
import { useDelete} from '../../../api/services/backend'
import { DownloadOutlined  } from '@ant-design/icons';
import ModalForm from '../../shared/modal'
import moment from 'moment'
// import EditCategory from './editCategory'

export default function Datatable({data, loading}) {
console.log({suraj:data});

  const [page, setPage] = useState(1);
  const {mutate} = useDelete('seasonal-category')
  const [visibleEdit, setEditModal] = useState(false);
  const [curr_category, setCategory] = useState(null);

    const confirm = (e, id) => {
      mutate(id._id)
    }

    const cancel = (e) =>{
      return null
    }

    const handleEditClick = (e, isvisible, id) =>{
      e.preventDefault()
      setCategory(id)
      setEditModal(isvisible)
      }

  const columns = [
    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },

    {
      title: 'Category Name',
      dataIndex: 'category',
      key: 'category',
      render: text => <p className="m-0 capitalize  font-medium">{text}</p>,
    },
    {
        title: 'Resale Days',
        dataIndex: 'resale_days',
        key: 'resale_days',
        render: text => <p className="m-0 capitalize  font-medium">{text}</p>,
      },
      
      {
        title: 'Estimate Quantity',
        dataIndex: 'estimate_qty',
        key: 'estimate_qty',
        render: text => <p className="m-0 capitalize  font-medium">{text}</p>,
      },
      {
        title: 'Converstion Rate',
        dataIndex: 'converstion_rate',
        key: 'converstion_rate',
        render: text => <p className="m-0 capitalize  font-medium">{text}</p>,
      },
    // {
    //   title: ' Date',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render:(text)  => <p className="m-0">{moment(text).format('DD/MM/YYYY')}</p>,
    // },
  
  
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
               <Link to={`/dashboard/edit-seasonal-category/${id._id}`}>
             <h5 style={{color: 'var(--brandColor' }} className="text-secondary  m-0 " >
              <FaRegEdit />                           
              </h5>
              </Link>

        <h5 className="text-danger mt-2">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="seasonalcategory" cancel={cancel} >
            <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },

     ]

 


      return (
        <DataTableWrap>
      

    { 
        <Table 
    
    loading={loading}
    columns={columns} 
    dataSource={data} 
    rowKey={(record)=>record._id}
    
    />

    }       
          </DataTableWrap>  
     )
 }
   
 
const DataTableWrap = styled.div`

min-height: 70vh;
`