import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useQuery } from 'react-query'
import key from '../../../key'
import { Table } from 'antd'
import { useFetchList } from '../../../api/services/backend'
import { Button, Input, Select, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import ModalForm from '../../shared/modal'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons'
import DataTable from './datatable'
import ExcelBtn from './exportexcel'
import styled from 'styled-components'
import { useDebounce } from "use-debounce";
import axios from 'axios'
import moment from 'moment'
import { selectAuth, fethFilter } from '../../../api/authSlice'
const { Option } = Select;
interface Props {

  title?: string
}

const Customer: React.FC<Props> = ({ title }) => {


  const { data: res, isLoading } = useFetchList('customer')

  const [date, setDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  const [data, setData] = useState<any>([])

  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  //  const {filter} = useSelector(selectAuth)
  const [filter, setFilter] = useState('')

  console.log({ tyt: filter });

  const [debouncedText] = useDebounce(search, 2000);
  const [filtercustomer, setFilterCustomer] = useState(false)

  const [visible, setVisible] = useState(false)
  const [apartment, setApartment] = useState<any>(null)

  console.log(data);


  useEffect(() => {

    setData(res)

  }, [res])



  useEffect(() => {
    // dispatch(fethFilter('seasonal-category', debouncedText ))
    axios.get(key.BACK_ENDURL + `/customer?search=${debouncedText}`).then(({ data }) => {

      setFilter(data)
    })
    setLoading(false)
  }, [dispatch, debouncedText])


  useEffect(() => {
    dispatch(fethFilter('customer', debouncedText))
    setLoading(false)
  }, [dispatch, debouncedText])



  useEffect(() => {
    if (filter.length < 1) {
      setSearch('')
    }
  }, [filter])


  const onSearch = (e) => {
    setLoading(true)
    setSearch(e.target.value)

  }


  function onChange(value) {

    if (value !== 'all') {
      const date = moment().subtract(value, 'days').format('YYYY-MM-DD')
      axios.get(key.BACK_ENDURL + `/customer?date=${date}`).then(({ data }) => {
        setData(data)
      })
    }
    else {


      axios.get(key.BACK_ENDURL + `/customer?date=${'null'}`,).then(({ data }) => {
        setData(data)
      })
    }
  }

  const onChangApartment = (value) => {
    setApartment(value)
    setLoading(true)

    axios.get(key.BACK_ENDURL + `/customer?apartment=${value}`).then(({ data }) => {
      data && setFilterCustomer(true)
      setFilter(data)
      data && setLoading(false)
    })

  }


  let cust = data?.filter((item, i) => {
   if(item.apartment_id) {

    return item.apartment_id
   }
    
  })



  let unique = cust?.filter((thing, index, self) =>{

    
    console.log({self});
    

    return thing && index === self.findIndex((t) => (
      t?._id === thing?._id
    )
    )
   })
 
    

  console.log(unique);


  const reset = () => {
    setFilter('')
    setApartment(null)
    setSearch('')
    //  dispatch(fetchContent( user_id,null, null))
  }

  const onSearchAppartment = (value: string) => {
    console.log('search:', value);
  };

  return (

    <div className="p-5 rounded-lg bg-white" style={{ border: '1px solid #e5e5e5' }}>
      <div className="flex mb-5 items-center justify-between">
        <div>
          {/* <Link to="/dashboard/create-user">
            <Button onClick={()=>setVisible(true)}  type="primary" icon={<PlusOutlined />}>
            Add User</Button>
            </Link> */}
        </div>


        <div className="flex  items-center justify-end">

          <Select
            style={{ width: "200px", margin: '0 10px' }}
            placeholder="Days"
            optionFilterProp="children"
            onChange={onChange}
            // onSearch={onSearch}
        
          >
            <Option value="7">Past 7 Days</Option>
            <Option value="30">Past 30 Days</Option>
            <Option value="90">Past 90 Days</Option>
            <Option value="all">All</Option>
          </Select>



          <Select

            placeholder="Apartment Names"
            onChange={onChangApartment}
            style={{ width: '250px' }}
            value={apartment}
            optionFilterProp="children"
            onSearch={onSearchAppartment}
            showSearch
            filterOption={(input:any, option:any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={
              
              unique?.filter((item) =>{
                

                if(item.apartment_info?.name) {

                return {value:item?.apartment_info?.name, label:item?.apartment_info.name}

                }
                
              
              })
              }
          />
            {/* {
              unique?.map((item, i) => <Select.Option key={i} value={item._id}>{item.apartment_info.name}</Select.Option>)
            }

          </Select> */}


          <Tooltip title="Clear Filter">
            <SyncOutlined
              className="text-xl shadow"
              style={{
                marginLeft: '10px',
                padding: "0.45rem", borderRadius: "4px",
                backgroundColor: "#3498db24",
                color: 'var(--brandColor)'
              }} onClick={reset} />
          </Tooltip>


          <SearchWrap className="mx-4 " style={{ borderRadius: "4px" }}>

            <Input value={search} className="px-4 py-2 focus:outline-none"
              prefix={<SearchOutlined style={{ color: '#3e79f7', fontWeight: 'bold' }} />
              }
              placeholder="Search" onChange={onSearch} />
          </SearchWrap>

          <ExcelBtn data={data && data} />

        </div>


      </div>

      <DataTable loading={loading}
        data={((debouncedText || filtercustomer) && filter.length > 0) ? filter : data} />

    </div>



  )
}

export default Customer


const SearchWrap = styled.div`
width:300px;
transition:0.3s ease-in-out;
.ant-input-affix-wrapper > input.ant-input {
box-shadow: none !important;
&:focus{
    border-color:white !important;
}
&:hover{
    border-color:white !important;
}
}
`