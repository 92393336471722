import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button , InputNumber, Upload, Image } from 'antd';
import storage from '../../../shared/storage'
import {MdClose, MdDelete} from 'react-icons/md'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  useEdit,  } from '../../../../api/services/backend'
import styled from 'styled-components'
import Loader from '../../../shared/loader';

    export default function Media({ advertisement }) {
        const dispatch = useDispatch()
        const [form] = Form.useForm();
        const [imgurl, setImgurl] = useState<any>([])
        const [loading1, setLoading1] = useState(false)
        const [fileList, setFileList] = useState([])

        const {mutate} = useEdit('advertisement')


        useEffect(()=>{          
          setImgurl(advertisement?.media_info?.image)

          advertisement && form.setFieldsValue({
              video: advertisement?.media_info?.video_link,
            });
      }, [advertisement])


        const onFinish = (values: any) => {
            const data = {
              media_info:{
                image: imgurl,
                video_link: values.video,   
                 } ,
                 id: advertisement._id        
           }
        mutate(data)
        }


        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
          };


          const handleChange = info => {
            setLoading1(true)
               
                storage
                .ref("images/" + info.file.name)
                .put(info.file.originFileObj)
                .then(snapshot => {
                  return snapshot.ref.getDownloadURL();
                })
                .then(url => {
                  console.log(url);
                  setImgurl([...imgurl, url])
                  setLoading1(false)
            
                })
                .catch(error => {
                  console.log(error);
                });
            
              };
            

              const remove = (e, url) =>{

                setImgurl(prev => prev.filter(item => item !== url))
                
                }

                const uploadButton = (
                    <div>
                      { loading1 ? <LoadingOutlined  /> : <PlusOutlined />}
                      <div style={{ marginTop: 8, fontSize:"14px" }}>{loading1 ? "uploading" :""}</div>
                    </div>
                  );
                

    return (
      <FormWrap>

        <div className="mt-5 mx-5">
            <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
             >
            <div className="grid grid-cols-2">
            <div className="col-span-1">


            <Form.Item
            label={<p className="text-left m-0 ml-2 ">Images</p>}
            name="image"
            // rules={[{ required: true, message: 'required!' }]}
          >
                <div 
                    className=" grid grid-cols-4 gap-5">
                {

                imgurl.map((img, i)=>{

                    return <div className=" imglist  bg-gray-50 text-center" style={{height:"100px"}}>
                    {/* <img key={i} className="  rounded col-span-1  block  object-cover" style={{height:"100%", width:"100%"}} src={img} alt="hj"   /> */}
                                         
                    <Image    
                     preview={false}               
                     key={i}
                     className="  rounded col-span-1  block  object-cover"
                     style={{height:"100%", width:"100%"}}
                     src={img}
                     placeholder={<Loader/> }              
                   />   
                    <h2 onClick={(e)=>remove(e, img)} className="p-1 text-white  text-xl "> <MdDelete/></h2>

                    </div>
                })
                }

                <Upload       
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleChange}
                        multiple={true}
                        className="ml-2"
                        >
                        {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                </div> 


        </Form.Item>


            <Form.Item
                label={<p className="text-left m-0 ">Video Link</p>}
                name="video"
                // rules={[{ required: true, message: 'required!' }]}
            >
                <Input  style={{width:'100%'}}/>
            </Form.Item>  
            

            <div className="mt-10">
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                </div>

            </div>
            </div>
          </Form>
     </div>
 </FormWrap>

    )
}

const FormWrap = styled.div`
position: relative;

.imglist {
overflow: hidden;
h2 { opacity : 0;
display:none;
position: absolute;
margin: 0;
bottom: 11%;
background-color: rgba(0,0,0,0.5);
cursor: pointer;
transition: 0.1s;

&:hover {

font-size: 1.5rem;
}

}

&:hover h2 {

opacity : 1;
display:block
}
}

`