import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button , Checkbox  , Select} from 'antd';
import styled from 'styled-components'
import {useFetchList, useCreate, useFetchOne} from '../../../../api/services/backend'



    export default function Checklist({ actionMethod, info }) {

        const [form] = Form.useForm();
        const dispatch = useDispatch()
        const {data, isLoading, error} = useFetchList('user')

        console.log(data);


        const onFinish = (values:any) => {
            const data = {
              checklist:{
                quality: values.quality ? true : false ,
                images: values.images ? true : false ,  
                available_quantity: values.available_quantity ? true : false ,  
              },
              rm:values.rm,                                       
            }

            
            dispatch(actionMethod())
            dispatch(info(data))
        }

        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
          };


          const onChange = (e)=> {
            console.log(`checked = ${e.target.checked}`);
          }

          const handleManagerSelect = (value) => {
      
        }

        
    return (
      <FormWrap> 
        <div className="mt-5 mx-5">
            <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
             >
            <div className="grid grid-cols-5">
            <div className="col-span-2">

            <Form.Item
                  label={<p className=" text-left m-0 ">Relationship Manager</p>}
                  name="rm"
                  rules={[{ required: true, message: 'required!' }]}
                >
                      <Select
                      showSearch 
                      placeholder="Name" 
                      style={{marginBottom:'10px'}}
                      filterOption={(input, option:any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     } 
                      onChange={handleManagerSelect}>

              {

              data && data.admin.map((item, i)=>{      
                  return <option key={i} value={item._id} >{item.admin_name}</option>

              })
              }</Select>
              </Form.Item>


            <Form.Item name="quality" valuePropName="checked" >
               <Checkbox > Quality </Checkbox>
            </Form.Item>  

            <Form.Item name="images" valuePropName="checked">
                <Checkbox>Images</Checkbox>
            </Form.Item>  

            <Form.Item name="available_quantity" valuePropName="checked">
                <Checkbox >Available Quantity</Checkbox>
            </Form.Item>  


            <div className="mt-10">
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="submit">
                          Next
                        </Button>
                    </Form.Item>
                </div>
                
            </div>
            </div>
          </Form>
     </div>
     </FormWrap>
    )
}

const FormWrap = styled.div`

.ant-checkbox + span {
    font-size: 16px !important;
}

`