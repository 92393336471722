import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import frFR from 'antd/lib/locale/fr_FR';

const resources = {
    en: {
      translation: {
        "dashboard": "Dashboard",
        "farmers":"Farmers",
        "orders":"Orders",
        "users":"Users",
        "customers":"Customers",
        "editProfile":"Edit Profile",
        "logout": "Log Out",
        "Help":"Help Center"
      }
    },
    fr: {
      translation: {
        "dashboard": "tableau de bord",
        "farmers":"agricultrice",
        "orders":"Ordres",
        "users":"utilisatrices",
        "customers":"Clients",
        "editProfile":"Editer le profil",
        "logout": "Se déconnecter",
        "Help":"Centre d'aide"
      }
    }
  };
  
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "en",
  
      keySeparator: false, // we do not use keys in form messages.welcome
  
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
  
    export default i18n;