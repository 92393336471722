import key from '../../key'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../hooks';
import { getProfile } from '../authSlice';
import { message } from 'antd';
import { useFetchList } from './backend';


const token = localStorage.getItem('sftoken')

const config = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    type: 'admins'
  }
};



const login = async (data: any) => {
  const { data: response } = await axios.post(key.BACK_ENDURL + `/user/login`, data, config);
  localStorage.setItem("sftoken", response.token)
  return response.data;
};


const useLogin = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data } = useMutation(login, {
    onSuccess: _ => {
      window.location.href = '/dashboard/home'
    },
    onError: ({ response }) => {
      message.error(response?.data?.msg)
    },
    onSettled: () => {
      queryClient.invalidateQueries('create');
    }
  });
  return { mutate, isLoading, data }
}

const useLogout = () => {
  let history = useHistory();
  const logout = () => {
    localStorage.removeItem('sftoken');
    history.push("/");
  }
  return { logout }
}


const useCheckAuth = () => {

  let history = useHistory();
  const dispatch = useAppDispatch();

  const checkAuth = () => {

    axios.get(key.BACK_ENDURL + `/userProfile`, config).then(({ data }) => {

      dispatch(getProfile(data))

    }).catch(err => {
      console.log(err);

      localStorage.removeItem('sftoken');
      history.push("/");

    })
  }

  return { checkAuth }
}


export { useLogin, useLogout, useCheckAuth };