import React, {useEffect, useState} from 'react'
import { useEdit, useFetchOne} from '../../../../api/services/backend'
import { Form, Input, Button, InputNumber ,Checkbox, Select, Row, Col} from 'antd';
import {MdClose} from 'react-icons/md'
import {useParams} from 'react-router-dom'


const { Option } = Select;

const layout = {
  labelCol: { span: 24},
  wrapperCol: { span: 24 },
}

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };



  export default function EditCity({cancel}) {
    const {id} = useParams()

  const {mutate} = useEdit('location')
  const {data} = useFetchOne('location', id)

  const [form] = Form.useForm();
   
  console.log(data);
  

  useEffect(()=>{
    
    data && form.setFieldsValue({  
      city:data?.location?.city,
      state:data?.location?.state,
      })
}, [data])


  const onFinish = (values: any) => {

    const cityData = {
    city:values.city, 
    state:values.state,
    id:data.location._id
  }
  mutate(cityData)
  };

  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };



  return (
   
    <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>
         <h1 className="mx-3 text-xl">Edit City</h1>
       <div className=" mx-5 grid grid-cols-2 mt-12">

    <Form
    {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >   
         
      <Form.Item
            label={<p className="text-left m-0 ">City</p>}
            name="city"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{width:'100%'}} />
          </Form.Item>

          <Form.Item
            label={<p className="text-left m-0 ">State</p>}
            name="state"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{width:'100%'}} />
          </Form.Item>
      

        <div className="mt-10">
            <Form.Item  className="text-right">
                <Button type="primary" htmlType="submit">
                Submit
                </Button>
            </Form.Item>
        </div>
   

    </Form>
</div>
    </div>
  );

  }