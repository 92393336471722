import React, {useEffect, useState} from 'react'
import {useCreate, useFetchList} from '../../../../api/services/backend'
import { Form, Input,Select, Button, Upload,message ,Image, Row, Col, Switch, InputNumber} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Loader from '../../../shared/loader';
import storage from '../../../shared/storage'
import { MdDelete } from 'react-icons/md';
import styled from 'styled-components';


const { Option } = Select;


  



  export default function CreateBanner() {

  const {mutate} = useCreate('banner')
  const [imgurl, setImgurl] = useState<any>([])
  const [loading1, setLoading1] = useState(false)
  const [fileList, setFileList] = useState([])
  const [mediaType, setMediaType] = useState<any>('image')

  const [form] = Form.useForm();

  const {data, error, isLoading} = useFetchList('product')
   


  const onFinish = (values: any) => {
    console.log(values);

   

    const data = {
    
          title: values.title,
          image: imgurl[0],  
          mediaType:mediaType,
          products:values?.products
         
          
  }

  
console.log(data);

  mutate(data)
  // form.resetFields()

  
}
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };




function onChangeType(value) {
  setMediaType(value)
  console.log(`selected ${value}`);
 
}
   
function onChange(value) {
  console.log(`selected ${value}`);
 
}

const handleChange = info => {
  setLoading1(true)
     
      storage
      .ref("images/" + info.file.name)
      .put(info.file.originFileObj)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL();
      })
      .then(url => {
        console.log(url);
        setImgurl([...imgurl, url])
        setLoading1(false)
  
      })
      .catch(error => {
        console.log(error);
      });
  
    };
  

    const remove = (e, url) =>{

      setImgurl(prev => prev.filter(item => item !== url))
      
      }

      const uploadButton = (
          <div>
            { loading1 ? <LoadingOutlined  /> : <PlusOutlined />}
            <div style={{ marginTop: 8, fontSize:"14px" }}>{loading1 ? "uploading" :""}</div>
          </div>
        );


  return (
   
    <FormWrap className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%',  border:'1px solid #e5e5e5'}}>

   <div style={{width:'80%'}}>
    <Form
layout='vertical'
name="basic"
      // labelCol={{ span: 4 }}
      // wrapperCol={{ span: 10 }}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >


 
  
      <Form.Item
        label={<p className="text-left m-0 "> Title</p>}
        name="title"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  style={{width:'100%'}}/>
      </Form.Item>
  
    <Form.Item
        label={<p className="text-left m-0 "> Banner Order</p>}
        name="bannerOrder"
        rules={[{ required: true, message: 'required!' }]}
      >
        <InputNumber  />
      </Form.Item>

      <Form.Item
        label={<p className="text-left m-0 "> Media Type</p>}
        name="mediaType"
      >
         <Select
       onChange={onChangeType}
      defaultValue="image"
      options={[{ value: 'image', label: 'Image' }, { value: 'video', label: 'Video' }]}
    />
       </Form.Item>

      <Form.Item
            label={<p className="text-left mb-2 ml-2 ">{ mediaType === 'image' ? "Image" : "Video"  }</p>}
            name="image"
            rules={[{ required: imgurl[0] ? false : true, message: 'required!' }]}
          >
             
                {

                imgurl.map((img, i)=>{

                  return <div  key={i} className=" imglist w-52   bg-gray-50 text-center" style={{height:"100px"}}>
                   
                 {mediaType === 'image' ?<Image    
                   preview={false}               
                  
                   className="  rounded col-span-1  block  object-cover"
                   style={{height:"100%", width:"200px"}}
                   src={img}
                   placeholder={<Loader/> }              
                 />   :    <div style={{ marginTop: '20px' }}>
                      <video width="320" height="240" controls>
                          <source src={imgurl} type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                  </div> }
                  <h2 onClick={(e)=>remove(e, img)} className="p-1 text-white  text-xl "> <MdDelete/></h2> 

                    </div>
                })
                }

                  { <Upload       
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleChange}
                        multiple={true}
                        className="ml-2"
                        >
                        {imgurl.length >= 1 ? null : uploadButton}
                        </Upload>}
              


        </Form.Item>
       { mediaType === 'image' && <Form.Item
        label={<p className="text-left m-0   ">Select Products</p>}
        name="products"
        rules={[{ required: true, message: 'required!' }]}
      >
       <Select
              mode="multiple"
             style={{height:"100%"}}
              optionFilterProp="children"
              onChange={onChange}
            >

{

data?.product?.map((item, i)=>{
  
  return <Option key={i} value={item?._id}>{item?.product_info?.product_name}</Option>


})


}

         
      </Select>
      </Form.Item> }   
      

    



<div className="mt-6">
      <Form.Item  className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
</div>
    </Form>
    </div>
    </FormWrap>
  );

  }

  const  FormWrap = styled.div`

  position: relative;
  
  
  .imglist {
  overflow: hidden;
  
  h2 { opacity : 0;
  display:none;
  position: absolute;
  margin: 0;
  bottom: 0%;
  background-color: rgba(0,0,0,0.5);
  cursor: pointer;
  transition: 0.1s;
  
  &:hover {
  
  font-size: 1.5rem;
  }
  
  }
  
  &:hover h2 {
  
  opacity : 1;
  display:block
  }
  }
  
  `