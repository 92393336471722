import  React,{ useEffect } from 'react'
import {  Tabs  } from 'antd';
import City from './city'
import Zone from './zone'
import Pin from './pinCode'
import Locality from './locality'

import {useDispatch, useSelector} from 'react-redux'
import { locationTab, locationSelector} from '../../../../api/services/location'


const { TabPane } = Tabs;

  export default function EditIndex({cancel}) {

    const dispatch = useDispatch()
    
    const {activetab} = useSelector(locationSelector)


    const callback = (key) => {
      console.log({k:key});
      dispatch(locationTab(key))
    }
  

  return (

           <div className=" rounded-lg bg-white p-5 shadow-sm" style={{height:'100%', border:'1px solid #e5e5e5'}}>

    <Tabs defaultActiveKey={activetab} onChange={callback}>
    <TabPane tab="City" key="1">
        <City cancel={cancel}/>
    </TabPane>

    <TabPane tab="Zone" key="2">
    <Zone cancel={cancel}/>
    </TabPane>

    <TabPane tab="Pincode" key="3">
    <Pin cancel={cancel}/>
    </TabPane>

    <TabPane tab="Locality" key="4">
    <Locality cancel={cancel}/>
    </TabPane>
  </Tabs>
   </div>  
  );

  }