import { Button } from 'antd';
import React from 'react'
import ReactExport from "react-export-excel";
import { DownloadOutlined  } from '@ant-design/icons';
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function ExportExcel({data}) {
    console.log(data);
    
    return (
        <ExcelFile filename="broadcast" element={<Button icon={<DownloadOutlined  style={{transform:"translateY(2px)" }}/>} className="mr-1" type="primary">Download</Button>}>
               
        <ExcelSheet data={data} name="Broadcast" >
        <ExcelColumn label="Message" value={col => col.broadcast_info ? col.broadcast_info.message: "null"}/> 
        <ExcelColumn label="To" value={col => col.broadcast_info ? col.broadcast_info.audiance: "null"}/> 
        <ExcelColumn label="Date" value={col =>  moment(col.createdAt).format("DD-MM-yyyy")}/>
        <ExcelColumn label="time" value={col =>  moment(col.createdAt).format('LT')}/>
         
        </ExcelSheet>
   
    </ExcelFile>
    )
}


