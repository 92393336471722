import  React,{ useEffect,useState } from 'react'
import { Form, Input,Select, Button,message,Checkbox,Space,Upload, Row, Col } from 'antd';
import {useFetchList, useEdit, useFetchOne} from '../../../../api/services/backend'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {MdClose} from 'react-icons/md'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;


  export default function Edituser() {
    const dispatch = useDispatch()
    const [imgurl, setImgurl] = useState('')
    const [fileList, setFileList] = useState([])
    const [loading1, setLoading1] = useState(false)
  const {mutate} = useEdit('user')

  const {id} = useParams()
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 24},
    wrapperCol: { span: 24 },
  }

 


//   const {data, isLoading, error} = useFetchList('user')

  const {data} = useFetchOne('user', id)

  console.log(id);


  
  useEffect(()=>{
   
    data && form.setFieldsValue({
        admin_name: data?.admin?.admin_name,
        email:  data?.admin?.email,
        phone_no: data?.admin?.phone_no,
        role: data?.admin?.role,
        district:data?.admin?.district,
        pincode:data?.admin?.pincode,
        state:data?.admin?.state,
        address:data?.admin?.address
       
      });
}, [data])




  const onFinish = (values: any) => {
    console.log(values);
    var numbers = /^[0-9]+$/;
    if(!values.phone_no.match(numbers) )
    {
    return message.error("Phone number must be only numbers") 
    }
    if(values.phone_no.length !== 10 ){
     return message.error("Phone number must be 10 numbers")
    }
   
else{

    const Adata = {
        admin_name: values.admin_name,
        role:values.role,
        email:values.email,
        phone_no: values.phone_no,
        // password: values.password,
        address:values.address,
        state:values.state,
        district:values.district,
        pincode:values.pincode,
        
        id:data.admin._id
  } 

  mutate(Adata)
  
}
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  
const handleChange = info => {
    if (info.file.status === 'uploading') {
      // setImgurl(null)
      setLoading1(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, collegeimgurl => {
    setImgurl(collegeimgurl)
        setLoading1(false)
        })
    }
  };
  const uploadButton = (
    <div>
      { loading1 ? <LoadingOutlined  /> : <PlusOutlined />}
      <div style={{ marginTop: 8, fontSize:"14px" }}>{loading1 ? "uploading" :""}</div>
    </div>
  );

   
  function onChange(value) {
    console.log(`selected ${value}`);
   
  }

  return (

   <div className="mt-5 ">
    <Form
      name="basic"
      {...layout}
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
 <div className="flex mx-10">
   
        <div>  
         {/* <Form.Item
        label={<p className="text-left m-0">Image</p>}
        name="image"
        // rules={[{ required: true, message: 'required!' }]}
      >
<Upload
        name="image"
        action= {''}
        listType="picture-card"
        maxCount={1}
        fileList={fileList}
        className="avatar-uploader mt-1 mr-10"
        showUploadList={false}
        onChange={handleChange}
        style={{width:'200px'}}
            >
            {imgurl ? 
        <div onClick={()=> {
            setImgurl('https://www.mocky.io/v2/5cc10019d3000009100a055e76')
            // dispatch(deleteImg(File(imgurl)))
            }}>
            <img  src={imgurl} alt="avatar" style={{ width: '125px', height:'100px', objectFit:'cover'}}  />
            <MdClose style={{ color:'red', position:'absolute', top:'5%'}}/>
        </div> : uploadButton}
        </Upload>

        </Form.Item>  */}
                        
                  
             </div>
             <div>  

      <Form.Item
        label={<p className='text-left m-0'> Name</p>}
        name="admin_name"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label={<p className='text-left m-0'>Mobile No.</p>}
        name="phone_no"
        rules={[{ required: true, message: 'required!' },
     ]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label={<p className='text-left m-0'>Email</p>}
        name="email"
        rules={[{ required: true, message: 'required!' },
        {type:"email", message: ' email is not valid!'}]}
      >
      <Input  />
      </Form.Item>

        <Form.Item
        label={<p className='text-left m-0'>Role</p>}
        name="role"
        rules={[{ required: true, message: 'required!' }]}
      >
      <Select
              placeholder="Select Role"
              optionFilterProp="children"
              onChange={onChange}
            >
            <Option value="Seasonal Admin">Seasonal Admin</Option>
            <Option value="Specialities Admin">Specialities Admin</Option>
            <Option value="Sub Admin"> Sub Admin</Option>
            <Option value="Campaign Group">Campaign Group</Option>
      </Select>
      </Form.Item>    

      <Form.Item
        label={<p className="text-left m-0 w-36">Address</p>}
        name="address"
        // rules={[{ required: true, message: 'required!' }]}
      >
        <Input />
      </Form.Item>
      
      <Row gutter={20}>
   
   <Col span={8}>
      <Form.Item
        label={<p className="text-left m-0 w-36">Pincode</p>}
        name="pincode"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input />
      </Form.Item>   
      </Col>

      <Col span={8}>
      <Form.Item
        label={<p className="text-left m-1 w-24">District</p>}
        name="district"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input />
      </Form.Item>


      </Col>

      <Col span={8}>

      <Form.Item
        label={<p className="text-left m-0 ml w-16">State</p>}
        name="state"
        rules={[{ required: true, message: 'required!' }]}
      >
        <Input />
      </Form.Item>

      </Col>

      </Row>
   
      <div className="mt-10">
      <Form.Item className="text-right">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      </div>
      </div>
      </div>
    </Form>

    </div>  
  );

  }