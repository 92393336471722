import React, { useEffect, useState } from 'react'
import { useCreate, useEdit, useFetchList } from '../../../api/services/backend'
import { Form, Input, Button, InputNumber, Checkbox, Select, Row, Col } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { MdClose } from 'react-icons/md'
import GoogleLoc from '../../shared/googlemap'
import { selectAuth } from '../../../api/authSlice'
import { useAppSelector } from '../../../api/hooks'
import { useParams } from 'react-router-dom';
const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};



export default function ApartmentForm({ goNext, fdata, setData, isEdit }) {
  const [cityFound, setCityInfo] = useState<any>(null)
  const [zoneInfo, setZoneInfo] = useState<any>([])
  const [pincodeInfo, setPincodeInfo] = useState<any>([])
  const [localityInfo, setLocalityInfo] = useState<any>([])
  const [onboardingCheck, setonboardingCheck] = useState(fdata?.block);
  const [sampleCheck, setsampleCheck] = useState(fdata?.sample);
  const [form] = Form.useForm();

  useEffect(() => {

    setCityInfo(fdata?.city);
    setZoneInfo(fdata?.city?.zone_info)

    const pincodeArray = fdata?.city?.pincode_info?.filter((item: any) => item.zone === fdata?.zone)
    const localityArray = fdata?.city?.locality_info?.filter((item: any) => item.pincode === fdata?.pincode)
    setPincodeInfo(pincodeArray)
    setLocalityInfo(localityArray)

    form.setFieldsValue({
      name: fdata?.name,
      address: fdata?.address,
      // latitude: fdata?.latitude || 12.456,
      // longitude: fdata?.longitude || 12.456,
      city: fdata?.city?.city,
      locality: fdata?.city?.locality_info?.find((item: any) => item.id === fdata?.locality)?.locality,
      pincode: fdata?.city?.pincode_info?.find((item: any) => item.id === fdata?.pincode)?.pincode,
      zone: fdata?.city?.zone_info?.find((item: any) => item.id === fdata?.zone)?.zone,
      block: fdata?.block,
      sample: fdata?.sample,
      total_houses: fdata?.total_houses
    });


  }, [fdata])

  const { data, isLoading, error } = useFetchList('location')

  const { latlng } = useAppSelector(selectAuth)

  const onFinish = (values: any) => {
    setData({
      name: values.name,
      address: values.address,
      // latitude: latlng?.lat,
      // longitude: latlng?.lng,
      zone_id: values.zone,
      pincode_id: values.pincode,
      locality_id: values.locality,
      city_id: values.city,
      total_houses: values.total_houses,
      sample:values.sample,
      block:values.block
    });
    goNext();
  };


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };



  const onchangeCity = (value) => {
    form.resetFields(['zone'])
    form.resetFields(['pincode'])
    form.resetFields(['locality'])
    const cityFound = data && data.city.find((item, i) => item._id === value)

    setZoneInfo(cityFound.zone_info ? cityFound.zone_info : [])
    setCityInfo(cityFound)
    setPincodeInfo([])
    setLocalityInfo([])
  }


  const onChangeZone = (value) => {
    form.resetFields(['pincode'])
    form.resetFields(['locality'])
    const pinsFound = cityFound.pincode_info.filter((item, i) => {
      return item.zone === value
    })
    setPincodeInfo(pinsFound)
    setLocalityInfo([])
  }


  const onChangePincode = (value) => {
    form.resetFields(['locality'])
    const localityFound = cityFound.locality_info.filter((item, i) => item.pincode === value)
    setLocalityInfo(localityFound)
  }

  const onChangeLocality = (value) => {
    console.log(`selected ${value}`);

  }

  return (
    <Form
      {...layout}
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

      <div className="grid grid-cols-3  mt-6 mx-4 ">
        <div className="col-span-2">
          <Form.Item
            label={<p className="text-left m-0 ">Apartment Name</p>}
            name="name"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>


          <Form.Item
            label={<p className="text-left m-0  ">Address</p>}
            name="address"
            rules={[{ required: true, message: 'required!' }]}
          >
            <Input.TextArea rows={2} style={{ width: '100%' }} />
          </Form.Item>

          <Row gutter={20}>



            <Col span={12}>
              <Form.Item
                label={<p className="text-left m-0">City</p>}
                name="city"
                rules={[{ required: true, message: 'required!' }]}
              >
                <Select
                  showSearch
                  placeholder="Select City"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={onchangeCity}>

                  {
                    data?.city.map((item, i) => {

                      return <Select.Option key={i} value={item._id} >{item.city}</Select.Option>

                    })
                  }

                </Select>
              </Form.Item>
            </Col>


            <Col span={12}>
              <Form.Item
                label={<p className="text-left m-0   ">Zone</p>}
                name="zone"
                rules={[{ required: true, message: 'required!' }]}
              >
                <Select
                  showSearch
                  placeholder="Select Zone"
                  optionFilterProp="children"
                  onChange={onChangeZone}
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={zoneInfo?.length < 1 ? true : false}
                >
                  {
                    zoneInfo?.map((item, i) => {
                      return <Select.Option key={i} value={item.id} >{item.zone}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>

            </Col>


            <Col span={12}>
              <Form.Item
                label={<p className="text-left m-0  ">Pincode</p>}
                name="pincode"
                rules={[{ required: true, message: 'required!' }]}
              >
                <Select
                  showSearch
                  placeholder="Select Pincode"
                  optionFilterProp="children"
                  onChange={onChangePincode}
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={pincodeInfo?.length < 1 ? true : false}
                >
                  {
                    pincodeInfo?.map((item, i) => {
                      return <Select.Option key={i} value={item.id} >{item.pincode}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>

              <Form.Item
                label={<p className="text-left m-0   ">Locality</p>}
                name="locality"
                rules={[{ required: true, message: 'required!' }]}
              >
                <Select
                  showSearch
                  placeholder="Select Locality"
                  optionFilterProp="children"
                  onChange={onChangeLocality}
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={localityInfo?.length < 1 ? true : false}
                >
                  {
                    localityInfo?.map((item, i) => {
                      return <Select.Option key={i} value={item.id} >{item.locality}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<p className="text-left m-0   ">Total Houses</p>}
                name="total_houses"
                rules={[{ required: true, message: 'required!' }, { pattern: /[0-9]/, message: 'invalid!' }]}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>


            {
              isEdit && <Row gutter={20} className="px-2">
                {/* <Col span={12}>
                  <Form.Item
                    label={<p className="text-left m-0   ">Latitude</p>}
                    name='latitude'
                    rules={[{ required: true, message: 'required!' }]}
                  >
                    <InputNumber style={{ width: '100%' }} disabled />
                  </Form.Item>

                </Col>


                <Col span={12}>
                  <Form.Item
                    label={<p className="text-left m-0 ">Longitude</p>}
                    name='longitude'
                    rules={[{ required: true, message: 'required!' }]}
                  >
                    <InputNumber style={{ width: '100%' }} disabled />
                  </Form.Item>
                </Col> */}

                <Col span={12}>
                  <Form.Item name="block" valuePropName="checked" >
                    <Checkbox defaultChecked={onboardingCheck} onChange={() => setonboardingCheck(!onboardingCheck)}>
                      Stop Onboarding
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="sample" valuePropName="checked" >
                    <Checkbox defaultChecked={sampleCheck} onChange={() => setsampleCheck(!sampleCheck)}>
                      Sample
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            }


            {/* <Col span={12}>
 
      <Form.Item
             label={<p className="text-left m-0   ">Latitude</p>}
            name='latitude'
            rules={[{ required: true, message: 'required!' }]}
          >
            <InputNumber style={{ width: '100%' }} />
         
            </Form.Item>
          </Col>
      
      <Col span={12}>
      <Form.Item
             label={<p className="text-left m-0 ">Longitude</p>}
            name='longitude'
            rules={[{ required: true, message: 'required!' }]}
          >
            <InputNumber style={{ width: '100%' }}/>
          </Form.Item> 
        </Col> */}
            <Col span={24} className="mt-5">
              <GoogleLoc />
            </Col>
          </Row>

          <div className="mt-5">
            <Form.Item {...tailLayout} className="text-right">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  );

}

