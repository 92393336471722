import React, { useState} from 'react'
import { Table, Tag, Space, } from 'antd';
import styled from 'styled-components'
import { useDelete, useFetchList} from '../../../../api/services/backend'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import DeleteConfirm from '../../../shared/deleteConfirm';

export default function Datatabletestimonial() {

  const [page, setPage] = useState(1);
  const {mutate} = useDelete('testimonial')
 
  const {data, isLoading, error} = useFetchList('testimonial')




 
  const confirm = (e, id) => {
    console.log(id._id);
    mutate(id._id)
  }

  const cancel = (e) =>{
    return null
  }

      
  const columns = [

    {
      title: 'Sl. No.',
      key: 'Sl.No.',
      render:(t, k, i)=>{
        return <p className="m-0 ">{(page - 1) * 10 + (i+1)}</p>
      }
    },

      {
        title: 'Customer Name',
        dataIndex: 'name',
        key: 'name',
        render: text => <p className="m-0">{text}</p>
      },

      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        render: text => <p>{text}</p>
      },

    
      {
        title: 'feedback',
        dataIndex: 'feedback',
        key: 'feedback',
        render:text => <p className="m-0">{text}</p>
      },
      {
        title: 'Action',
        key: 'action',
        render: (id) => (
          <Space size="middle">
             <a href={`/dashboard/edit-testimonial/${id._id}`}>
             <h5  style={{color: 'var(--brandColor' }}  className="text-secondary  m-0 ">
           <FaRegEdit />  
            </h5>
            </a>

        <h5 className="m-0">
            <DeleteConfirm confirm={(e)=>confirm(e, id)} title="poster" cancel={cancel} >
                <FaRegTrashAlt style={{cursor:"pointer", color:"#e43d3d"}} />
            </DeleteConfirm>
        </h5>

        </Space>
        ),
      },
        
     ]

 
      return (
        <DataTableWrap>
        { 
        !isLoading && <Table 
      
        loading={isLoading}
        columns={columns} 
        dataSource={data} 
        rowKey={(record)=>record._id}
        
        />

        }       
          </DataTableWrap>  
     )
 }
   
const DataTableWrap = styled.div`

min-height: 70vh;

`